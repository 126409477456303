import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
import Info from '../views/info.vue'
import Home from '../views/home.vue'
import MemberPc from '../views/member/member_pc.vue'
import DetailPc from '../views/member/detail_pc.vue'
import IndexPC from '../views/member/index_pc.vue'
import UserEditPc from '../views/member/user_edit_pc.vue'
import PcChat from '../views/chat/pc_chat.vue'
import PayPc from '../views/pay/pay_pc.vue'
import UpgradePc from '../views/upgrade/upgrade_pc.vue'
import StorePC from '../views/store/store_pc.vue'


// 以下为移动端组件
import Mhome from '../views/home_m'
import MContactList from '../views/chat/m_contacts.vue'
import MNewsfeed from '../views/newsfeed_m.vue'
import MDetail from '../views/member/m_detail.vue'
import MChat from '../views/chat/m_chat.vue'
import MChatList from '../views/chat/m_chat_list.vue'
import My from '../views/my.vue'
import MPay from '../views/pay/m_pay'
import MPayPayment from '../views/pay/m_payment'
import MUpgrade from '../views/upgrade/m_upgrade'
import MGift from '../views/store/m_gift'
import MMyGift from '../views/store/m_my_gift'
import MGiftSend from '../views/store/m_gift_send'
import MSet from '../views/m_setting'
import MCPWD from '../views/m_changepwd'
import MCData from '../views/m_changedata'
import MCSign from '../views/m_changesign'
import MLogin from '../views/m_login'
import MInfo from '../views/m_info'
import MRegister from '../views/m_register'

import store from '@/store'
import i18n from '@/language'

Vue.use(VueRouter)
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
};
const routes = [

    {
        path: '/',
        name: '/',
        redirect: '/home',
    },
    {
        path: '/m/home',
        name: 'Mhome',
        component: Mhome,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: login,
    },
    {
        path: '/m/login',
        name: 'MLogin',
        component: MLogin,
    },
    {
        path: '/m/register',
        name: 'MRegister',
        component: MRegister,
    },
    {
        path: '/info/:query',
        name: 'Info',
        component: Info,
    },
    {
        path: '/m/info',
        name: 'MInfo',
        component: MInfo,
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/member/pc',
        name: 'MemberPC',
        component: MemberPc,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/member/detail/pc',
        name: 'DetailPc',
        component: DetailPc,
        meta: {
            requireAuth: true
        },
        beforeEnter: (to, from, next) => {
            if(store.state.user.viptype <= 0){
                Vue.prototype.$message({
                    showClose: true,
                    message: i18n._t.apply(i18n, [ 'user.permission', i18n.locale, i18n._getMessages(), this ].concat( [] )),
                    type: 'error'
                });
                return false
            }
            next()
        },
    },
    {
        path: '/m/member/detail',
        name: 'MDetail',
        component: MDetail,
        meta: {
            requireAuth: true
        },
        beforeEnter: (to, from, next) => {
            if(store.state.user.viptype <= 0){
                Vue.prototype.$message({
                    showClose: true,
                    message: i18n._t.apply(i18n, [ 'user.permission', i18n.locale, i18n._getMessages(), this ].concat( [] )),
                    type: 'error'
                });
                return false
            }
            next()
        },
    },
    {
        path: '/m/newsfeed',
        name: 'MnNewsfeed',
        component: MNewsfeed,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/member/index/pc',
        name: 'IndexPC',
        component: IndexPC,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/member/index/user/edit',
        name: 'UserEditPc',
        component: UserEditPc,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/chat/pc',
        name: 'PcChat',
        component: PcChat,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/m/chat',
        name: 'MChat',
        component: MChat,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/m/chat/list',
        name: 'MChatList',
        component: MChatList,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/m/contacts',
        name: 'MContactList',
        component: MContactList,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/pay/pc',
        name: 'PayPc',
        component: PayPc,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/m/pay',
        name: 'MPay',
        component: MPay,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/m/pay/payment',
        name: 'MPayPayment',
        component: MPayPayment,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/upgrade/pc',
        name: 'UpgradePc',
        component: UpgradePc,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/m/upgrade',
        name: 'MUpgrade',
        component: MUpgrade,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/store/pc',
        name: 'StorePC',
        component: StorePC,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/m/gift',
        name: 'MGift',
        component: MGift,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/m/my/gift',
        name: 'MMyGift',
        component: MMyGift,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/m/gift/send',
        name: 'MGiftSend',
        component: MGiftSend,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/m/my',
        name: 'My',
        component: My,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/m/my/setting',
        name: 'MSet',
        component: MSet,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/m/my/setting/changepwd',
        name: 'MCPWD',
        component: MCPWD,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/m/my/setting/changedata',
        name: 'MCData',
        component: MCData,
        meta: {
            requireAuth: true
        },
    },
    {
        path: '/m/my/setting/changesoign',
        name: 'MCSign',
        component: MCSign,
        meta: {
            requireAuth: true
        },
    }
]




const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

function isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}
/**
 * 路由拦截
 * 权限验证
 */

router.beforeEach(async (to, from, next) => {
    if (to.meta.requireAuth) {
        // 这里依据 token 判断是否登录，可视情况修改
        const token = sessionStorage.getItem('token')
        if (token && token !== 'undefined' && store.state.user) {
            next()
        } else {
            if(isMobile()){
                next({
                    name: 'MLogin',
                    query: {
                        redirect: to.fullPath
                    }
                })
            }else{
                next({
                    name: 'Login',
                    query: {
                        redirect: to.fullPath
                    }
                })
            }

            sessionStorage.clear()
        }
    } else {
        next()
        // if(isMobile()){
        //     next({
        //         name: 'MLogin',
        //         query: {
        //             redirect: to.fullPath
        //         }
        //     })
        // }
        // else{
        //     next({
        //         name: 'Login',
        //         query: {
        //             redirect: to.fullPath
        //         }
        //     })
        // }
    }
})
export default router
