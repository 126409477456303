<template>
  <div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-search v-model="search" :placeholder="$t('user.search')" />
      <van-list
          class="indexuserBox"
          v-model="loading"
          :finished="finished"
          finished-text=""
          loading-text=""
      >
        <div
            class="userItem"
            v-for="item in memberList"
            :key="item.id"
        >
          <div class="img">
            <router-link :to="{name: 'MDetail', query: {id: item.id}}" class="">
              <el-image :src="item.header_img"></el-image>
            </router-link>>
            <img v-show="item.viptype == 2" class="vip" src="/img/dimand.png" >
            <img v-show="item.viptype == 1" class="vip" src="/img/king.png" >
          </div>
          <div class="userName">{{item.username}}
            <div :class="item.is_online > 0 ? 'online' : 'offline'"></div>
          </div>
          <div class="flexbox userMenu">
            <img @click="likeOp(item.id, item.like)" v-if="item.like == 1" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAA/NJREFUWEfFl1tsFFUcxn//2S5LL4RYxUvirViIQogWNdQSFCJg4zXBNFEbY6m71EuM8dn44IPxxWcTHuzqGiqwUmzVKgVBMBQ0wcYQo/FBY3yCeiFYt7XdnWPOzszOZc9ut5TGeZmdc+bs953v/L9vzhH+50vmwlddXfU0N2/Ftv9AqSVIYcY4RiSBUv8idhPKmiy+U5Cz0t//dzUMIwH10vPtzOY/R6nlzmDl3vQ9+DvQV6ldKRCmialu2T0wGCVTRkD17foZVEsJaKHgwQnAuLwzsD5IIkRgkcGhqIaESJQIqJdf3MD0zOlFmrkD7qlhc6+8u/cr/egTeC514bKteVj2MLhTNv9Iem9TmEBf0quuhRXcnOAuTHqfJaCKCqhXuurJLc8tuNprBdeFXa+WydvZSYdAX98jkB92xlewWjwOq1ZD62r47VeYOO/c9XXrGlh3B6xshR+/h8MjMOlEQUXbCtukf/8Rh0Aq1YFln6wIfu11sL0TGhrDNh4dgRVXw4OPQSzm9+XzMLgPxr8JLGc0M9RGSX845hBIJjdj2ceMbOvq4OkeSCyNZojzfP2N5vZCAd54FXK5sBJersBmSWePuwrs7EA46S9BgG1rK2ztNINUI6D7jo3CoU/8ZQiGmhBQIOUSCHrVW7+2O2FDx6URODsOe/rNtVBGAOUoUJLItcvNLdD58KURGBmCE0fMhSgSUaBIIJBWniMSCehJgWXNrwa0mm++BhcvlBei7rOsKAE7rEBwObQCLbfMj8DvE/DW62Zw3WomUOFTe0UzPPo4NDSUkzC5QNswsxt++iEcw8FJWfZGSQ+5NtzVuwWVP2p0gTfotrWwZVttBE6dgKH9lcGLCnC/pA8e9W1IwQ2iQCFGXdF2F9yzKUwiqsB3Z+CDdHVwXWuWDqKSAs9sx7YPhVxQZkk3ptesCysRJDB2HIazc4M7Bb5DMkMHfQVU3ndBJXDPpjfcBB33wVUrnCScmoLDn8LYl7WCQ0wHkaeADiI1a05Cz47BjNAE9Xdh/d2w9nYYHICJc7WD6/8SeUDe+2jUUaC3dxnWzMXQrsVb6agaprSMkqzlnalEnWSzBX9H9Gx3eENSaeamtAwC1gKuNyKZ4WKy+QSS3VMo5X/yFmvmzgT+lMzHV0YIPLUDxYGqWWCKak+R2mbu1smSdnn/wNchAkVeye4z2La7b5/HAWQ+4CK/SGZ4pVdi5QeT3ie/BdVmVMJ0+lkAeJkCpcLveWITwmegGs3nBDctawNXoP6iEH9I9gyejmZ51cNp8VT3Qlcj09KOsnMgcVCz5R8E3WQtBXuaAtcQ4xy2NJGLf6GtVv6+3zLn6bja4MvR9x+KHK4/B4NQQwAAAABJRU5ErkJggg==" >
            <img @click="likeOp(item.id, item.like)" v-else src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAA4hJREFUWEfFl1tPE0EUx/+zu+22tE0xosRoiILRxEuMvogCMsuiPhgT4wMvfggTY/wW+gXkE2DUxBfUlHaNUPVBSTRG8YooCg1bsFzbsjtmVltpu7vdlhrnrZ1zzv83Z8+ZC8F/HqSa/tDQUPBwJtO/Fo3qC+Gw3/T7c3Y+jDGZEJIFEAawxG1kWX7V3d296KZhC5C7dqXz874D93/s3h1loliN0cv8miAIl3p7e++UG1cAfL1x/dOHo8f2eIlah824oijHNvqVAPxjcUuXEDJOKS1CFAFyVy8fHzt34Wkdq6rZxTCMU/39/Y8toIL3xODNhe8de6M1R6vPYVlRFF6sfwG0WIw1qOA8IVFKBUIIszKQTCaD2Wx2xZNn44wiiqIsWQBvBgfPz3R03HOLLRKCVp+E7ZKEtGFgyTCQXjcsl21NTWgNh9AcjkBfzOBjeh454/ecyzitKErMAhi/devkQkvLmJNxVBRxsCkAPynt2terawgGgmjfuRMQhKI7Mwy8n57G9yVrP7Idpml2qaqatCImhocpAoGEnSUPeyISgq9MvGi7q81JAaMTE1hnzHaeMUb7+voeWQAjIyMnBUGwzcB2n4QDwYBzJp0AAHyb+YEP6fnqGXADaJP9aJf9dQGkdR0vZ2c3B9AiSTjUVF8GJqenMfnz5+YAJELQFQn93TDKwzl9Asbw5N07ZB26oaQI3T4B1+MZ4JmwHQ4AuZVlJCe/eOuCagAhQcCRULCiDa3oNgC8DV9PTWFuddUbQCwWU0RRjLvtGjt8PuwPypUmNgAzs7N4q+uuu5BpmqqqqvGqbbgxSpvfj/ZAWUeUAehzc3iVSrmK88nyGjgjCMKDql4AKjKxAcDLygsapmleVFX1bk0ZKDhvkUR0yDLComDVgJnLYTKVwlQm42UNlk1NXWAXlZ8LfJNq3tqCN6kUlvN5z+J/AM6qqvrQysDo6Ggkn897x69Jyt6YUioRQozi8ZZIJOxPjQaIlYfgFxF+IeH/bwTgTeuy5zaOhBCSppRuLQGIx+MXCSG3GyfjHEmSpM6enp5nJQD8h6ZpzxljJff2RgMRQj5TStsLcSseJpqmvWCMHW20sLXaMvGKDBRENU3rYYwNAwhtFoQXHIB5xtg5RVEq3h2uj1PGGNE0jUN0mqa5QgjxMcacGp4X8BqAVgD8FhLWdX1kYGDA9XZa9XW82QxU8/8FdalrMFGzk9oAAAAASUVORK5CYII=" >
            <img @click="openChat(item)" src="img/msg.jpg">
          </div>
        </div>
      </van-list>
      <div class="pager">{{ $t('user.more') }}</div>
    </van-pull-refresh>
    <m-footer />
  </div>
</template>

<script>
import {like, memberList} from '../api/user'
import { PullRefresh,Search,List } from 'vant'
import 'vant/lib/search/style'
import 'vant/lib/pull-refresh/style'
import 'vant/lib/list/style'
import MFooter from "@/components/MFooter"
import {mapGetters} from "vuex";
export default {
  components: {
    [PullRefresh.name]: PullRefresh,
    [Search.name]: Search,
    [List.name]: List,
    MFooter
  },
  data() {
    return {
      srcList: [],
      search: '',
      loading: false,
      finished: true,
      refreshing: false,
      page: 1,
      list: []
    }
  },

  methods: {
    likeOp(like_id, type){
      like({like_id: like_id, type: type}).then(res => {
        if(res.code == 0){
          let list = this.memberList
          this.memberList = []
          for (const i in list) {
            let item = list[i]
            if(item.id == like_id){
              item.like = type == 1 ? 0 : 1
            }
            // item.timeFormat = this.dayjsObj.unix(list[i].create_time).fromNow()
            this.memberList.push(item)
          }
          this.$message({
            message: 'Operated successful',
            type: 'success'
          });
        }
      })
    },
    openChat(user){
      if(this.getUserinfo.viptype < 1){
        this.$message({
          showClose: true,
          message: this.$t('user.permission'),
          type: 'error'
        });
        let that = this
        setTimeout(function (){
          that.$router.push({name: 'MUpgrade'})
        },1000)

        return
      }
      let list = []
      if(this.$store.state.contacts.length > 0){
        for (const i in this.$store.state.contacts) {
          if(this.$store.state.contacts[i].id != user.id)
            list.push(this.$store.state.contacts[i])
        }
      }
      list.unshift(user)
      this.$store.commit('setContacts', list)
      this.$store.commit('setMUserChat', user)
      sessionStorage.setItem('setMUserChat', JSON.stringify(user))
      this.$router.push({name: 'MChat'})
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        if (this.refreshing) {
          this.memberList = [];
          this.refreshing = false;
        }

        this.getMemberList(1, 20)
        this.loading = false;

      }, 1000);
    },
    onRefresh(){
      // 清空列表数据
      this.finished = true;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = false;
      this.getMemberList(1, 30)
      this.refreshing = false;
      // this.onLoad();
    },

    toDetail(id){
      this.$router.push({name: 'DetailPc', query: {id: id}})
    },

    getMemberList(page, limit){
      const that = this
      memberList({page: page, limit: limit}).then(res => {
        if(res.code == 0){
          that.list = res.list
          this.page += 1
        }
      })
    },
  },
  created() {
    // 获取用户
    this.getMemberList(1, 20)
  },
  computed:{
    ...mapGetters(['getUserinfo']),
    memberList(){
      return this.list.filter(u => u.username.includes(this.search))
    }
  },
  mounted() {
  },
  watch: {
  }
};
</script>
<style scoped>
@import "../assets/css/common.css";
.userItem {
  width: 50%;
  text-align: center;
  padding: 0 3%;
  padding-bottom: 15px;
}
.userItem .img {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}
.userItem .img .vip{
  position: absolute;
  right: 2px;
  top: 3px;
  width: 22px;
}
.userItem .userName{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0 8px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.online {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-image: linear-gradient(180deg,#adff2f,green);
  margin-right: 10px;
}
.userItem .userMenu{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.indexuserBox{
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  min-height: 501px;
}
.userItem .img .el-image{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.pager{
  text-align: center;
  font-size: 13px;
  color: #666;
  margin-bottom: 15px;
}
</style>
