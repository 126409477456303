import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        language: 'tn',
        isLogin: false,
        contacts: [], // 联系人
        chatFixedOpen: false,
        chatWindowOpen: [],
        hiddenUsers:  [],
        user: {},
        websocketMessage: null,// 端返回的信息
        socketTimestamp: new Date().getTime(),//时间戳去监听数据的改变
        userMsg: [],
        mChatUser: null,
        totalMsgCount: 0, // 未读消息总数
        serviceInfo: null,
        payData: null,
        onlineUser: null, // 在线列表
    },
    mutations: {
        setOnlineUser(state, list){
            state.onlineUser = list
        },
        changeLanguage(state, tag){
            state.language = tag
        },
        loginState(state, is){
            console.log('loginState = ', is)
            state.isLogin = is
        },
        setContacts(state, contacts){
            state.contacts = contacts
        },
        setChatFixedOpen(state, is){
            state.chatFixedOpen = is
        },
        setChatWindowOpen(state, data){
            state.chatWindowOpen = Object.assign({}, data)
        },
        setHiddenUsers(state, users){
            state.hiddenUsers = users
        },
        setUserInfo(state, userinfo){
            state.user = Object.assign({},userinfo)
        },
        setSocketMessage(state, data) {
            state.socketTimestamp = new Date().getTime()
            state.websocketMessage = data
        },
        setUserMsg(state, data) {
            state.userMsg = Object.assign({},data)
        },
        setMUserChat(state, user){
            state.mChatUser = user
        },
        setTotalMsgCount(state, count){
            state.totalMsgCount = count
        },
        setServiceInfo(state, user){
            state.serviceInfo = user
        },
        setPayData(state, data){
            state.payData = data
        }
    },
    getters: {
        getLanguage: state => state.language,
        getIslogin: state => state.isLogin,
        getContacts: state => state.contacts,
        getChatFixedOpen: state => state.chatFixedOpen,
        getChatWindowOpen: state => state.chatWindowOpen,
        getHiddenUsers: state => state.hiddenUsers,
        getUserinfo: state => state.user,
        getSocketMessage: state => state.websocketMessage,
        getSocketTimestamp: state => state.socketTimestamp,
        getUserMsg: state => state.userMsg,
        getMUserChat: state => state.mChatUser,
        getTotalMsgCount: state => state.totalMsgCount,
        getServiceInfo: state => state.serviceInfo,
        getPayData: state => state.payData,
        getOnlineUser: state => state.onlineUser,
    },
    actions: {
    },
    modules: {
    }
})
