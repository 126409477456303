<template>
<div>
  <div class="invest_area" >
    <div class="invest_area_top" >
      <div class="invest_area_top_icon" >
        <div @click="$router.back()">{{ $t('user.cancel') }}</div>
      </div>
      <span >{{ $t('user.change_password') }}</span>
      <div class="invest_area_top_save">
        <button @click="changeSignature" class="el-button el-button--primary" type="button" style="background: rgb(255, 74, 83); border: 1px solid rgb(255, 74, 83);">
          <span>{{ $t('user.save') }}</span>
        </button>
      </div>
    </div>
  </div>

  <div class="menuList">
    <el-input
        type="textarea"
        :rows="4"
        maxlength="30"
        show-word-limit
        :placeholder="$t('user.add_signature')"
        v-model="signatureInput"
    >
    </el-input>
  </div>
</div>
</template>

<script>
import {signature} from '@/api/user'
import {mapGetters} from "vuex";
export default {
  name: "m_changesign",
  components: {
  },

  data(){
    return {
      signatureInput: ''
    }
  },
  computed: {
    ...mapGetters([
      'getUserinfo',
    ]),
  },
  watch: {
    getUserinfo: {
      handler(newV) {
        this.signatureInput = newV.signature
      },
      immediate: true
    },
  },
  created() {

  },
  methods: {
    changeSignature(){
      if(this.signatureInput){
        signature({str: this.signatureInput}).then(res => {
          if(res.code == 0){
            this.$message({
              message: 'Operated successful',
              type: 'success'
            })
          }else{
            this.$message.error('Operated failed')
          }

        })
      }
    },
  }
}
</script>

<style scoped>
@import "../assets/css/common.css";
.invest_area{
  width: 100%;
  height: 20px;
  padding: 20px;
  color: #303133;
  position: relative;
}
.invest_area_top{
  text-align: center;
  font-size: 18px;
  height: 20px;
  line-height: 20px;
}
.invest_area_top_icon{
  position: absolute;
  height: 20px;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  color: #465dfe;
}
.invest_area_top_save{
  position: absolute;
  height: 20px;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
}
.menuList {
  padding: 20px 0 100px;
}
.el-form-item{
  margin-bottom: 22px;
  display: flex;
  width: 100%;
  padding: 0 16px;
  background: #f5f7fa;
  height: 60px;
  align-items: center;
  margin-bottom: 30px;
}
::v-deep .el-form-item__content{
  display: flex!important;
  width: 100%;
}
</style>
