<template>
<div>
  <div class="invest_area" >
    <div class="invest_area_top" >
      <div class="invest_area_top_icon" >
        <img @click="$router.back()" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAABAklEQVRIS8XVz2oCMRAG8G+Mq9BDD32Agogg2/3X7t1L1XfwPfpKfQgvWnsqlNo0gZbe6sl36Lo7soVVinhYw2DO4ceXSWZCEFok5OI8cBDcRcqjKTE/aL18rHO6o4n9OL7xSM1KjIvNwBjz7QyHYdhvNFtzAqt8k91ba20dtNx7kDhJkh5IPQFo/3I+/NRa10UP4CBIu6rJCwAXRZ6NrLVvp6D/4CiKOqS8BQGX4HyktX49Fd3Bvp9et9r8zExXXGRjY8yLC7qD49v0i4A+wGsGVo7oz8f7cvJ3eWKwWCnK1CKXV9VU5LlVuEiDVLhIS1e4yBDa11xgbDo2yZl+EJfUW+dUiBfEiYriAAAAAElFTkSuQmCC" alt="" >
        <img @click="$router.push({name: 'Mhome'})" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAACmklEQVRIS+3VTUgUYRgH8P8zOxpW1EFTCO1Qixd3vsm6hAUeCvIkEhHZxyWowEtEQUHhReokZNDJMiLCPBnUQSjpUsbMvDO7Xsw6pASaBkUl6O77xM66scqqqx26NMd5n+fHO/+Z5x3CGldtbW1F5Y7qWwRqz5YyuG/2y/TlycnJudVaabXFRMLZo5bxADM0EB5FtYwTREimF6g1lXI/rNS/IqzrdouioI+jTaI9CLxnWcQw7KMg9BFAUqI9DL3BYngxWDFNu5OBKwCFMjPfmkwmPxY2a5q2W4mVDwCsE9AlhHcdgCysWQLXO07V5gw/BlEzM9+fnZk+v1KWUfZV1XeJ6DSYh37F6PiY687k8T+wYRiNpKj9zFwDRkcQePfWerGL0ZwDoZuIplim24IgGMnej2BNs5oUVXlB4KkFlm2jQrwrBc3XNJjm3jJS+hlUI9PycDLpD0ewadojDKqci2Ff4eOsB8/GWJHBWwLPCuE15mDLSbOUt4PAv7oebHmtYTldBFwSvqvmYQbzDSG8m38JdxJwTfgu/TtY1+0DFEMHATtzg4fPnEF3GHqvi0RR2o41y2qOQXnOwAzAozmIGgioykAeSfr+UCFuWE5psGnZr5ip7uePb9b4+Pj3LBKPx7dt2brdJ+IJ4XsHNwQblvOJGC+FcE8VAqbpPGDCocB3d20MNp0JAoaEcM8sg3sZaA6EW/cfLi0Kw3LmwPw0EN7Z3KcKJBKJypi66T2BHwrhXViasd3DoJMsF+JhGH5dXCPDtHtB1Br4bkU0eYZp3yGiJc3RMDDPs0RTGHpvCmFdt/eTgmEiKl8+JMzcEwjvYv48VnTLOqYw1RcUptNpDKZSXljs/EgkbF1V0QJAza9L4rHQ959k/ya/AUtYkibkTA55AAAAAElFTkSuQmCC" alt="" >
      </div>
      <span >{{ $t('user.my_preferences') }}</span>
    </div>
  </div>

  <div class="menuList">
    <div class="menuList_titile">{{$t('user.basic')}}</div>
    <van-cell  is-link :title="$t('user.birthday')" :value="date" @click="show = true" />
    <van-cell   :title="$t('user.state')" />
    <van-cell  is-link :title="$t('user.sex')" :value="sex"/>
  </div>
  <van-dialog v-model="show" :title="$t('user.birthday')" show-cancel-button @confirm="confirm">
    <van-datetime-picker
        v-model="birthday"
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        :show-toolbar="false"
        @change="dateChange"
    />
  </van-dialog>
</div>
</template>

<script>
import {DatetimePicker,Dialog, Cell, Locale} from 'vant';
import 'vant/lib/cell/style'
import 'vant/lib/datetime-picker/style'
import 'vant/lib/dialog/style'
import en from 'vant/es/locale/lang/en-US';
import cn from 'vant/es/locale/lang/zh-CN';
import tn from 'vant/es/locale/lang/zh-TW';
import {mapGetters} from "vuex";
import {userEdit} from "@/api/user";
export default {
  name: "m_changedata",
  components: {
    [Cell.name]:Cell,
    [DatetimePicker.name]:DatetimePicker,
    [Dialog.Component.name]:Dialog.Component,
  },

  data(){
    return {
      date: '',
      show: false,
      sex: '',
      minDate: new Date(1955, 0, 1),
      maxDate: new Date(),
      birthday: null,
      selectedBirthday: ''
    }
  },
  computed: {
    ...mapGetters([
      'getUserinfo',
      'getLanguage',
    ]),
  },
  watch: {
    getUserinfo: {
      handler(newV) {
        this.sex = newV.sex == 1 ? this.$t('male') : this.$t('female')
        this.birthday = new Date(this.getUserinfo.born)
        this.selectedBirthday = this.getUserinfo.born
        this.date = `${this.birthday.getFullYear()}-${this.birthday.getMonth() + 1}-${this.birthday.getDate()}`
      },
      immediate: true
    },
    getLanguage: {
      handler(newV){
        if(newV == 'cn'){
          Locale.use('zh-CN',cn)
        }else if(newV == 'en'){
          Locale.use('en-US',en)
        }else{
          Locale.use('zh-TW', tn)
        }
      },
      immediate: true
    }
  },
  created() {
  },
  methods: {
    confirm(){
      let age = Math.floor((((new Date().getTime()) - (new Date(this.selectedBirthday).getTime())) / 86400 / 1000/ 365))
      let data = {
        born: this.selectedBirthday,
        age: age
      }

      userEdit(data).then(res => {
        if(res.code == 0){
          let userInfo = this.getUserinfo
          userInfo.born = this.selectedBirthday
          userInfo.age = age
          this.$store.commit('setUserInfo', userInfo)
          sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
          this.$message({
            message: 'Operated successful',
            type: 'success'
          })
        }else{
          this.$message.error('Operated failed')
        }
      })
    },
    dateChange(e){
      this.selectedBirthday = e.getValues().join('-')
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date){
      this.show = false;
      this.date = this.formatDate(date);
    }
  }
}
</script>

<style scoped>
@import "../assets/css/common.css";
.invest_area{
  width: 100%;
  height: 20px;
  padding: 20px;
  color: #303133;
  position: relative;
}
.invest_area_top{
  text-align: center;
  font-size: 18px;
  height: 20px;
  line-height: 20px;
}
.invest_area_top_icon{
  position: absolute;
  height: 20px;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
}
.invest_area_top_icon img:first-of-type {
  width: 20px;
  height: 16px;
  margin-right: 15px;
}
.invest_area_top_icon img:nth-of-type(2) {
  width: 25px!important;
  height: 25px!important;
  top: 10px;
}
.menuList {
  text-align: left;
  padding: 20px 0 100px;
}
.menuList_titile{
  color: #303133;
  font-size: 14px;
  padding: 15px;
  background-color: #f5f7fa;
}
</style>
