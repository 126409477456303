import request from './request'
/**
 * @description 首页最新动态
 */
export function trend(data) {
    return request.get(`trend`, data)
}

/**
 * @description 首页最新动态
 */
export function sendTrend(data) {
    return request.post(`sendTrend`, data)
}

/**
 * @description 升级选项
 */
export function recharge(data) {
    return request.get(`index/recharge`, data)
}

/**
 * @description 升级选项
 */
export function giftList(data) {
    return request.get(`index/gift`, data)
}

/**
 * @description 发送礼物
 */
export function sendGift(data) {
    return request.post(`index/buyGift`, data)
}

/**
 * @description 我的礼物礼物
 */
export function myGift(data) {
    return request.get(`index/myGift`, data)
}
