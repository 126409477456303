<template>
<div>
  <div class="invest_area" >
    <div class="invest_area_top" >
      <div class="invest_area_top_icon" >
        <img @click="$router.back()" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAABAklEQVRIS8XVz2oCMRAG8G+Mq9BDD32Agogg2/3X7t1L1XfwPfpKfQgvWnsqlNo0gZbe6sl36Lo7soVVinhYw2DO4ceXSWZCEFok5OI8cBDcRcqjKTE/aL18rHO6o4n9OL7xSM1KjIvNwBjz7QyHYdhvNFtzAqt8k91ba20dtNx7kDhJkh5IPQFo/3I+/NRa10UP4CBIu6rJCwAXRZ6NrLVvp6D/4CiKOqS8BQGX4HyktX49Fd3Bvp9et9r8zExXXGRjY8yLC7qD49v0i4A+wGsGVo7oz8f7cvJ3eWKwWCnK1CKXV9VU5LlVuEiDVLhIS1e4yBDa11xgbDo2yZl+EJfUW+dUiBfEiYriAAAAAElFTkSuQmCC" alt="" >
        <img @click="$router.push({name: 'Mhome'})" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAACmklEQVRIS+3VTUgUYRgH8P8zOxpW1EFTCO1Qixd3vsm6hAUeCvIkEhHZxyWowEtEQUHhReokZNDJMiLCPBnUQSjpUsbMvDO7Xsw6pASaBkUl6O77xM66scqqqx26NMd5n+fHO/+Z5x3CGldtbW1F5Y7qWwRqz5YyuG/2y/TlycnJudVaabXFRMLZo5bxADM0EB5FtYwTREimF6g1lXI/rNS/IqzrdouioI+jTaI9CLxnWcQw7KMg9BFAUqI9DL3BYngxWDFNu5OBKwCFMjPfmkwmPxY2a5q2W4mVDwCsE9AlhHcdgCysWQLXO07V5gw/BlEzM9+fnZk+v1KWUfZV1XeJ6DSYh37F6PiY687k8T+wYRiNpKj9zFwDRkcQePfWerGL0ZwDoZuIplim24IgGMnej2BNs5oUVXlB4KkFlm2jQrwrBc3XNJjm3jJS+hlUI9PycDLpD0ewadojDKqci2Ff4eOsB8/GWJHBWwLPCuE15mDLSbOUt4PAv7oebHmtYTldBFwSvqvmYQbzDSG8m38JdxJwTfgu/TtY1+0DFEMHATtzg4fPnEF3GHqvi0RR2o41y2qOQXnOwAzAozmIGgioykAeSfr+UCFuWE5psGnZr5ip7uePb9b4+Pj3LBKPx7dt2brdJ+IJ4XsHNwQblvOJGC+FcE8VAqbpPGDCocB3d20MNp0JAoaEcM8sg3sZaA6EW/cfLi0Kw3LmwPw0EN7Z3KcKJBKJypi66T2BHwrhXViasd3DoJMsF+JhGH5dXCPDtHtB1Br4bkU0eYZp3yGiJc3RMDDPs0RTGHpvCmFdt/eTgmEiKl8+JMzcEwjvYv48VnTLOqYw1RcUptNpDKZSXljs/EgkbF1V0QJAza9L4rHQ959k/ya/AUtYkibkTA55AAAAAElFTkSuQmCC" alt="" >
      </div>
      <span >{{ $t('user.my_preferences') }}</span>
    </div>
  </div>

  <div class="menuList">
    <div class="menuItem" @click="isUpload = true">
      <div class="left">{{ $t('user.my_head') }}</div>
      <div class="left">
        <img class="ico" :src="userImg" alt="">
        <van-icon name="arrow" />
      </div>
    </div>
    <router-link to="setting/changedata" class="terms">
      <div class="menuItem">
        <div class="left">{{ $t('user.data_setting') }}</div>
        <div class="left">
          <van-icon name="arrow" />
        </div>
      </div>
    </router-link>
    <router-link to="setting/changesoign" class="terms">
      <div class="menuItem">
        <div class="left">{{ $t('user.personal_signature') }}</div>
        <div class="left">
          <van-icon name="arrow" />
        </div>
      </div>
    </router-link>
    <a @click="isLang = true" class="terms">
      <div class="menuItem">
        <div class="left">{{ $t('user.language') }}</div>
        <div class="left">
          <span class="langtxt">{{currentLangText}}</span>
          <van-icon name="arrow" />
        </div>
      </div>
    </a>
    <router-link to="setting/changepwd" class="terms">
      <div class="menuItem">
        <div class="left">{{ $t('user.change_password') }}</div>
        <div class="left">
          <van-icon name="arrow" />
        </div>
      </div>
    </router-link>
    <div class="logout">
      <van-button @click="logoutBtn" type="danger" color="#FF4A53" block round >{{ $t('user.logout') }}</van-button>
    </div>
    <input ref="upload" type="file" accept="image/*" @change="onUpload" style="display: none;">
  </div>
  <van-action-sheet
      v-model="isUpload"
      :actions="uactions"
      :cancel-text="$t('user.cancel')"
      @select="onSelect" />

  <van-action-sheet
      v-model="isLang"
      :actions="lactions"
      @select="lSelect" />
</div>
</template>

<script>
import {mapGetters} from "vuex";
import { Icon,ActionSheet,Button ,Locale} from 'vant';
import 'vant/lib/icon/style'
import 'vant/lib/action-sheet/style'
import 'vant/lib/button/style'
import en from 'vant/es/locale/lang/en-US';
import cn from 'vant/es/locale/lang/zh-CN';
import tn from 'vant/es/locale/lang/zh-TW';
import {uploadImg,logout} from '@/api/user'
export default {
  name: "setting",
  components: {
    [Icon.name]: Icon,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
  },

  data(){
    return {
      isUpload: false,
      uactions: [{ name: this.$t('user.choose_from_album') }],
      lactions: [{ name: 'English'}, {name: '繁體中文'}, {name: '简体中文' }],
      userImg: '',
      isLang: false,
      currentLangText: ''
    }
  },
  computed: {
    ...mapGetters([
        'getUserinfo',
        'getLanguage',
    ]),
  },
  watch: {
    getUserinfo: {
      handler(newV) {
        this.userImg = newV.header_img
      },
      immediate: true
    },
    getLanguage: {
      handler(newV){
        if(newV == 'cn'){
          this.currentLangText = '简体中文'
          Locale.use('zh-CN',cn)
        }else if(newV == 'en'){
          this.currentLangText = 'English'
          Locale.use('en-US',en)
        }else{
          this.currentLangText = '繁體中文'
          Locale.use('zh-TW', tn)
        }
      },
      immediate: true
    }
  },
  created() {
  },
  methods: {
    logoutBtn(){
      logout().then(res => {
        if(res.code == 0){
          sessionStorage.clear()
          this.$store.commit('setUserInfo', {})
          this.$store.commit('loginState', false)
          this.$router.push({name: 'MLogin'})
        }
      })
    },
    lSelect(item){
      this.currentLangText = item.name
      let lang = ''
      if(item.name == '简体中文'){
        lang = 'cn'
      }else if(item.name == 'English'){
        lang = 'en'
      }else{
        lang = 'tn'
      }
      localStorage.setItem('lang', lang)
      this.$store.commit('changeLanguage', lang)
      this.$i18n.locale = lang
      this.isLang = false
    },
    onUpload(e){
      let file = e.target.files[0]
      let formData = new FormData()
      formData.set('img', file)
      uploadImg(formData).then(res => {
        if(res.code == 0){
          this.isUpload = false
          let user = this.getUserinfo
          user.header_img = res.list[0]
          this.$store.commit('setUserInfo', user)
          sessionStorage.setItem('userInfo', JSON.stringify(user))
        }else{
          this.$message.error('Operated failed')
        }
      })
    },
    onSelect(){
      this.$refs.upload.click()
    }
  }
}
</script>

<style scoped>
@import "../assets/css/common.css";
.invest_area{
  width: 100%;
  height: 20px;
  padding: 20px;
  color: #303133;
  position: relative;
}
.invest_area_top{
  text-align: center;
  font-size: 18px;
  height: 20px;
  line-height: 20px;
}
.invest_area_top_icon{
  position: absolute;
  height: 20px;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
}
.invest_area_top_icon img:first-of-type {
  width: 20px;
  height: 16px;
  margin-right: 15px;
}
.invest_area_top_icon img:nth-of-type(2) {
  width: 25px!important;
  height: 25px!important;
  top: 10px;
}
.menuList {
  padding: 20px 0 100px;
}
.menuItem {
  display: flex;
  justify-content: space-between;
  margin: 0 15px;
  padding: 15px 0;
  align-items: center;
  border-bottom: 1px solid #edeff2;
}
.menuItem .left {
  display: flex;
  align-items: center;
  color: #909399;
  font-size: 14px;
}
.menuItem .left .ico {
  width: 50px!important;
  height: 50px!important;
  border-radius: 50%;
  border: 1px solid #f1f1f1;
}
.langtxt {
  display: inline-block;
  float: right;
  margin-right: 10px;
  color: #000;
}
.logout {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 100;
  background: #fff;
  height: 60px;
  padding: 0 16px;
}
</style>
