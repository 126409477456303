<template>
  <div>
    <div class="details" v-if="giftInfo">
      <div class="dleft">
        <img :src="giftInfo.img" alt="">
      </div>
      <div class="dright">
        <div class="gtitle">{{lang == 'en' ? giftInfo.name_en : giftInfo.name}}</div>
        <p class="ginfo">{{lang == 'en' ? giftInfo.desc_en : giftInfo.desc}}</p>
        <p class="gprice">{{giftInfo.price}} {{ $t('pay.coin') }}</p>
      </div>
    </div>
    <div class="inputitem">
      <input type="text" v-model="username" class="username" :placeholder="$t('user.username')">
    </div>
    <div class="inputitem">
    <textarea rows="8" v-model="content" class="content" placeholder="Leave a message">
    </textarea>
    </div>
    <div class="flexbox send" @click="send">{{ $t('user.send_gift') }}</div>
    <van-loading v-show="loading" />
  </div>
</template>

<script>
import {giftDetail, userInfo} from '@/api/user'
import {mapGetters} from "vuex";
import {sendGift} from "@/api/index";
// import {chatSendMsg} from "@/api/chat";
import { Loading } from 'vant'
import 'vant/lib/loading/style'
export default {
  name: "m_gift_send",
  components: {
    [Loading.name]: Loading
  },
  data(){
    return {
      giftId: 0,
      giftInfo: null,
      lang: 'tn',
      username: '',
      content: '',
      userInfo: null,
      windowMsg: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'getUserMsg',
    ]),
  },
  watch: {
    getLanguage: {
      handler(newV){
        this.lang = newV
      },
      immediate: true
    },
    getUserMsg: {
      handler(v){
        this.windowMsg = v
      },
      immediate: true
    }
  },
  created() {
    this.giftId = this.$route.query.gift_id
    this.username = this.$route.query.username
    this.getGfit()
  },
  methods:{
    getGfit(){
      giftDetail({gift_id: this.giftId}).then(res => {
        if(res.code == 0){
          this.giftInfo = res.data
        }
      })
    },
    send(){
      if(!this.username){
        return
      }
      if(this.loading)
        return
      this.loading = true
      userInfo({username: this.username}).then(res => {
        if(res.code == 0){
          this.userInfo = res.data
          sendGift({
            username: this.userInfo.username,
            gift_id: this.giftInfo.id
          }).then(res => {
            if(res.code == 0){
              this.$message({
                message: 'Operated successful',
                type: 'success'
              })
              // chatSendMsg({
              //   author_id: this.userInfo.id,
              //   content: this.giftInfo.id,
              //   msg_type: 3
              // }).then(res => {
              //   this.loading = false
              //   if(res.code == 100){
              //     this.$message.error(this.$t('chat.upgrade_msg'))
              //   }else if(res.code == 0){
              //     this.addMsg(res.data, this.userInfo.id)
              //   }else{
              //     this.$message.error(this.$t('chat.send_failed'))
              //   }
              // })
              this.$router.back()
            }else{
              let err = res.code == 500 ? this.$t('store.your_balance_is_not_enough') : 'Operated failed'
              this.$message.error(err)
            }
          })
        }else{
          this.loading = false
          this.$message.error(this.$t('chat.send_failed'))
        }
      })
    },
    addMsg(data, author_id = 0){
      // 添加一条聊天
      let windowMsg = this.windowMsg
      this.windowMsg = []
      let msg = {
        id: data.msg_id,
        sender_id: data.user_id,
        reciver_id: data.reciver_id,
        content: data.msg.replace(/\[(face\d+)\]/g, '<img src="img/$1.gif" style="width: 24px;height: 24px;vertical-align: middle;">'),
        msg_type: data.msg_type,
        ndate: data.ndate,
        gift_name_en: data.gift_name_en,
        gift_name: data.gift_name,
        gift_img: data.gift_img,
      }
      if(author_id > 0){
        if(!windowMsg[author_id]){
          windowMsg[author_id] = []
        }
        windowMsg[author_id].push(msg)
      }else{
        if(!windowMsg[data.user_id]){
          windowMsg[data.user_id] = []
        }
        windowMsg[data.user_id].push(msg)
      }

      this.windowMsg = windowMsg
      this.$store.commit('setUserMsg', this.windowMsg)
    }
  }
}
</script>

<style scoped>
@import "../../assets/css/common.css";
.details {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.details .dleft {
  width: 40%;
  padding: 0 0 20px 20px;
}
.details .dleft img {
  width: 100%;
}
.details .dright {
  width: 55%;
  margin-left: 5%;
}
.inputitem {
  margin: 10px 0;
  padding: 10px;
}
.username {
  height: 40px;
  font-size: 16px;
  border-radius: 20px;
  padding: 0 20px;
}
.content, .username {
  border: 0;
  outline: 0;
  resize: none;
  width: 100%;
  background: #f1f2f3;
}
.send {
  width: 70%;
  position: fixed;
  bottom: 20px;
  left: 15%;
  height: 40px;
  background-color: #f43;
  color: #fff;
  border-radius: 20px;
}
</style>
