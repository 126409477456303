<template>
  <div class="flexbox footer">
    <div class="flexbox loginfootercontainer">
      <div class="flexbox linkcontainer">
        <router-link :to="{name: 'Info', params: {query: 'about-us'}}" class="">
          <div class="link" >{{ $t('user.about_us') }}</div>
        </router-link>|
        <router-link :to="{name: 'Info', params: {query: 'terms-of-use'}}" class="">
          <div class="link" >{{ $t('user.terms_of_use') }}</div>
        </router-link>|
        <router-link :to="{name: 'Info', params: {query: 'privacy-policy'}}" class="" >
          <div class="link" >{{ $t('user.privacy_policy') }}</div>
        </router-link>|
        <router-link :to="{name: 'Info', params: {query: 'help-center'}}" class="" >
          <div class="link">{{ $t('user.help_center') }}</div>
        </router-link>|
        <router-link :to="{name: 'Info', params: {query: 'contact-us'}}" class="">
          <div class="link" >{{ $t('user.contact_us') }}</div>
        </router-link>
      </div>
      <div class="payimage" v-html="logoImg">
        {{logoImg}}
      </div>
      <div class="flexbox website" >
        <p >Copyright ©{{ year }}
          <span id="companyp" >{{copy_right_company}}</span>All Rights Reserved.</p>
      </div>
    </div>
    <div @click="openCS" class="serviceimage" style="background-image: url(/img/customer_service.png);"></div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data(){
    return{
      logoImg: window.payLogo,
      copy_right_company: window.copy_right_company,
      year: new Date().getFullYear(),
      contact_us_company: window.contact_us_company,
    }
  },
  props: {

  },
  created() {
    console.log(window)
  },
  methods: {
    openCS(){
      let list = []
      list.push(this.$store.state.serviceInfo)
      for (const i in this.$store.state.contacts) {
        if(this.$store.state.serviceInfo.id != this.$store.state.contacts[i].id){
          list.push(this.$store.state.contacts[i])
        }
      }

      this.$store.commit('setContacts', list)
      // this.$parent.$refs.chatFixed.openUser(this.$store.state.serviceInfo)
      this.$router.push({name: 'PcChat', query: {id:  this.$store.state.serviceInfo.id}})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../assets/css/common.css";
 .footer{
  height: 150px;
  flex-shrink: 0;
   background-color: #b997ae;
}
.loginfootercontainer {
  width: 100%;
  height: 150px;
}
 .linkcontainer{
  width: 100%;
  font-size: 16px;
  color: #513b5a;
}
.payimage{
  width: 134px;
  margin-top: 20px;
}

.website {
  width: 100%;
  font-size: 12px;
  color: #fff;
}

.link {
  color: #7ce6fe;
  padding: 0 8px;
}
.serviceimage{
  position: fixed;
  right: 0;
  bottom: 60px;
  width: 80px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: pointer;
}
</style>
