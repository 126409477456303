<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {chatBind, chatContacts} from '@/api/chat'
import {mapGetters} from "vuex";
import {userInfo} from "@/api/user";
import {pullMsg} from '@/api/chat'
export default {
  data(){
    return {
      userInfo: null,
      pingTimer: null,
      lockReconnect: false, //是否真正建立连接
      timeout: 10 * 1000, //58秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时
    }
  },
  computed: {
    ...mapGetters([
        'getIslogin',
        'getOnlineUser',
    ]),
  },
  watch: {
    getIslogin: {
      handler(v){
        if(v){
          this.getContacts()
          this.$connect(`wss://api.meet-fate.com/wss`, { format: 'json' })
          this.initWebSocket()
        }else{
          this.$disconnect()
        }
      },
      immediate: true
    },
    // getOnlineUser: {
    //   handler(v){
    //     if(Array.isArray(v)){
    //
    //     }
    //   },
    //   immediate: true
    // }
  },
  created() {
    let urlQ = location.href.split('?')
    if(urlQ.length > 1){
      let query = new URLSearchParams('?' + urlQ[1])
      if(query.get('salesman_id')){
        localStorage.setItem('salesman_id', query.get('salesman_id'))
      }
    }
    let language = 'tn'
    if (localStorage.getItem('lang') != 'undefined') {
      language = localStorage.getItem('lang')
    } else {
      localStorage.setItem('lang', this.language)
    }
    this.$store.commit('changeLanguage', language)

    // 设置用户信息
    if(Object.keys(this.$store.state.user).length <= 0){
      let loginState = sessionStorage.getItem('loginState')
      let sUserId = sessionStorage.getItem('user_id')
      if(loginState && sUserId > 0){
        userInfo({user_id: sUserId}).then(res => {
          if(res.code == 0){
            this.userInfo = res.data
            this.$store.commit('setUserInfo', this.userInfo)
            this.$store.commit('loginState', true)
            // 获取联系人
            this.getContacts()
          }
        })
      }
    }
  },
  mounted() {
  },
  methods: {
      // 开启消息补齐强制机制
      pullMsg(){
        pullMsg().then(res => {
          console.log(res)
          if(res.code == 0){
            // 从新获取联系人
            this.getContacts()
            // 返回每个联系人近期的10条聊天记录
            for(let i = 0; i < res.list.length; i ++){
              this.$store.commit('setSocketMessage', res.list[i])
            }
          }
        })
      },
      getContacts(){
        chatContacts().then(res => {
          if(res.code == 0){
            let totalMsg = 0
            for (const i in res.list) {
              totalMsg += res.list[i].count
              if(res.list[i].msg_type == 2){
                res.list[i].content = `<img src="${res.list[i].content}" style="max-width:50px; max-height:50px;">`
              }else{
                res.list[i].content = res.list[i].content.replace(/\[(face\d+)\]/g, '<img src="img/$1.gif" style="width: 24px;height: 24px;vertical-align: middle;">')
              }
            }
            res.list.sort(function (a,b) {
              return b.count - a.count
            })
            this.$store.commit('setTotalMsgCount', totalMsg)
            this.$store.commit('setContacts', res.list)
          }
        })
      },
      // 初始化weosocket
      initWebSocket() {
        // this.$socket.onopen = this.websocketonopen
        // this.$socket.onerror = this.websocketonerror
        // this.$socket.onmessage = this.websocketonmessage
        // this.$socket.onclose = this.websocketclose
        //初始化weosocket
        const wsuri = 'wss://api.meet-fate.com/wss';
        this.websock = new WebSocket(wsuri);
        // 客户端接收服务端数据时触发
        this.websock.onmessage = this.websocketonmessage;
        // 连接建立时触发
        this.websock.onopen = this.websocketonopen;
        // 通信发生错误时触发
        this.websock.onerror = this.websocketonerror;
        // 连接关闭时触发
        this.websock.onclose = this.websocketclose;
      },
      // 链接ws服务器，e.target.readyState = 0/1/2/3   0 CONNECTING ,1 OPEN, 2 CLOSING, 3 CLOSED
      websocketonopen() {
        // this.pingTimer = setInterval(() => {
        //   this.$socket.sendObj({type: 'ping',});
        // }, 30000)
        this.start()
      },
      // 接收端发送过来的信息，整个项目接收信息的唯一入口
      websocketonmessage(e) {
        if (!e.data) {return;}
        let data = JSON.parse(e.data)
        let type = data.type || ''
        switch(type){
          case 'init':
            chatBind({client_id: data.client_id}).then(res => {
              if(res.code == 0){
                // this.$message({
                //   message: 'Connection successful',
                //   type: 'success'
                // })
                this.dialogTableVisible = false

                // 获取客服信息
                userInfo({user_id: 4}).then(res => {
                  if(res.code == 0){
                    this.$store.commit('setServiceInfo', res.data)
                  }
                })

              }else{
                this.$message.error('Connection failed')
              }
            })
            break;
          case 'ping':
            break;
          case 'sping':
            console.log('服务端ping')
            this.reset()
            break
          case "msg":
            // 处理联系人列表未读消息数量
            this.updateTotalMsg(data.data)
            // this.$store.commit('setSocketMessage', data.data)
            break
          case "online": {
            // 处理联系人上线消息
            if (data.uid == 4) {
              let userInfo = null
              userInfo = this.$store.state.serviceInfo
              userInfo.is_online = 1
              this.$store.commit('setServiceInfo', userInfo)
            } else {
              let contacts = this.$store.state.contacts
              for (const i in contacts) {
                if (data.uid == contacts[i].id) {
                  contacts[i].is_online = 1
                  this.$store.commit('setContacts', contacts)
                }
              }
            }

            // 添加到在线用户列表里面
            let onlineUser = Array.isArray(this.getOnlineUser) ? this.getOnlineUser : []
            onlineUser.push(data.uid)
            this.$store.commit('setOnlineUser', Array.from(new Set(onlineUser)))
            break
          }
          case "offline": {
            // 下线
            let onlineUser = this.getOnlineUser
            let newOnlineUser = []
            console.log(onlineUser)
            if(Array.isArray(onlineUser) && onlineUser.length > 0){
              for (const key in onlineUser) {
                if(onlineUser[key] == data.uid){
                  continue
                }
                newOnlineUser.push(onlineUser[key])
              }
              console.log(newOnlineUser)
              this.$store.commit('setOnlineUser', newOnlineUser)
            }
            break
          }
          case "member_online":
            // 处理页面所有会员在线更新
            if(data.uid == 4){
              let userInfo = null
              userInfo = this.$store.state.serviceInfo
              userInfo.is_online = 1
              this.$store.commit('setServiceInfo', userInfo)
            }else{
              let contacts = this.$store.state.contacts
              for (const i in contacts) {
                if(data.uid == contacts[i].id){
                  contacts[i].is_online = 1
                  this.$store.commit('setContacts', contacts)
                }
              }
            }
            break
          default :
            console.log(data)
        }

      },
      updateTotalMsg(data, type = 0){
        // 给联系人新增一个最新消息ID标识，如果同步来的ID 》 当前ID那么
        if(Object.keys(data).length  > 0 && data.user_id == 4){
          // 客服消息
          let userInfo = null
          userInfo = this.$store.state.serviceInfo
          userInfo.is_online = 1
          userInfo.content = data.msg
          if(type > 0) {
            userInfo.count = 0
          }else{
            userInfo.count += 1
          }
          this.$store.commit('setServiceInfo', userInfo)
          // return
        }
        let totalMsg = 0
        let contacts = []
        let isCurrentUser = false
        for (const i in this.$store.state.contacts) {
          let item = this.$store.state.contacts[i]
          if(Object.keys(data).length  > 0 && data.user_id > 0 && data.user_id == item.id){
            if(type > 0) {
              item.count = 0
            }else{
              item.count += 1
              if(data.msg_type == 2){
                item.content = `<img src="${data.msg}" style="max-width:50px; max-height:50px;">`
              }else{
                item.content = data.msg.replace(/\[(face\d+)\]/g, '<img src="img/$1.gif" style="width: 24px;height: 24px;vertical-align: middle;">')
              }
            }
            isCurrentUser = true
          }
          totalMsg += item.count
          contacts.push(item)
        }
        if(!isCurrentUser){
          // 添加一个联系人
          userInfo({user_id: data.user_id}).then(res => {
            if(res.code == 0 && res.data.id > 0){
              if(data.msg_type == 2){
                res.data.content = `<img src="${data.msg}" style="max-width:50px; max-height:50px;">`
              }else{
                res.data.content = data.msg.replace(/\[(face\d+)\]/g, '<img src="img/$1.gif" style="width: 24px;height: 24px;vertical-align: middle;">')
              }
              res.data.avatar = res.data.header_img
              res.data.vip = res.data.viptype
              res.data.msg_time = data.msg_time
              contacts.unshift(res.data)
              totalMsg += res.data.count
              // this.$store.commit('setTotalMsgCount', totalMsg)
              // this.$store.commit('setContacts', contacts)
              // this.$store.commit('setSocketMessage', data)
            }
          })
        }else{
          // this.$store.commit('setTotalMsgCount', totalMsg)
          // this.$store.commit('setContacts', contacts)
          // this.$store.commit('setSocketMessage', data)
        }

        contacts.sort(function (a,b) {
          return b.count - a.count
        })

        this.$store.commit('setTotalMsgCount', totalMsg)
        this.$store.commit('setContacts', contacts)
        this.$store.commit('setSocketMessage', data)
      },
      websocketclose(){
        // console.log(e)
        // if(this.pingTimer){
        //   clearInterval(this.pingTimer);
        //   this.pingTimer = null;
        // }
        // this.$connect(`wss://api.meet-fate.com/wss`, { format: 'json' })
        this.reconnect();
      },
      websocketonerror(e){
        console.log(e)
        // alert('网络错误，请刷新页面')
        // if(this.pingTimer){
        //   clearInterval(this.pingTimer);
        //   this.pingTimer = null;
        // }
        // const that = this
        // setTimeout(function (){
        //   console.log('err recoonet')
        //   that.$connect(`wss://api.meet-fate.com/wss`, { format: 'json' })
        // }, 10000)
        this.reconnect()
      },
    reconnect() {
      //重新连接
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function () {
        //新连接
        that.initWebSocket();
        that.lockReconnect = false;
      }, 5000);
    },
    reset() {
      //重置心跳
      var that = this;
      //清除时间
      clearTimeout(that.timeoutObj);
      clearTimeout(that.serverTimeoutObj);
      //重启心跳
      that.start();
    },
    start() {
        // 消息强行补充
      this.pullMsg()
      //开启心跳
      var self = this;
      self.timeoutObj && clearTimeout(self.timeoutObj);
      self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      self.timeoutObj = setTimeout(function () {
        if (self.websock.readyState == 1) {
          //如果连接正常
          self.websock.send(JSON.stringify({type: 'ping'}));
        } else {
          //否则重连
          self.reconnect();
        }
        self.serverTimeoutObj = setTimeout(function () {
          //超时关闭
          self.websock.close();
        }, self.timeout);
      }, self.timeout);
    },
  }
}
</script>

<style>
*{
  padding:0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}
.el-popper.is-light {
  background-color: transparent;
  border: none;
  padding: 0;
}
.el-popper[x-placement^=top] .popper__arrow {
  border-top-color: #9f759b !important;
}
 .el-popper[x-placement^=top] .popper__arrow::after {
   border-top-color: #9f759b !important;
 }
</style>
