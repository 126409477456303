<template>
  <div :style="'bottom: '+ bottomHeight +'px;'" class="fixedchatwindow" >
    <div class="flexbox-c fixedchatcontent">
      <div class="flexbox fixedchatcontentbody">
        <div @click="toogle" class="flexbox chatheader" style="height: 40px;">
          <div class="userheader">
            <img :src="user.header_img">
            <i class="iconfont icon-V vip" style="color: silver;">
            </i>
          </div>
          <div class="flexbox username">
          <span class="online" style="background-color: rgb(153, 153, 153);">
          </span>{{ user.username }}</div>
          <div class="flexbox optionbox">
            <i v-show="bottomHeight == 370" class="el-icon-arrow-down"></i>
            <i v-show="bottomHeight == 0" class="el-icon-arrow-up"></i>
            <i class="el-icon-close close" @click="closeUser(user.id)">
            </i>
          </div>
        </div>
        <el-scrollbar style="height: 370px;" ref="msgScrollbar">
          <div class="flexbox history" @click="getHistory">{{ $t('chat.view_history') }}</div>
          <div class="flexbox chatbody">
            <div
                class="flexbox chatcontentbox"
                v-for="msg in windowMsg[user.id]"
                :key="msg.id"
                :style="msg.sender_id == getUserinfo.id ? 'justify-content: flex-end; padding-right: 0px; padding-left: 40%;' : 'justify-content: flex-start; padding-right: 40%; padding-left: 0px;'"
            >
              <div :class="msg.sender_id == getUserinfo.id ? 'pop-right': 'pop-left'">
                <div v-if="msg.msg_type == 1" class="expresscontent"  v-html="msg.content">
                  {{msg.content}}
                </div>
                <el-image
                    v-if="msg.msg_type == 2"
                    style="max-width: 200px; height: auto;"
                    :src="msg.content"
                    :preview-src-list="[msg.content]">
                </el-image>
                <div
                    v-if="msg.msg_type == 3"
                    class="flexbox msggift"
                >
                  <div class="img" :style="'background-image: url(' + msg.gift_img + ');'">
                  </div>
                  <div class="flexbox giftname">{{$t('store.gift_name')}}: {{currentLang == 'en' ? msg.gift_name_en : msg.gift_name}}</div>
                </div>
                <span class="ndate">{{msg.ndate}}</span>
              </div>
            </div>
          </div>
        </el-scrollbar>
        <div class="flexbox-s chatinputbox">
          <el-input v-model="send" @change="sendMsg"></el-input>
          <div class="flexbox opratebox">
            <div class="flexbox iconbox">
              <i class="iconfont icon-liwu" style="font-size: 20px;" @click="isShowGiftDialog = true">
              </i>
            </div>
            <div class="flexbox iconbox" @click="openImg">
              <i class="iconfont icon-tupian" style="font-size: 20px;">
              </i>
            </div>
            <input ref="openImgId" @change="chatUploadImg" type="file" accept="image/*" style="display: none;">
            <div class="flexbox iconbox" @click="openBiaoQing">
              <i class="iconfont icon-biaoqing2" style="color: rgb(238, 221, 85); font-size: 20px;">
              </i>
            </div>
            <div class="flexbox sendbtn" @click="sendMsg">{{ $t('user.send') }}</div>
          </div>
          <div class="flexbox expressbox" v-show="is_bq_show">
            <img
                v-for="i in 108" :key="i"
                class="img"
                :src="'img/face' + i + '.gif'"
                @click="addEmoji(i)"
            >
          </div>
        </div>
      </div>
    </div>
    <GiftDialog
        :add-msg="addMsg"
        :current-chat-user="user"
        v-model="isShowGiftDialog"
    />
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {chatHistory, chatSendMsg} from "@/api/chat";
import {uploadImg, userInfo} from "@/api/user";
import GiftDialog from "@/components/GiftDialog";
export default {
  name: 'ChatWindow',
  components: {
    GiftDialog
  },
  data(){
    return {
      search: '',
      send: '',
      is_bq_show: false,
      bottomHeight: 370,
      windowMsg: [],
      page: [],
      currentLang: 'tn',
      isShowGiftDialog: false
    }
  },
  props: {
    user: {
      type: Object,
      default: null
    },
    removeUser: {
      type: Function,
      default: null
    }
  },
  computed: {
    ...mapGetters([
      'getChatWindowOpen',
      'getSocketMessage',
      'getSocketTimestamp',
      'getUserMsg',
      'getUserinfo',
      'getLanguage',
    ]),
  },
  watch: {
    getChatWindowOpen:  {
      handler(v){
        this.bottomHeight = v['id_' + this.user.id] ? 370 : 0
      },
      immediate: true
    },
    getSocketTimestamp: {
      handler(){
        this.handlerMsg(this.getSocketMessage)
      },
      // immediate: true
    },
    getUserMsg: {
      handler(v){
        this.windowMsg = v
      },
      immediate: true
    },
    getLanguage: {
      handler(newV){
        this.currentLang = newV
      },
      immediate: true
    },
  },
  created() {
  },
  methods: {
    openImg(){
      if(this.getUserinfo.viptype != 1){
        this.$message({
          showClose: true,
          message: this.$t('user.permission'),
          type: 'error'
        });
        return
      }
      this.$refs.openImgId.click()
    },
    changeLang(lang) {
      if(lang == 'cn'){
        this.dayjsObj.locale('zh-cn')
      }else if(lang == 'tn'){
        this.dayjsObj.locale('zh-tw')
      }else{
        this.dayjsObj.locale('en')
      }
    },
    chatUploadImg(e){
      let file = e.target.files[0]
      let formData = new FormData()
      formData.set('img', file)
      uploadImg(formData).then(res => {
        if(res.code == 0){
          chatSendMsg({
            author_id: this.user.id,
            content: res.list[0],
            msg_type: 2
          }).then(res => {
            if(res.code == 100){
              this.$message.error(this.$t('chat.upgrade_msg'))
            }else if(res.code == 0){
              this.send = ''
              this.addMsg(res.data, this.user.id)
            }else{
              this.$message.error(this.$t('chat.send_failed'))
            }
          })
        }else{
          this.$message.error('Operated failed')
        }
      })
    },
    scrollDown() {
      if(this.$refs['msgScrollbar'])
        this.$refs['msgScrollbar'].wrap.scrollTop = this.$refs['msgScrollbar'].wrap.scrollHeight
    },
    sendMsg(){
      if(this.send){
        chatSendMsg({
          author_id: this.user.id,
          content: this.send,
          msg_type: 1
        }).then(res => {
          if(res.code == 100){
            this.$message.error(this.$t('chat.upgrade_msg'))
          }else if(res.code == 0){
            this.send = ''
            this.addMsg(res.data, this.user.id)
          }else{
            this.$message.error(this.$t('chat.send_failed'))
          }
        })
      }
    },
    getHistory(){
      let page = 1
      if(this.page[this.user.id]){
        page = this.page[this.user.id]
      }else{
        this.page[this.user.id] = 1
      }
      chatHistory({
        user_id: this.user.id,
        page: page
      }).then(res => {
        if(res.code == 0){
          this.page[this.user.id] += 1
          let historyList = []
          let windowMsg = this.windowMsg
          this.windowMsg = []
          if(windowMsg[this.user.id]){
            historyList = windowMsg[this.user.id]
          }else{
            windowMsg[this.user.id] = historyList
          }
          for (const index in res.list) {
            let sender_id,reciver_id = 0
            if(res.list[index].type == 1) {
              sender_id = res.list[index].user_id
              reciver_id = res.list[index].to_id
            }else{
              reciver_id = res.list[index].user_id
              sender_id = res.list[index].to_id
            }
            if('undefined' == typeof(historyList.find(msg => msg.id == res.list[index].id))) {
              let msg = {
                id: res.list[index].id,
                sender_id: sender_id,
                reciver_id: reciver_id,
                content: res.list[index].content.replace(/\[(face\d+)\]/g, '<img src="img/$1.gif" style="width: 24px;height: 24px;vertical-align: middle;">'),
                msg_type: res.list[index].msg_type,
                ndate: res.list[index].ndate,
                gift_name_en: res.list[index].gift_name_en,
                gift_name: res.list[index].gift_name,
                gift_img: res.list[index].gift_img,
              }
              historyList.unshift(msg)
            }
          }

          windowMsg[this.user.id] = historyList
          this.windowMsg= windowMsg
          console.log(this.windowMsg)
          this.$store.commit('setUserMsg', this.windowMsg)
        }
      })
    },
    handlerMsg(data){
      if(Object.keys(data).length  > 0 && data.user_id > 0){
        console.log(this.getContacts)
        let chatUser = null
        for (const index in this.getContacts) {
          if(this.getContacts[index].id == data.user_id){
            chatUser = this.getContacts[index]
          }
        }
        if(!chatUser){
          userInfo({user_id: data.user_id}).then(res => {
            if(res.code == 0 && res.data.id > 0){
              this.filterContacts.unshift(res.data)
            }
          })
        }else{
          let chatUsers = this.getContacts.filter(u => u.id != chatUser.id)
          chatUsers.unshift(chatUser)
        }
        this.$store.commit('setContacts', this.getContacts)
        this.addMsg(data)
      }
    },
    addMsg(data, author_id = 0){
      // 添加一条聊天
      let windowMsg = this.windowMsg
      this.windowMsg = []
      let msg = {
        id: data.msg_id,
        sender_id: data.user_id,
        reciver_id: data.reciver_id,
        content: data.msg.replace(/\[(face\d+)\]/g, '<img src="img/$1.gif" style="width: 24px;height: 24px;vertical-align: middle;">'),
        msg_type: data.msg_type,
        ndate: data.ndate,
        gift_name_en: data.gift_name_en,
        gift_name: data.gift_name,
        gift_img: data.gift_img,
      }

      if(author_id > 0){
        if(!windowMsg[author_id]){
          windowMsg[author_id] = []
        }

        windowMsg[author_id].push(msg)
      }else{
        if(!windowMsg[data.user_id]){
          windowMsg[data.user_id] = []
        }
        if(windowMsg[data.user_id].find(i => i.id == msg.id)){
          return
        }
        windowMsg[data.user_id].push(msg)
      }

      this.windowMsg = windowMsg
      console.log(this.windowMsg)
      this.$store.commit('setUserMsg', this.windowMsg)
    },
    addEmoji(index){
      this.send += '[face'+ index + ']'
      this.is_bq_show = false
    },
    openBiaoQing(){
      this.is_bq_show = !this.is_bq_show
    },
    closeUser(id){
      if(this.removeUser){
        this.removeUser(id)
      }
    },
    toogle(){
      let openList = this.getChatWindowOpen
      // console.log(openList)
      openList['id_' + this.user.id] = !openList['id_' + this.user.id]
      this.$store.commit('setChatWindowOpen', openList)
    }
  },
  updated() {
    this.scrollDown()
  }
}
</script>
<style scoped>
@import "../assets/css/common.css";
.globalchatwindow{
  max-width: 100%;
  height: 30px;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row-reverse;
  z-index: 2;
}
.fixedchatwindow {
  position: relative;
  width: 290px;
  height: 400px;
  background-color: #f1f2f3;
  overflow: hidden;
  transition: bottom .1s;
  margin-right: 10px;
  border-radius: 4px 4px 0 0;
}
.fixedchatcontent{
  width: 100%;
  height: 400px;
}
.fixedchatcontent .fixedchatcontentbody{
  width: 100%;
  height: 400px;
  flex-direction: column;
  flex-wrap: nowrap;
}
.fixedchatcontent .fixedchatcontentbody .chatheader{
  height: 40px;
  border-bottom: 1px solid hsla(0,0%,100%,.3);
  padding: 0 15px;
  flex-wrap: nowrap;
  flex-shrink: 0;
  cursor: pointer;
}
.fixedchatcontent .fixedchatcontentbody .chatheader .userheader{
  position: relative;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 4px;
  overflow: hidden;
}
.fixedchatcontent .fixedchatcontentbody .chatheader .userheader img{
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.fixedchatcontent .fixedchatcontentbody .chatheader .userheader .vip{
  position: absolute;
  right: 0;
  bottom: 0;
  color: gold;
  font-size: 16px;
  cursor: pointer;
}
.fixedchatcontent .fixedchatcontentbody .chatheader .username{
  font-size: 12px;
  font-weight: 700;
  color: #666;
  justify-content: flex-start;
  padding-left: 10px;
  cursor: pointer;
}
.fixedchatcontent .fixedchatcontentbody .chatheader .username .online{
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 5px;
}
.fixedchatcontent .fixedchatcontentbody .chatheader .optionbox{
  width: auto;
  justify-content: flex-end;
  flex-wrap: nowrap;
}
.fixedchatcontent .fixedchatcontentbody .history{
  font-size: 12px;
  color: #999;
  cursor: pointer;
}
.fixedchatcontent .fixedchatcontentbody .chatbody {
  width: 290px;
  height: 100%;
  overflow: auto;
}
.chatcontentbox{
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
.pop-right{
  position: relative;
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 5px 0 5px 5px;
  margin-right: 10px;
}
.expresscontent{
  width: 100%;
  height: auto;
  vertical-align: baseline;
}
.ndate {
  font-size: 13px;
  color: #999;
}
.pop-left {
  position: relative;
  padding: 5px 10px;
  background-color: #fa9;
  border-radius: 0 5px 5px 5px;
  margin-left: 10px;
}
.fixedchatcontent .fixedchatcontentbody .chatinputbox {
  height: auto;
  flex-shrink: 0;
  border-top: 1px solid hsla(0,0%,100%,.3);
}
.fixedchatcontent .fixedchatcontentbody .chatinputbox .opratebox{
  justify-content: flex-end;
  padding: 5px;
}
.fixedchatcontent .fixedchatcontentbody .chatinputbox .opratebox .iconbox{
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 10px;
}
.fixedchatcontent .fixedchatcontentbody .chatinputbox .opratebox .sendbtn{
  width: auto;
  height: 20px;
  padding: 0 5px;
  background-color: #513b5a;
  border-radius: 3px;
  border: 1px solid #333;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
}
.fixedchatcontent .expressbox img{
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.giftcontainer{
  width: 100%;
  height: auto;
}
.giftcontainer .giftitembox{
  justify-content: flex-start;
  padding: 10px;
}
.giftcontainer .giftitembox .giftitem{
  width: 20%;
  height: auto;
  align-content: space-between;
  font-size: 12px;
  margin: 2.5%;
}
.giftcontainer .giftitembox .giftitem .img{
  width: 100%;
  padding: 50%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}
.giftcontainer .giftitembox .giftitem .giftname{
  height: 20px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333;
}
.giftcontainer .giftitembox .giftitem .giftprice{
  height: 20px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: "#999";
}
.giftcontainer .giftitembox .giftitem .sendbtn{
  padding: 0 5px;
  height: 20px;
  border: 1px solid #ff4a53;
  color: #ff4a53;
  border-radius: 10px;
  cursor: pointer;
}
.details{
  display: flex;
  flex-wrap: wrap;
}
.details .dleft{
  width: 40%;
  padding: 0 0 20px 20px;
}
.details .dright {
  width: 55%;
  margin-left: 5%;
  text-align: left;
}
.sendtextarea{
  margin-top: 20px;
}
.details .dleft img{
  width: 100%;
}

.msggift{
  width: 200px;
  height: auto;
  align-content: space-between;
  font-size: 12px;
  margin: 2.5%;
}
.msggift .img{
  width: 100%;
  padding: 50%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}
.msggift .giftname{
  height: 20px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #da1d1d;
  font-size: 16px;
}
</style>
