<template>
  <div>
  <van-tabs v-model="active">
    <van-tab :title="$t('chat.chats')" name="1">
      <van-search v-model="search" :placeholder="$t('user.search')" />
      <div class="flexbox chatlist">
        <div class="flexbox">
          <van-swipe-cell
              v-for="item in filterContacts"
              :key="item.id"
              style="width: 100%;"
          >
            <div class="flexbox chatitem" @click="openChat(item)">
              <img :src="item.header_img" alt="" class="avatar">
              <div class="flexbox usermsg">
                <div class="flexbox msgbox">
                  <div class="username">
                    <img v-show="item.vip == 2" class="vip" src="/img/dimand.png" >
                    <img v-show="item.vip == 1" class="vip" src="/img/king.png" >
                    <template v-if="item.count > 0">
                      <van-badge  :content="item.count" max="99">{{item.username}}</van-badge>
                    </template>
                    <template v-else>
                      {{item.username}}
                    </template>
                    </div>
                  <div class="chatcontent">
                    <div class="expresscontent singleline" v-html="item.content">{{ item.content }}</div>
                  </div>
                </div>
                <div class="flexbox onlinebox">
                  <div class="circle" :class="item.is_online ? 'online': 'offline'">
                  </div>
                </div>
              </div>
            </div>
            <template #right>
              <div class="flexbox delete" @click="removerUser(item)">
                <van-icon name="delete-o" color="#fff" size="24"/>
              </div>
            </template>
          </van-swipe-cell>
        </div>
      </div>
    </van-tab>
    <van-tab :title="$t('chat.customer')" name="2">
      <div class="flexbox chatlist">
        <div class="flexbox chatitem"  @click="openChat(getServiceInfo)">
          <img :src="getServiceInfo.header_img" alt="" class="avatar">
          <div class="flexbox usermsg">
            <div class="flexbox msgbox">
              <div class="username">
                <i class="iconfont icon-V" style="color: gold;"></i>
                {{getServiceInfo.username}}
              </div>
              <div class="chatcontent">
                <div class="expresscontent singleline">{{ getServiceInfo.content }}</div>
              </div>
            </div>
            <div class="flexbox onlinebox">
              <div class="circle" :class="getServiceInfo.is_online ? 'online': 'offline'">
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-tab>
  </van-tabs>
    <MFooter />
  </div>
</template>

<script>
import { Tab, Tabs ,Search,SwipeCell, Icon ,Badge   } from 'vant'
import 'vant/lib/tab/style'
import 'vant/lib/tabs/style'
import 'vant/lib/search/style'
import 'vant/lib/swipe-cell/style'
import 'vant/lib/icon/style'
import 'vant/lib/badge/style'
import {mapGetters} from "vuex"
import MFooter from "@/components/MFooter"
import {removeContact} from "@/api/user"
export default {
  name: "m_chat_list",
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Search.name]: Search,
    [SwipeCell.name]: SwipeCell,
    [Icon.name]: Icon ,
    [Badge.name]: Badge,
    MFooter,
  },
  data() {
    return {
      active: 1,
      search: ''
    };
  },
  computed: {
    filterContacts(){
      return this.getContacts.filter(u => u.username.includes(this.search))
    },
    ...mapGetters([
      'getContacts',
      'getServiceInfo',
      'getUserinfo',
    ])
  },
  methods:{
    openChat(user){
      if(this.getUserinfo.viptype < 1 && user.id != this.getServiceInfo.id){
        this.$message({
          showClose: true,
          message: this.$t('user.permission'),
          type: 'error'
        });
        let that = this
        setTimeout(function (){
          that.$router.push({name: 'MUpgrade'})
        },1000)

        return
      }

      let oldUser = Object.assign({},user)
      this.$store.commit('setMUserChat', oldUser)
      sessionStorage.setItem('setMUserChat', JSON.stringify(oldUser))
      user.user_id = user.id
      this.$parent.updateTotalMsg(user, 1)
      this.$router.push({name: 'MChat'})
    },
    removerUser(user){
      // 删除联系人
      removeContact({user_id: user.id}).then(res => {
        if(res.code == 0){
          this.$store.commit('setContacts', this.getContacts.filter(u => u.id != user.id))
        }
      })
    }
  }
}
</script>

<style scoped>
@import "../../assets/css/common.css";
.chatlist{
  padding-bottom: 60px;
}
.chatlist .chatitem{
  padding: 11px 15px;
  flex-wrap: nowrap;
}
.chatlist .chatitem .avatar{
  width: 52px;
  height: 52px;
  border-radius: 50%;
  flex-shrink: 0;
}
.chatlist .chatitem .usermsg{
  width: 85%;
  height: 52px;
  flex-wrap: nowrap;
  justify-content: flex-start;
  text-align: left;
}
.chatlist .chatitem .usermsg .msgbox{
  height: 52px;
  padding: 0 10px;
  align-content: space-around;
  justify-content: flex-start;
}
.chatlist .chatitem .usermsg .msgbox .username{
  width: 80%;
}
.chatlist .chatitem .usermsg .msgbox .username .vip{
  width: 20px;
  float: left;
  margin-right: 5px;
}
.chatlist .chatitem .usermsg .msgbox .chatcontent{
  width: 80%;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.singleline{
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.expresscontent{
  width: 100%;
  height: auto;
  vertical-align: baseline;
}
.chatlist .chatitem .usermsg .onlinebox{
  width: 50px;
  flex-shrink: 0;
}
.chatlist .chatitem .usermsg .onlinebox .offline{
  background-color: #aaa;
}
.chatlist .chatitem .usermsg .onlinebox .circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.chatlist .chatitem .usermsg .onlinebox .online{
  background-color: green;
}
.delete{
  background-color: #ff4a53;
  width: 74px;
  height: 74px;
}
.chatlist .chatitem .avatar{
  width: 52px;
  height: 52px;
  border-radius: 50%;
  flex-shrink: 0;
}
</style>
