<template>
  <div class="flexbox-c home">
    <Head />

    <div class="flexbox-c homecontent">
      <div class="flexbox-c homecontentbox" >
        <div class="fullradiusbox bannerbox" >
          <div class="flexbox title">
            <i class="el-icon-user-solid" >
            </i>{{ $t('user.members') }}</div>
          <div class="banner" >
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide
                v-for="item in memberList"
                :key="item.id"
              >
                <el-popover
                    placement="top"
                    width="300"
                    trigger="hover"
                    popper-class="is-light">
                  <div class="cardcontainer">
                    <div class="flexbox card" >
                      <div class="flexbox-s usermsg" >
                        <div class="cardbg" >{{item.sex == 1 ? $t('user.male') : $t('user.female')}},{{item.age}}{{ $t('user.age') }}</div>
                        <div class="flexbox userheader">
                          <img :src="item.header_img ? item.header_img: '/img/avatar.png'" alt="">
                        </div>
                        <div class="userother" >
                          <a class="username" >{{ item.username }}</a>
                          <div class="userstatus" >
                          <span class="online" >
                          </span>{{ $t('user.active') }}：{{item.loginTime}}</div>
                        </div>
                      </div>
                      <div class="flexbox usermenu">
                        <div class="btn">{{ $t('user.follow') }}</div>
                        <div class="btn" >{{ $t('user.send_gift') }}</div>
                      </div>
                    </div>
                  </div>
                  <div slot="reference" @click="toDetail(item.id)"  class="swiper-slide-image"  :style="'background-image: url(' + (item.header_img ? item.header_img: '/img/avatar.png') + ');'">
                    <i v-show="item.viptype == 2" class="iconfont icon-zuanshihuiyuan vip"  style="color: silver;"></i>
                    <i v-show="item.viptype == 1" class="iconfont icon-V vip"  style="color: silver;"></i>
                  </div>
                </el-popover>
              </swiper-slide>
            </swiper>
          </div>
        </div>


        <div class="flexbox-c indexbody" >
          <div class="lefecolumn" >
            <div class="fullradiusbox">
              <div class="flexbox title">
                <i class="iconfont icon-xiangqingon">
                </i>{{ $t('user.my_credits') }}</div>
              <div class="accountbox">
                <div class="flexbox desc">{{ $t('user.balance') }}</div>
                <div class="flexbox acount" >{{$store.state.user.balance}}</div>
                <div class="flexbox btncontainer">
                  <a href="#/w/home/pay" class="flexbox btn" tag="div" >{{ $t('user.buy_more') }}</a>
                </div>
              </div>
            </div>
            <div class="fullradiusbox" >
              <div class="flexbox title">
                <i class="iconfont icon-yonghu-fuben" >
                </i>{{ $t('user.users') }}</div>
              <div class="flexbox communitybox" >
                <div class="flexbox btncontainer" style="margin-top: 0px;">
<!--                  <div class="flexbox btn"  style="border-right: none;">{{ $t('user.latest') }}</div>-->
                  <div class="flexbox btn active" >{{ $t('user.online') }}</div>
                </div>
                <div class="flexbox userbox">
                  <div
                      class="flexbox useravatar"
                      v-for="item in onlineMemberList"
                      :key="item.id"
                  >
                    <el-popover
                        placement="top"
                        width="300"
                        trigger="hover"
                        popper-class="is-light">
                      <div class="cardcontainer">
                        <div class="flexbox card" >
                          <div class="flexbox-s usermsg" >
                            <div class="cardbg" >{{item.sex == 1 ? $t('user.male') : $t('user.female')}},{{item.age}}{{ $t('user.age') }}</div>
                            <div class="flexbox userheader">
                              <img :src="item.header_img ? item.header_img: '/img/avatar.png'" alt="">
                            </div>
                            <div class="userother" >
                              <a class="username" >{{ item.username }}</a>
                              <div class="userstatus" >
                          <span class="online" >
                          </span>{{ $t('user.active') }}：{{item.loginTime}}</div>
                            </div>
                          </div>
                          <div class="flexbox usermenu">
                            <div class="btn">{{ $t('user.follow') }}</div>
                            <div class="btn" >{{ $t('user.send_gift') }}</div>
                          </div>
                        </div>
                      </div>
                      <img slot="reference" :src="item.header_img" >
                    </el-popover>
                    <i v-show="item.viptype == 2" class="iconfont icon-zuanshihuiyuan vip"  style="color: silver;"></i>
                    <i v-show="item.viptype == 1" class="iconfont icon-V vip"  style="color: silver;"></i>
                  </div>
                </div>
                <div class="flexbox btncontainer" >
                  <a href="#/member/pc" class="flexbox btn" tag="div" >{{ $t('user.all') }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="rightcolumn" >
            <div class="halfradiusbox" >
              <div class="flexbox title" >
                <i class="iconfont icon-dongtai2">
                </i>{{ $t('user.newsfeed') }}</div>
              <div class="flexbox-s trendbox" >
                <div
                    class="flexbox-s trenditem"
                    v-for="item in trendList"
                    :key="item.id"
                >
                  <a class="flexbox useravatar" @click="toDetail(item.user_id)">
                    <img :src="item.header_img" >
                    <img v-show="item.viptype == 1" class="vip" src="/img/king.png" >
                    <img v-show="item.viptype == 2" class="vip" src="/img/dimand.png" >
                  </a>
                  <div class="flexbox trendcontent" >
                    <div class="flexbox-s textsector" >
                      <a class="username" >{{ item.username }}</a>
                      <div>{{item.event == 0 ? $t('user.joined_our_site') : (item.event == 1 ? $t('user.modified_his_avatar') :  $t('user.newsfeed'))}}</div>
                    </div>
                    <div class="flexbox-s imagesector">
                      <div class="img">
                        <template v-if="item.type == 0 && item.event == 2">
                          <div v-html="item.content">
                            {{item.content}}
                          </div>
                        </template>
                        <template v-else-if="item.type == 1">
                          <el-image
                              :src="item.path"
                              :preview-src-list="[item.path]">
                          </el-image>
                        </template>
                        <template v-if="item.type == 2">
                          <video :src="item.path" :controls="$store.state.user.viptype == 1 ? 'controls': ''" style="width: 412px;"></video>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="flexbox trendtime" >
                    <div class="report" >舉報</div>
                    <div class="time" >{{ item.timeFormat }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <Footer />
    <ChatFixed ref="chatFixed" />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Head from '@/components/Head.vue'
import ChatFixed from '@/components/ChatFixed.vue'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/zh-cn";
import "dayjs/locale/zh-tw";
import "dayjs/locale/en";
dayjs.extend(relativeTime);
import 'swiper/css/swiper.css'
import {memberList} from '../api/user'
import {trend} from '../api/index'
import {mapGetters} from "vuex";
export default {
  components: { Footer,Head,Swiper, SwiperSlide,ChatFixed },
  directives: {
    swiper: directive
  },
  data() {
    return {
      // 配置参数
      swiperOptions: {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        // Some Swiper option/callback...
      },
      srcList: [],
      memberList: [],
      onlineMemberList: [],
      trendList: [],
      dayjsObj: dayjs
    }
  },

  methods: {
    toDetail(id){
      this.$router.push({name: 'DetailPc', query: {id: id}})
    },
    onSwiper: function (){

    },
    onSlideChange: function (){

    },
    getMemberList(page, limit){
      const that = this
      memberList({page: page, limit: limit}).then(res => {
        if(res.code == 0){
          for (const i in res.list) {
            let item = res.list[i]
            item.loginTime = this.dayjsObj.unix(item.login_time).fromNow()
            this.memberList.push(item)
          }
          that.memberList = res.list
        }
      })
    },
    getOnlineMemberList(page, limit){
      memberList({page: page, limit: limit,online: 1}).then(res => {
        if(res.code == 0){
          for (const i in res.list) {
            let item = res.list[i]
            item.loginTime = this.dayjsObj.unix(item.login_time).fromNow()
            this.onlineMemberList.push(item)
          }
        }
      })
    },
    getTrend(page, limit){
      const that = this
      trend({page: page, limit: limit}).then(res => {
        if(res.code == 0){
          for (const i in res.list) {
            let item = res.list[i]
            if(item.content){
              item.content = item.content.replace(/\[(face\d+)\]/g, '<img src="img/$1.gif" style="width: 24px;height: 24px;vertical-align: middle;">')
            }
            item.timeFormat = that.dayjsObj.unix(res.list[i].create_time).fromNow()
            that.trendList.push(item)
          }
        }
      })
    },
    changeTrendLang(){
      let list = this.trendList
      this.trendList = []
      for (const i in list) {
        let item = list[i]
        item.timeFormat = this.dayjsObj.unix(list[i].create_time).fromNow()
        this.trendList.push(item)
      }
    },
    changeLang(lang) {
      if(lang == 'cn'){
        this.dayjsObj.locale('zh-cn')
      }else if(lang == 'tn'){
        this.dayjsObj.locale('zh-tw')
      }else{
        this.dayjsObj.locale('en')
      }

      this.changeTrendLang()
    }
  },
  created() {
    // 获取用户
    this.getMemberList(1, 30)
    this.getOnlineMemberList(1, 30)
    this.getTrend(1, 20)
  },
  computed:{
    ...mapGetters([
      'getLanguage',
    ]),
    // 计算属性拿到swiper实例
    swiper() {
      // this.$refs.mySwiper拿到Swiper vue组件实例，
      // 而它身上挂载的$swiper就是swiper实例，可以通过swiper实例来操作slider。
      return this.$refs.mySwiper.$swiper
    },
  },
  mounted() {
    this.swiper.slideTo(3, 1000, false)
  },
  watch: {
    getLanguage: {
      handler(newV){
        this.changeLang(newV)
      },
      immediate: true
    },
  }
};
</script>
<style scoped>
@import "../assets/css/common.css";
@import "../assets/css/index.css";
.homecontentbox{
  width: 1000px;
  padding: 100px 0;
}
.homecontentbox .bannerbox{
  width: 1000px;
}
.homecontentbox .fullradiusbox, .homecontentbox .halfradiusbox {
  background: hsla(0,0%,100%,.2) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpi/P//WRoDGmAB4ifYBF9gE/w3Kkiu4P/Bp/0/DbRTIAgQYAC2PiJjuOjlPgAAAABJRU5ErkJggg==) repeat-x 0 0;
  border: none;
  border-bottom: none;
  margin-bottom: 35px;
}
.homecontentbox .fullradiusbox{
  border-radius: 6px;
}
.homecontentbox .fullradiusbox .title, .homecontentbox .halfradiusbox .title {
  justify-content: flex-start;
  padding: 8px 10px;
  font-size: 16px;
}
.banner {
  height: 300px;
}
.banner .swiper-container{
  width: 80%;
  padding: 40px 0;
}
.banner .swiper-slide{
  width: 220px;
  height: 220px;
}
.banner .swiper-slide .swiper-slide-image{
  position: relative;
  background-position: 50%;
  background-size: cover;
  width: 220px;
  height: 220px;
  border: 10px solid #fff;
}

.banner .swiper-slide .swiper-slide-image .vip{
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-size: 30px;
}

.icon-V{
  color: gold !important;
}
.icon-zuanshihuiyuan{
  color: #409eff !important;
}
.homecontentbox .indexbody{
  width: 1000px;
  justify-content: space-between;
}
.homecontentbox .indexbody .lefecolumn{
  width: 400px;
}
.homecontentbox .indexbody .rightcolumn{
  width: 580px;
}
.homecontentbox .fullradiusbox, .homecontentbox .halfradiusbox {
  background: hsla(0,0%,100%,.2) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpi/P//WRoDGmAB4ifYBF9gE/w3Kkiu4P/Bp/0/DbRTIAgQYAC2PiJjuOjlPgAAAABJRU5ErkJggg==) repeat-x 0 0;
  border: none;
  border-bottom: none;
  margin-bottom: 35px;
}
.homecontentbox .halfradiusbox{
  border-radius: 6px 6px 0 0;
}
.homecontentbox .fullradiusbox .title, .homecontentbox .halfradiusbox .title{
  justify-content: flex-start;
  padding: 8px 10px;
  font-size: 16px;
}
.homecontentbox .indexbody .rightcolumn .trendbox{
  font-size: 14px;
  padding: 10px;
}
.trenditem{
  flex-wrap: nowrap;
  margin-bottom: 10px;
}
.trenditem .useravatar{
  width: 50px;
  height: 50px;
  background-color: #ad8cad;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
}
.trenditem .trendcontent{
  padding: 0 5px;
  color: #fff;
}
.trenditem .trendcontent .textsector{
  margin-bottom: 10px;
}
.trenditem .trendcontent .textsector .username{
  color: #7ce6fe;
  padding: 0 5px;
  font-weight: 700;
  cursor: pointer;
}
.trenditem .trendcontent .imagesector {
  padding: 0 10px;
  margin-bottom: 10px;
}
.trenditem .trendcontent .imagesector .img {
  max-width: 200px;
  height: auto;
}
.trenditem .trendtime{
  color: #513b5a;
  width: auto;
  min-height: 50px;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  flex-shrink: 0;
}
.trenditem .trendtime .report{
  display: none;
  width: 100%;
  font-size: 12px;
  text-align: right;
  cursor: pointer;
}
.trenditem .useravatar img{
  width: 44px;
  height: 44px;
  border-radius: 3px;
}
.trenditem .useravatar .vip {
  position: absolute;
  right: 2px;
  bottom: 0;
  font-size: 20px;
  width: 18px;
  height: 18px;
}

.homecontentbox .fullradiusbox, .homecontentbox .halfradiusbox {
  background: hsla(0,0%,100%,.2) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpi/P//WRoDGmAB4ifYBF9gE/w3Kkiu4P/Bp/0/DbRTIAgQYAC2PiJjuOjlPgAAAABJRU5ErkJggg==) repeat-x 0 0;
  border: none;
  border-bottom: none;
  margin-bottom: 35px;
}
.homecontentbox .fullradiusbox .title, .homecontentbox .halfradiusbox .title {
  justify-content: flex-start;
  padding: 8px 10px;
  font-size: 16px;
}
.homecontentbox .accountbox .desc {
  font-size: 12px;
  color: #fff;
  margin-top: 10px;
}
.homecontentbox .accountbox .acount{
  font-size: 20px;
  color: #513b5a;
  margin-top: 5px;
  font-weight: 700;
}
.homecontentbox .accountbox .btncontainer{
  margin-top: 15px;
  justify-content: flex-end;
  align-content: flex-end;
  align-items: flex-end;
  padding: 10px;
}
.homecontentbox .accountbox .btncontainer .btn{
  width: auto;
  padding: 3px 8px;
  color: #fff;
  font-size: 12px;
  background-color: #9c6d97;
  border-radius: 2px;
  border: 1px solid #513b5a;
  cursor: pointer;
}
.homecontentbox .communitybox .btncontainer{
  margin-top: 15px;
  justify-content: flex-end;
  align-content: flex-end;
  align-items: flex-end;
  padding: 10px;
}
.homecontentbox .communitybox .userbox{
  padding: 10px 5px;
  color: #fff;
  font-size: 14px;
}
.homecontentbox .communitybox .btncontainer .active{
  background-color: #513b5a;
}
.homecontentbox .communitybox .btncontainer .btn{
  width: auto;
  padding: 3px 8px;
  color: #fff;
  font-size: 12px;
  background-color: #9c6d97;
  border-radius: 2px;
  border: 1px solid #513b5a;
  cursor: pointer;
}
.homecontentbox .communitybox .btncontainer{
  margin-top: 15px;
  justify-content: flex-end;
  align-content: flex-end;
  align-items: flex-end;
  padding: 10px;
}
.homecontentbox .communitybox .btncontainer .btn {
  width: auto;
  padding: 3px 8px;
  color: #fff;
  font-size: 12px;
  background-color: #9c6d97;
  border-radius: 2px;
  border: 1px solid #513b5a;
  cursor: pointer;
}
.homecontentbox .communitybox .userbox{
  padding: 10px 5px;
  color: #fff;
  font-size: 14px;
}
.homecontentbox .communitybox .userbox .useravatar{
  width: 50px;
  height: 50px;
  background-color: #bd9cbd;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
  margin: 5px;
  position: relative;
}
.homecontentbox .communitybox .userbox .useravatar img{
  width: 44px;
  height: 44px;
  border-radius: 3px;
}
.homecontentbox .communitybox .userbox .useravatar .vip{
  position: absolute;
  right: 2px;
  bottom: 0;
  font-size: 20px;
}
.homecontentbox .communitybox .btncontainer .active{
  background-color: #513b5a;
}
.cardcontainer{
  width: 100%;
  height: 100%;
}
.cardcontainer .card{
  width: 300px;
  height: 160px;
  border-radius: 8px;
  background-color: #9f759b;
  overflow: hidden;
}
.cardcontainer .card .usermsg{
  flex-wrap: nowrap;
  height: 120px;
  position: relative;
}
.cardcontainer .card .usermsg .cardbg{
  position: absolute;
  width: 100%;
  height: 60px;
  top: 60px;
  background-color: #9c6d97;
  color: #fff;
  font-size: 12px;
  padding-left: 130px;
  border-top: 1px solid #a97fa5;
  border-bottom: 1px solid #a97fa5;
}
.cardcontainer .card .usermsg .userheader{
  position: relative;
  width: 120px;
  height: 120px;
}
.cardcontainer .card .usermsg .userother{
  width: 180px;
  height: 120px;
}
.cardcontainer .card .usermenu{
  height: 40px;
  background-color: #9f759b;
  font-size: 12px;
  padding-left: 10px;
  justify-content: flex-start;
}
.cardcontainer .card .usermenu .btn{
  color: #fff;
  padding: 2px 5px;
  border: 1px solid #513b5a;
  background-color: #8f75a1;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 5px;
}
.cardcontainer .card .usermsg .userother .username{
  font-size: 12px;
  color: #7ce6fe;
  cursor: pointer;
  margin-top: 15px;
  margin-left: 10px;
}
.cardcontainer .card .usermsg .userother .userstatus{
  font-size: 12px;
  margin-top: 5px;
  color: #fff;
  margin-left: 10px;
}
.cardcontainer .card .usermsg .userheader img{
  border: 5px solid #d2aed3;
  width: 100px;
  height: 100px;
  border-radius: 8px;
}
.cardcontainer .card .usermsg .userother .online{
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #14e414;
  margin-right: 10px;
}
.cardcontainer .card .usermsg .userother .offline{
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #999;
  margin-right: 10px;
}
</style>
