<template>
  <div>
    <div v-loading="loading" class="chatwindow" :style="'height: ' + bodyHeight + 'px;'">
      <div class="flexbox-c chatcontent">
        <div class="flexbox chatcontentbody" >
          <router-link :to="{name: 'MDetail', query: {id: user.id}}" class="flexbox chatheader" >
            <div class="userheader" >
              <img :src="user.header_img" >
            </div>
            <div class="flexbox username" >
              <img v-show="user.viptype == 1" class="vip" src="/img/king.png" >
              <img v-show="user.viptype == 2" class="vip" src="/img/dimand.png" >
              {{ user.username }}
            </div>
            <div class="optionbox" >
              <i class="van-badge__wrapper van-icon van-icon-arrow" ></i>
            </div>
          </router-link>
          <el-scrollbar  style="height: 100%;width: 100%;" ref="msgScrollbar">
            <div class="flexbox history" v-if="historyBtn" @click="getHistory">{{ $t('chat.view_history') }}</div>
            <div class="flexbox chatbody" >
              <div
                  v-for="msg in windowMsg[user.id]"
                  :key="msg.id"
                  class="flexbox chatcontentbox"
                  :style="msg.sender_id == getUserinfo.id ? 'justify-content: flex-end; padding-right: 0px; padding-left: 40%;':'justify-content: flex-start; padding-right: 40%; padding-left: 0px;'"
              >
                <div :class="msg.sender_id == getUserinfo.id ? 'pop-right': 'pop-left'">
                  <div v-if="msg.msg_type == 1" class="expresscontent"  v-html="msg.content">
                    {{msg.content}}
                  </div>
                  <el-image
                      v-if="msg.msg_type == 2"
                      style="max-width: 200px; height: auto;"
                      :src="msg.content"
                      :preview-src-list="[msg.content]">
                  </el-image>
                  <div
                      v-if="msg.msg_type == 3"
                      class="flexbox msggift"
                  >
                    <div class="img" :style="'background-image: url(' + msg.gift_img + ');'">
                    </div>
                    <div class="flexbox giftname">{{$t('store.gift_name')}}: {{currentLang == 'en' ? msg.gift_name_en : msg.gift_name}}</div>
                  </div>
                  <span class="ndate" >{{msg.ndate}}</span>
                </div>
              </div>
            </div>
          </el-scrollbar>
          <div class="flexbox-s chatinputbox" >
            <div class="flexbox inputctainer" >
              <el-input v-model="send" ></el-input>
              <div class="flexbox sendbtn" @click="sendMsg">
                <i class="el-icon-s-promotion" style="font-size:  22px;">
                </i>
              </div>
            </div>
            <div class="flexbox opratebox" >
              <div class="flexbox iconbox" @click="sendGiftByUser">
                <i class="iconfont icon-liwu"  style="font-size: 20px;" >
                </i>
              </div>
              <div class="flexbox iconbox" @click="openImg">
                <i class="iconfont icon-tupian"  style="font-size: 20px;">
                </i>
              </div>
              <input ref="openImgId" @change="chatUploadImg" type="file" accept="image/*"  style="display: none;">
              <div class="flexbox iconbox" @click="openBiaoQing">
                <i class="iconfont icon-biaoqing2"  style="color: rgb(238, 221, 85); font-size: 20px;">
                </i>
              </div>
            </div>
            <div class="flexbox expressbox" v-show="is_bq_show">
              <img
                  v-for="i in 108" :key="i"
                  class="img"
                  :src="'img/face' + i + '.gif'"
                  @click="addEmoji(i)"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {uploadImg} from '@/api/user'
import {chatHistory,chatSendMsg} from '@/api/chat'
export default {
  components: {

  },
  data() {
    return {
      search: '',
      send: '',
      is_bq_show: false,
      windowMsg: [],
      page: [],
      bodyHeight: 0,
      user: null,
      historyBtn: true,
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'getUserinfo',
      'getContacts',
      'getSocketMessage',
      'getSocketTimestamp',
      'getUserMsg',
      'getMUserChat'
    ])
  },
  watch: {
    getSocketTimestamp: {
      handler(){
        this.handlerMsg(this.getSocketMessage)
      },
      immediate: true
    },
    getUserMsg: {
      handler(v){
        this.windowMsg = v
      },
      immediate: true
    }
  },
  created() {
    if(!this.getMUserChat){
      let sUser = sessionStorage.getItem('setMUserChat')
      if(sUser){
        let user = JSON.parse(sUser)
        this.$store.commit('setMUserChat', user)
      }else{
        this.$router.push({name: 'MContactList'})
      }

    }
    this.user = this.getMUserChat
    // if(this.user.count > 0){
      this.getHistory()
    // }
  },
  methods: {
    openImg(){
      if(this.getUserinfo.viptype != 1 && this.user.id != 4){
        this.$message({
          showClose: true,
          message: this.$t('user.permission'),
          type: 'error'
        });
        return
      }
      this.$refs.openImgId.click()
    },
    chatUploadImg(e){
      this.loading = true
      let file = e.target.files[0]
      let formData = new FormData()
      formData.set('img', file)
      uploadImg(formData).then(res => {
        if(res.code == 0){
          chatSendMsg({
            author_id: this.user.id,
            content: res.list[0],
            msg_type: 2
          }).then(res => {
            this.loading = false
            if(res.code == 100){
              this.$message.error(this.$t('chat.upgrade_msg'))
            }else if(res.code == 0){
              this.send = ''
              this.addMsg(res.data, this.user.id)
            }else{
              this.$message.error(this.$t('chat.send_failed'))
            }
          })
        }else{
          this.loading = false
              this.$message.error('Operated failed')
        }
      })
    },
    sendGiftByUser(){
      this.$router.push({name: 'MGift', query: {username: this.user.username}})
    },
    scrollDown() {
      this.$refs['msgScrollbar'].wrap.scrollTop = this.$refs['msgScrollbar'].wrap.scrollHeight
    },
    sendMsg(){
      if(this.send){
        this.loading = true
        chatSendMsg({
          author_id: this.user.id,
          content: this.send,
          msg_type: 1
        }).then(res => {
          this.loading = false
          if(res.code == 100){
            this.$message.error(this.$t('chat.upgrade_msg'))
          }else if(res.code == 0){
            this.send = ''
            this.addMsg(res.data, this.user.id)
          }else{
            this.$message.error(this.$t('chat.send_failed'))
          }
        })
      }
    },
    getHistory(){
      let page = 1
      if(this.page[this.user.id]){
        page = this.page[this.user.id]
      }else{
        this.page[this.user.id] = 1
      }
      chatHistory({
        user_id: this.user.id,
        page: page
      }).then(res => {
        if(res.code == 0){
          this.page[this.user.id] += 1
          let historyList = []
          let windowMsg = this.windowMsg
          this.windowMsg = []
          if(windowMsg[this.user.id]){
            historyList = windowMsg[this.user.id]
          }else{
            windowMsg[this.user.id] = historyList
          }
          for (const index in res.list) {
            let sender_id,reciver_id = 0
            if(res.list[index].type == 1) {
              sender_id = res.list[index].user_id
              reciver_id = res.list[index].to_id
            }else{
              reciver_id = res.list[index].user_id
              sender_id = res.list[index].to_id
            }
            if('undefined' == typeof(historyList.find(msg => msg.id == res.list[index].id))) {
              let msg = {
                id: res.list[index].id,
                sender_id: sender_id,
                reciver_id: reciver_id,
                content: res.list[index].content.replace(/\[(face\d+)\]/g, '<img src="img/$1.gif" style="width: 24px;height: 24px;vertical-align: middle;">'),
                msg_type: res.list[index].msg_type,
                ndate: res.list[index].ndate,
                gift_name_en: res.list[index].gift_name_en,
                gift_name: res.list[index].gift_name,
                gift_img: res.list[index].gift_img,
              }
              historyList.unshift(msg)
            }
          }

          windowMsg[this.user.id] = historyList
          this.windowMsg= windowMsg
          console.log(this.windowMsg)
          this.$store.commit('setUserMsg', this.windowMsg)
        }
      })
    },
    handlerMsg(data){
      if(data && Object.keys(data).length  > 0 && data.user_id > 0){
        // let chatUser = null
        // for (const index in this.getContacts) {
        //   if(this.getContacts[index].id == data.user_id){
        //     chatUser = this.getContacts[index]
        //   }
        // }
        // if(!chatUser){
        //   userInfo({user_id: data.user_id}).then(res => {
        //     if(res.code == 0 && res.data.id > 0){
        //       this.filterContacts.unshift(res.data)
        //     }
        //   })
        // }else{
        //   let chatUsers = this.getContacts.filter(u => u.id != chatUser.id)
        //   chatUsers.unshift(chatUser)
        // }
        // this.$store.commit('setContacts', this.getContacts)
        this.addMsg(data)
      }
    },
    addEmoji(index){
      this.send += '[face'+ index + ']'
      this.is_bq_show = false
    },
    openBiaoQing(){
      this.is_bq_show = !this.is_bq_show
    },
    _removeDuplicateObj(arr){
      let obj = {};
      arr = arr.reduce((newArr, next) => {
        obj[next.id] ? "" : (obj[next.id] = true && newArr.push(next));
        return newArr;
      }, []);
      return arr;
    },
    addMsg(data, author_id = 0){
      // 添加一条聊天
      let windowMsg = this.windowMsg
      this.windowMsg = []
      let msg = {
        id: data.msg_id,
        sender_id: data.user_id,
        reciver_id: data.reciver_id,
        content: data.msg.replace(/\[(face\d+)\]/g, '<img src="img/$1.gif" style="width: 24px;height: 24px;vertical-align: middle;">'),
        msg_type: data.msg_type,
        ndate: data.ndate,
        gift_name_en: data.gift_name_en,
        gift_name: data.gift_name,
        gift_img: data.gift_img,
      }
      if(author_id > 0){
        if(!windowMsg[author_id]){
          windowMsg[author_id] = []
        }
        windowMsg[author_id].push(msg)
        windowMsg[author_id] = this._removeDuplicateObj(windowMsg[author_id])
      }else{
        if(!windowMsg[data.user_id]){
          windowMsg[data.user_id] = []
        }
        windowMsg[data.user_id].push(msg)
        windowMsg[data.user_id] = this._removeDuplicateObj(windowMsg[data.user_id])
      }


      this.windowMsg = windowMsg
      this.$store.commit('setUserMsg', this.windowMsg)
    }
  },
  updated() {
    this.scrollDown()
  },
  mounted() {
    this.bodyHeight = document.documentElement.clientHeight
    console.log(this.bodyHeight)
  }
}
</script>
<style scoped>
@import "../../assets/css/common.css";
.chatwindow{
  width: 100%;
  height: 100%;
  background-color: hsla(0,0%,100%,.2);
  border: 1px solid hsla(0,0%,100%,.3);
  border-radius: 4px;
  overflow: hidden;
  flex-wrap: nowrap;
}
.chatwindow .chatcontent{
  width: 100%;
  height: 100%;
}
.chatwindow .chatcontent .chatcontentbody{
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: #f1f2f3;
}
.chatwindow .chatcontent .chatcontentbody .chatheader{
  height: 64px;
  border-bottom: 1px solid hsla(0,0%,100%,.3);
  padding: 0 15px;
  flex-wrap: nowrap;
  flex-shrink: 0;
  background-color: #fff;
}
.chatwindow .chatcontent .chatcontentbody .chatheader .userheader{
  width: 50px;
  height: 50px;
  position: relative;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
}
.chatwindow .chatcontent .chatcontentbody .chatheader .userheader img{
  width: 50px;
  height: 50px;
}
.chatwindow .chatcontent .chatcontentbody .chatheader .username{
  font-size: 16px;
  font-weight: 700;
  color: #7ce6fe;
  justify-content: flex-start;
  padding-left: 10px;
  padding-bottom: 15px;
}
.chatwindow .chatcontent .chatcontentbody .chatheader .username .vip {
  width: 22px;
}
.chatwindow .chatcontent .chatcontentbody .chatinputbox{
  height: auto;
  flex-shrink: 0;
  border-top: 1px solid hsla(0,0%,100%,.3);
}
.chatwindow .chatcontent .chatcontentbody .chatinputbox .inputctainer{
  flex-wrap: nowrap;
}
.chatwindow .chatcontent .chatcontentbody .chatinputbox .inputctainer .sendbtn{
  width: 50px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
}
.chatwindow .chatcontent .chatcontentbody .chatinputbox .opratebox{
  justify-content: flex-start;
  padding: 5px;
}
.chatwindow .chatcontent .chatcontentbody .chatinputbox .opratebox .iconbox{
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-right: 10px;
}
.chatwindow .expressbox{
  width: 100%;
  height: auto;
  background-color: #fff;
  justify-content: flex-start;
  align-content: flex-start;
  overflow: hidden;
}
.chatwindow .expressbox img{
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.chatwindow .chatcontent .chatcontentbody .history{
  font-size: 14px;
  color: #7ce6fe;
  cursor: pointer;
}
.chatwindow .chatcontent .chatcontentbody .chatbody{
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #f1f2f3;
}
.chatcontentbox{
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
.pop-right{
  position: relative;
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 5px 0 5px 5px;
  margin-right: 10px;
}
.expresscontent{
  width: 100%;
  height: auto;
  vertical-align: baseline;
}
.ndate{
  font-size: 13px;
  color: #999;
}
.pop-left{
  position: relative;
  padding: 5px 10px;
  background-color: #fa9;
  border-radius: 0 5px 5px 5px;
  margin-left: 10px;
}
.msggift{
  max-width: 200px;
  height: auto;
  align-content: space-between;
  font-size: 12px;
  margin: 2.5%;
}
.msggift .img{
  width: 100%;
  padding: 50%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}
.msggift .giftname{
  height: 20px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #da1d1d;
  font-size: 16px;
}
</style>
