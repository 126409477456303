import request from './request'

/**
* @description 登录
*/
export function login(data) {
    return request.post(`login`, data)
}



/**
 * @description 登录
 */
export function register(data) {
    return request.post(`register`, data)
}

/**
 * @description 退出登录
 */
export function logout() {
    return request.get(`logout`)
}


/**
 * @description 获取用户列表
 */
export function memberList(data) {
    return request.get(`memberList`, data)
}

/**
 * @description 获取用户信息
 */
export function userInfo(data) {
    return request.get(`userInfo`, data)
}

/**
 * @description 关注
 */
export function like(data) {
    return request.get(`like`, data)
}

/**
 * @description 更新头像
 */
export function updateAvatar(data) {
    return request.post(`updateAvatar`, data)
}


/**
 * @description 更新头像
 */
export function uploadImg(data) {
    return request.post(`upload/img`, data)
}


/**
 * @description 更新签名
 */
export function signature(data) {
    return request.post(`updateSignature`, data)
}

/**
 * @description 用户信息编辑
 */
export function userEdit(data) {
    return request.post(`user/edit`, data)
}

/**
 * @description 修改密码
 */
export function changePwd(data) {
    return request.post(`user/editPassword`, data)
}

/**
 * @description 移除联系人
 */
export function removeContact(data) {
    return request.post(`chat/contact/del`, data)
}

/**
 * @description 礼物详情
 */
export function giftDetail(data) {
    return request.get(`store/gift/detail`, data)
}


