import Vue from 'vue'
import VueI18n from 'vue-i18n'
import cn from './cn'
import en from './en'
import tn from './tn'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import twLocale from 'element-ui/lib/locale/lang/zh-TW'
import elmentlocale from 'element-ui/lib/locale'
Vue.use(VueI18n)
const messages = {
    cn: Object.assign(cn, zhLocale),
    tn: Object.assign(tn, twLocale),
    en: Object.assign(en, enLocale),
}
const i18n = new VueI18n({
    messages,
    locale: localStorage.getItem('lang') || 'tn', // 语言标识
})
elmentlocale.i18n((key, value) => i18n.t(key, value))
export default i18n
