<template>
  <div class="flexbox-c home">
    <Head />

    <div class="flexbox-c homecontent">
      <div class="container">
        <div class="title">{{ $t('user.browse_user') }}</div>
        <div class="linkbox">
          <a class="linkitem" :class="tabIndex == 0 ? 'tab-active' : ''" @click="tabIndex = 0">
            <i class="iconfont icon-fix-full"></i> {{ $t('user.whole') }}
          </a>
          <a class="linkitem" :class="tabIndex == 1 ? 'tab-active' : ''" @click="tabIndex = 1">
            <i class="iconfont icon-sousuo"></i> {{ $t('user.search') }}
          </a>
        </div>

        <div class="flexbox-s membercontent" v-show="tabIndex == 0">
          <div
              class="flexbox card"
              v-for="item in memberList"
              :key="item.id"
          >
            <div class="flexbox-s usermsg" >
              <div class="flexbox userheader" @click="toDetail(item.id)">
                  <img :src="item.header_img" alt="" >
              </div>
              <div class="userother" >
                <div class="username" @click="toDetail(item.id)">
                  <span :class="item.is_online > 0 ? 'online' : 'offline'"></span>{{ item.username }}
                  <img v-show="item.viptype == 1" class="vip" src="/img/king.png" >
                  <img v-show="item.viptype == 2" class="vip" src="/img/dimand.png" >
                </div>
                <div class="flexbox usermenu" >
                  <div class="btn" @click="likeOp(item.id, item.like)">
                    <img v-if="item.like == 1" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAA/NJREFUWEfFl1tsFFUcxn//2S5LL4RYxUvirViIQogWNdQSFCJg4zXBNFEbY6m71EuM8dn44IPxxWcTHuzqGiqwUmzVKgVBMBQ0wcYQo/FBY3yCeiFYt7XdnWPOzszOZc9ut5TGeZmdc+bs953v/L9vzhH+50vmwlddXfU0N2/Ftv9AqSVIYcY4RiSBUv8idhPKmiy+U5Cz0t//dzUMIwH10vPtzOY/R6nlzmDl3vQ9+DvQV6ldKRCmialu2T0wGCVTRkD17foZVEsJaKHgwQnAuLwzsD5IIkRgkcGhqIaESJQIqJdf3MD0zOlFmrkD7qlhc6+8u/cr/egTeC514bKteVj2MLhTNv9Iem9TmEBf0quuhRXcnOAuTHqfJaCKCqhXuurJLc8tuNprBdeFXa+WydvZSYdAX98jkB92xlewWjwOq1ZD62r47VeYOO/c9XXrGlh3B6xshR+/h8MjMOlEQUXbCtukf/8Rh0Aq1YFln6wIfu11sL0TGhrDNh4dgRVXw4OPQSzm9+XzMLgPxr8JLGc0M9RGSX845hBIJjdj2ceMbOvq4OkeSCyNZojzfP2N5vZCAd54FXK5sBJersBmSWePuwrs7EA46S9BgG1rK2ztNINUI6D7jo3CoU/8ZQiGmhBQIOUSCHrVW7+2O2FDx6URODsOe/rNtVBGAOUoUJLItcvNLdD58KURGBmCE0fMhSgSUaBIIJBWniMSCehJgWXNrwa0mm++BhcvlBei7rOsKAE7rEBwObQCLbfMj8DvE/DW62Zw3WomUOFTe0UzPPo4NDSUkzC5QNswsxt++iEcw8FJWfZGSQ+5NtzVuwWVP2p0gTfotrWwZVttBE6dgKH9lcGLCnC/pA8e9W1IwQ2iQCFGXdF2F9yzKUwiqsB3Z+CDdHVwXWuWDqKSAs9sx7YPhVxQZkk3ptesCysRJDB2HIazc4M7Bb5DMkMHfQVU3ndBJXDPpjfcBB33wVUrnCScmoLDn8LYl7WCQ0wHkaeADiI1a05Cz47BjNAE9Xdh/d2w9nYYHICJc7WD6/8SeUDe+2jUUaC3dxnWzMXQrsVb6agaprSMkqzlnalEnWSzBX9H9Gx3eENSaeamtAwC1gKuNyKZ4WKy+QSS3VMo5X/yFmvmzgT+lMzHV0YIPLUDxYGqWWCKak+R2mbu1smSdnn/wNchAkVeye4z2La7b5/HAWQ+4CK/SGZ4pVdi5QeT3ie/BdVmVMJ0+lkAeJkCpcLveWITwmegGs3nBDctawNXoP6iEH9I9gyejmZ51cNp8VT3Qlcj09KOsnMgcVCz5R8E3WQtBXuaAtcQ4xy2NJGLf6GtVv6+3zLn6bja4MvR9x+KHK4/B4NQQwAAAABJRU5ErkJggg==" >
                    <img v-else src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAA4hJREFUWEfFl1tPE0EUx/+zu+22tE0xosRoiILRxEuMvogCMsuiPhgT4wMvfggTY/wW+gXkE2DUxBfUlHaNUPVBSTRG8YooCg1bsFzbsjtmVltpu7vdlhrnrZ1zzv83Z8+ZC8F/HqSa/tDQUPBwJtO/Fo3qC+Gw3/T7c3Y+jDGZEJIFEAawxG1kWX7V3d296KZhC5C7dqXz874D93/s3h1loliN0cv8miAIl3p7e++UG1cAfL1x/dOHo8f2eIlah824oijHNvqVAPxjcUuXEDJOKS1CFAFyVy8fHzt34Wkdq6rZxTCMU/39/Y8toIL3xODNhe8de6M1R6vPYVlRFF6sfwG0WIw1qOA8IVFKBUIIszKQTCaD2Wx2xZNn44wiiqIsWQBvBgfPz3R03HOLLRKCVp+E7ZKEtGFgyTCQXjcsl21NTWgNh9AcjkBfzOBjeh454/ecyzitKErMAhi/devkQkvLmJNxVBRxsCkAPynt2terawgGgmjfuRMQhKI7Mwy8n57G9yVrP7Idpml2qaqatCImhocpAoGEnSUPeyISgq9MvGi7q81JAaMTE1hnzHaeMUb7+voeWQAjIyMnBUGwzcB2n4QDwYBzJp0AAHyb+YEP6fnqGXADaJP9aJf9dQGkdR0vZ2c3B9AiSTjUVF8GJqenMfnz5+YAJELQFQn93TDKwzl9Asbw5N07ZB26oaQI3T4B1+MZ4JmwHQ4AuZVlJCe/eOuCagAhQcCRULCiDa3oNgC8DV9PTWFuddUbQCwWU0RRjLvtGjt8PuwPypUmNgAzs7N4q+uuu5BpmqqqqvGqbbgxSpvfj/ZAWUeUAehzc3iVSrmK88nyGjgjCMKDql4AKjKxAcDLygsapmleVFX1bk0ZKDhvkUR0yDLComDVgJnLYTKVwlQm42UNlk1NXWAXlZ8LfJNq3tqCN6kUlvN5z+J/AM6qqvrQysDo6Ggkn897x69Jyt6YUioRQozi8ZZIJOxPjQaIlYfgFxF+IeH/bwTgTeuy5zaOhBCSppRuLQGIx+MXCSG3GyfjHEmSpM6enp5nJQD8h6ZpzxljJff2RgMRQj5TStsLcSseJpqmvWCMHW20sLXaMvGKDBRENU3rYYwNAwhtFoQXHIB5xtg5RVEq3h2uj1PGGNE0jUN0mqa5QgjxMcacGp4X8BqAVgD8FhLWdX1kYGDA9XZa9XW82QxU8/8FdalrMFGzk9oAAAAASUVORK5CYII=" >
                  </div>
                  <div class="btn" @click="openChat(item)">
                    <img src="img/msg.jpg" >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pager" @click="loadMore" v-show="tabIndex == 0">{{ $t('user.more') }}</div>

        <div class="flexbox-s membercontent" v-show="tabIndex == 1">
          <el-input :placeholder="$t('user.username')" v-model="searchInput" @change="searchOp" style="margin-bottom: 25px;">
            <template slot="prepend">{{ $t('user.search_by_name') }}</template>
            <template slot="append"><i class="el-icon-search"></i> {{ $t('user.search') }}</template>
          </el-input>
          <div
              class="flexbox card"
              v-for="item in searchMemberList"
              :key="item.id"
          >
            <div class="flexbox-s usermsg" >
              <div class="flexbox userheader" @click="toDetail(item.id)">
                <img :src="item.header_img" alt="" >
              </div>
              <div class="userother" >
                <div class="username" @click="toDetail(item.id)">
                  <span :class="item.is_online > 0 ? 'online' : 'offline'"></span>{{ item.username }}
                  <img v-show="item.viptype == 1" class="vip" src="/img/king.png" >
                  <img v-show="item.viptype == 2" class="vip" src="/img/dimand.png" >
                </div>
                <div class="flexbox usermenu" >
                  <div class="btn" @click="likeOp(item.id, item.like)">
                    <img v-if="item.like == 1" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAA/NJREFUWEfFl1tsFFUcxn//2S5LL4RYxUvirViIQogWNdQSFCJg4zXBNFEbY6m71EuM8dn44IPxxWcTHuzqGiqwUmzVKgVBMBQ0wcYQo/FBY3yCeiFYt7XdnWPOzszOZc9ut5TGeZmdc+bs953v/L9vzhH+50vmwlddXfU0N2/Ftv9AqSVIYcY4RiSBUv8idhPKmiy+U5Cz0t//dzUMIwH10vPtzOY/R6nlzmDl3vQ9+DvQV6ldKRCmialu2T0wGCVTRkD17foZVEsJaKHgwQnAuLwzsD5IIkRgkcGhqIaESJQIqJdf3MD0zOlFmrkD7qlhc6+8u/cr/egTeC514bKteVj2MLhTNv9Iem9TmEBf0quuhRXcnOAuTHqfJaCKCqhXuurJLc8tuNprBdeFXa+WydvZSYdAX98jkB92xlewWjwOq1ZD62r47VeYOO/c9XXrGlh3B6xshR+/h8MjMOlEQUXbCtukf/8Rh0Aq1YFln6wIfu11sL0TGhrDNh4dgRVXw4OPQSzm9+XzMLgPxr8JLGc0M9RGSX845hBIJjdj2ceMbOvq4OkeSCyNZojzfP2N5vZCAd54FXK5sBJersBmSWePuwrs7EA46S9BgG1rK2ztNINUI6D7jo3CoU/8ZQiGmhBQIOUSCHrVW7+2O2FDx6URODsOe/rNtVBGAOUoUJLItcvNLdD58KURGBmCE0fMhSgSUaBIIJBWniMSCehJgWXNrwa0mm++BhcvlBei7rOsKAE7rEBwObQCLbfMj8DvE/DW62Zw3WomUOFTe0UzPPo4NDSUkzC5QNswsxt++iEcw8FJWfZGSQ+5NtzVuwWVP2p0gTfotrWwZVttBE6dgKH9lcGLCnC/pA8e9W1IwQ2iQCFGXdF2F9yzKUwiqsB3Z+CDdHVwXWuWDqKSAs9sx7YPhVxQZkk3ptesCysRJDB2HIazc4M7Bb5DMkMHfQVU3ndBJXDPpjfcBB33wVUrnCScmoLDn8LYl7WCQ0wHkaeADiI1a05Cz47BjNAE9Xdh/d2w9nYYHICJc7WD6/8SeUDe+2jUUaC3dxnWzMXQrsVb6agaprSMkqzlnalEnWSzBX9H9Gx3eENSaeamtAwC1gKuNyKZ4WKy+QSS3VMo5X/yFmvmzgT+lMzHV0YIPLUDxYGqWWCKak+R2mbu1smSdnn/wNchAkVeye4z2La7b5/HAWQ+4CK/SGZ4pVdi5QeT3ie/BdVmVMJ0+lkAeJkCpcLveWITwmegGs3nBDctawNXoP6iEH9I9gyejmZ51cNp8VT3Qlcj09KOsnMgcVCz5R8E3WQtBXuaAtcQ4xy2NJGLf6GtVv6+3zLn6bja4MvR9x+KHK4/B4NQQwAAAABJRU5ErkJggg==" >
                    <img v-else src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAA4hJREFUWEfFl1tPE0EUx/+zu+22tE0xosRoiILRxEuMvogCMsuiPhgT4wMvfggTY/wW+gXkE2DUxBfUlHaNUPVBSTRG8YooCg1bsFzbsjtmVltpu7vdlhrnrZ1zzv83Z8+ZC8F/HqSa/tDQUPBwJtO/Fo3qC+Gw3/T7c3Y+jDGZEJIFEAawxG1kWX7V3d296KZhC5C7dqXz874D93/s3h1loliN0cv8miAIl3p7e++UG1cAfL1x/dOHo8f2eIlah824oijHNvqVAPxjcUuXEDJOKS1CFAFyVy8fHzt34Wkdq6rZxTCMU/39/Y8toIL3xODNhe8de6M1R6vPYVlRFF6sfwG0WIw1qOA8IVFKBUIIszKQTCaD2Wx2xZNn44wiiqIsWQBvBgfPz3R03HOLLRKCVp+E7ZKEtGFgyTCQXjcsl21NTWgNh9AcjkBfzOBjeh454/ecyzitKErMAhi/devkQkvLmJNxVBRxsCkAPynt2terawgGgmjfuRMQhKI7Mwy8n57G9yVrP7Idpml2qaqatCImhocpAoGEnSUPeyISgq9MvGi7q81JAaMTE1hnzHaeMUb7+voeWQAjIyMnBUGwzcB2n4QDwYBzJp0AAHyb+YEP6fnqGXADaJP9aJf9dQGkdR0vZ2c3B9AiSTjUVF8GJqenMfnz5+YAJELQFQn93TDKwzl9Asbw5N07ZB26oaQI3T4B1+MZ4JmwHQ4AuZVlJCe/eOuCagAhQcCRULCiDa3oNgC8DV9PTWFuddUbQCwWU0RRjLvtGjt8PuwPypUmNgAzs7N4q+uuu5BpmqqqqvGqbbgxSpvfj/ZAWUeUAehzc3iVSrmK88nyGjgjCMKDql4AKjKxAcDLygsapmleVFX1bk0ZKDhvkUR0yDLComDVgJnLYTKVwlQm42UNlk1NXWAXlZ8LfJNq3tqCN6kUlvN5z+J/AM6qqvrQysDo6Ggkn897x69Jyt6YUioRQozi8ZZIJOxPjQaIlYfgFxF+IeH/bwTgTeuy5zaOhBCSppRuLQGIx+MXCSG3GyfjHEmSpM6enp5nJQD8h6ZpzxljJff2RgMRQj5TStsLcSseJpqmvWCMHW20sLXaMvGKDBRENU3rYYwNAwhtFoQXHIB5xtg5RVEq3h2uj1PGGNE0jUN0mqa5QgjxMcacGp4X8BqAVgD8FhLWdX1kYGDA9XZa9XW82QxU8/8FdalrMFGzk9oAAAAASUVORK5CYII=" >
                  </div>
                  <div class="btn" @click="openChat(item)">
                    <img src="img/msg.jpg" >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>


    <Footer />
    <ChatFixed ref="chatFixed" />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Head from '@/components/Head.vue'
import ChatFixed from '@/components/ChatFixed.vue'
import {memberList, like} from "@/api/user";
import {mapGetters} from "vuex";
export default {
  components: {Footer, Head, ChatFixed},
  data() {
    return {
      memberList: [],
      searchMemberList: [],
      page: 1,
      tabIndex: 0,
      searchInput: '',
      timer: null
    }
  },
  computed: {
    ...mapGetters([
      'getUserinfo',
      'getOnlineUser',
    ])
  },
  watch: {
    getOnlineUser: {
      handler(v){
        if(Array.isArray(v)){
          this.checkOnline(v)
        }
      },
      immediate: true
    }
  },
  created() {
    this.getMemberList()
    let that = this
    this.timer = setInterval(function (){
      that.page = 1
      that.getMemberListByOnline()
    }, 5000)
  },
  methods: {
    checkOnline(userIds){
      if(userIds.length < 1)
        return

      let memberList = this.memberList
      for (let i = 0; i < memberList.length; i++) {
        let isOnline = 0
        for (let j = 0; j < userIds.length; j++) {
          if(userIds[j] == memberList[i].id){
            isOnline = 1
            break
          }
        }
        memberList[i].is_online = isOnline
      }

      this.memberList = memberList.sort(function (a, b){
        return b.is_online - a.is_online
      })
    },

    searchOp(){
      if(this.getUserinfo.viptype < 1){
        this.$message({
          showClose: true,
          message: this.$t('user.permission'),
          type: 'error'
        });
        let that = this
        setTimeout(function (){
          that.$router.push({name: 'UpgradePc'})
        },1000)
        return
      }
      if(this.searchInput != ''){
        this.searchMemberList = []
        let req = {kw: this.searchInput, page: 1, limit: 20}
        memberList(req).then(res => {
          if(res.code == 0){
            this.searchMemberList.push(...res.list)
          }
        })
      }
    },
    likeOp(like_id, type){
      like({like_id: like_id, type: type}).then(res => {
        if(res.code == 0){
          let list = this.memberList
          this.memberList = []
          for (const i in list) {
            let item = list[i]
            if(item.id == like_id){
              item.like = type == 1 ? 0 : 1
            }
            // item.timeFormat = this.dayjsObj.unix(list[i].create_time).fromNow()
            this.memberList.push(item)
          }
          this.$message({
            message: 'Operated successful',
            type: 'success'
          });
        }
      })
    },
    openChat(user){
      if(this.getUserinfo.viptype < 1){
        this.$message({
          showClose: true,
          message: this.$t('user.permission'),
          type: 'error'
        });
        let that = this
        setTimeout(function (){
          that.$router.push({name: 'UpgradePc'})
        },1000)
        return
      }
      let list = this.$store.state.contacts
      list.unshift(user)
      this.$store.commit('setContacts', list)
      // this.$refs.chatFixed.openUser(user)
      this.$router.push({name: 'PcChat', query: {id:  user.id}})
    },
    loadMore(){
      this.getMemberList()
    },
    getMemberList(){
      const that = this
      let req = {page: that.page, limit: 30}
      memberList(req).then(res => {
        if(res.code == 0){
          that.memberList.push(...res.list)
          if(res.list.length > 0){
            that.page += 1
          }
        }
      })
    },
    getMemberListByOnline(){
      const that = this
      let req = {page: that.page,online: 1, limit: 30}
      memberList(req).then(res => {
        if(res.code == 0){
          let onlineList = res.list
          let newList  = that.memberList
          that.memberList = []
          newList.unshift(...onlineList)
          that.memberList = this._removeDuplicateObj(newList)
        }
      })
    },
    toDetail(id){
      this.$router.push({name: 'DetailPc', query: {id: id}})
    },
    _removeDuplicateObj(arr){
      let obj = {};
      arr = arr.reduce((newArr, next) => {
        obj[next.id] ? "" : (obj[next.id] = true && newArr.push(next));
        return newArr;
      }, []);
      return arr;
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
}
</script>
<style scoped>
@import "../../assets/css/common.css";
@import "../../assets/css/index.css";
.container{
  width: 1000px;
  padding: 100px 30px;
}
.container .title {
  font-size: 20px;
  color: #513b5a;
  margin-bottom: 28px;
  font-weight: 700;
  text-align: left;
}
.container .linkbox {
  text-align: left;
}
.container .linkbox .router-link-active {
  background-color: #b4534d;
}
.container .linkbox .linkitem{
  padding: 10px 10px;
  background-color: #fff;
  color: #513b5a;
  background-color: transparent;
}
.container .linkbox .router-link-active{
  background-color: #b4534d !important;
}
.container .linkbox .linkitem{
  padding: 10px 10px;
  background-color: #fff;
  color: #513b5a;
  background-color: transparent;
  cursor: pointer;
}
.container .membercontent{
  padding: 10px;
  padding-right: 0;
  background-color: hsla(0,0%,100%,.2);
  margin-top: 30px;
  border-radius: 8px;
  min-height: 400px;
}
.container .membercontent .card{
  width: 49%;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 10px;
  margin-right: 1%;
  overflow: hidden;
}
.container .membercontent .card .usermsg{
  flex-wrap: nowrap;
  height: 180px;
  padding: 30px;
}
.container .membercontent .card .usermsg .userheader{
  width: 120px;
  height: 120px;
  margin-right: 10px;
}
.container .membercontent .card .usermsg .userother{
  width: 100%;
  height: 120px;
}
.container .membercontent .card .usermsg .userheader img{
  width: 120px;
  height: 120px;
  border-radius: 10px;
}
.container .membercontent .card .usermsg .userother .usermenu{
  height: 40px;
  background-color: #f5f5f5;
  font-size: 12px;
  padding-left: 10px;
  justify-content: flex-start;
  margin-top: 40px;
}
.container .membercontent .card .usermsg .userother .username{
  font-size: 16px;
  color: #333;
  cursor: pointer;
  margin-left: 15px;
  position: relative;
  text-align: left;
}
.container .membercontent .card .usermsg .userother .username .online{
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-image: linear-gradient(180deg,#adff2f,green);
  margin-right: 10px;
}
.container .membercontent .card .usermsg .userother .online{
  font-size: 12px;
  margin-top: 5px;
  margin-left: 10px;
  justify-content: flex-start;
}
.container .membercontent .card .usermsg .userother .username .vip{
  position: absolute;
  right: 15px;
  bottom: -2px;
  font-size: 0;
  width: 30px;
  height: 30px;
}
.container .membercontent .card .usermsg .userother .usermenu .btn{
  color: #fff;
  padding: 2px 5px;
  cursor: pointer;
  margin-right: 5px;
  margin-left: 20%;
}
.container .pager{
  text-align: center;
  height: 40px;
  line-height: 40px;
  color: #333;
  cursor: pointer;
}

.tab-active{
  background-color: #b4534d !important;
}
</style>
