<template>
  <div class="flexbox-c home">
    <Head />

    <div class="flexbox-c homecontent">
      <div class="flexbox-c homecontentbox" >

        <div class="container">
          <div class="title">{{ $t('user.upgrade') }}</div>
          <div class="flexbox usermsg">
            <div class="flexbox diy-radio-box" style="width: 14px; height: 14px; border-color: rgb(156, 109, 151); border-width: 2px;">
              <div class="diy-radio-inner" style="background-color: rgb(156, 109, 151);">
              </div>
            </div>
            <span class="objectuser">{{ $t('pay.recharge_for_self') }}</span>
          </div>
          <div class="flexbox butoptionsbox">
            <div class="memberbox">
              <div class="flexbox memberheader">{{ $t('pay.crown_card') }}</div>
              <div style="width: 100%; height: 150px;">
                <div class="el-scrollbar">
                  <div class="el-scrollbar__wrap el-scrollbar__wrap--hidden-default" style="height: 150px; max-height: 150px;">
                    <div class="el-scrollbar__view">
                      <div class="flexbox authoritybox">
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou">
                          </i>{{ $t('pay.allow_profile_wall_posts') }}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{ $t('pay.search_users') }}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{ $t('pay.view_profiles') }}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{ $t('pay.auth_message') }}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{ $t('pay.allow_comments_on_newsfeed') }}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{ $t('pay.dynamic_unrestricted') }}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{ $t('pay.allows_newsfeed_to_be_ent') }}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.upload_photo')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.view_photo')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.comment_photo')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.add_video')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.view_video')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.comment_video')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.start_mail_dialog')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.continue_mail_dialog')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.read_chat_messages')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.send_chat_messages_for_free')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.start_chat_dialog')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.read_mails')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.continue_chat_dialog')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.free_use_of_the_translation_function')}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="el-scrollbar__bar is-horizontal" style="display: none;">
                    <div class="el-scrollbar__thumb" style="transform: translateX(0%);">
                    </div>
                  </div>
                  <div class="el-scrollbar__bar is-vertical" style="display: none;">
                    <div class="el-scrollbar__thumb" style="height: 27.2727%; transform: translateY(0%);">
                    </div>
                  </div>
                </div>
              </div>
              <div class="chargepackagebox">
                <div
                    class="flexbox chargepackageitem"
                    v-for="item in rechargeList[0]"
                    :key="item.id"
                    :class="upgrade == item.id ? 'checkedpackage' : ''"
                    @click="selectUpgrade(item)"
                >
                  <div class="flexbox diy-radio-box" style="width: 14px; height: 14px; border-color: rgb(156, 109, 151); border-width: 2px;">
                    <div v-show="upgrade == item.id" class="diy-radio-inner" style="margin-left: 1px; background-color: rgb(156, 109, 151);"></div>
                  </div>
                  <span>{{ item.price }} USD/{{item.duration}}{{$t('pay.month1')}}</span>
                  <span>{{item.avg}} USD/1{{$t('pay.month1')}}</span>
                  <span class="province">{{$t('pay.province')}} {{item.province}}%</span>
                </div>
              </div>
            </div>
            <div class="memberbox">
              <div class="flexbox memberheader">{{ $t('pay.diamond_card') }}</div>
              <div style="width: 100%; height: 150px;">
                <div class="el-scrollbar">
                  <div class="el-scrollbar__wrap el-scrollbar__wrap--hidden-default" style="height: 150px; max-height: 150px;">
                    <div class="el-scrollbar__view">
                      <div class="flexbox authoritybox">
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.allow_profile_wall_posts')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.search_users')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.view_profiles')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.allow_comments_on_newsfeed')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.allows_newsfeed_to_be_ent')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.upload_photo')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.add_video')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.start_mail_dialog')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.continue_mail_dialog')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.read_chat_messages')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.send_chat_messages_for_free')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.start_chat_dialog')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.read_mails')}}</div>
                        <div class="flexbox authorityitem">
                          <i class="iconfont icon-duigou"></i>{{$t('pay.continue_chat_dialog')}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="el-scrollbar__bar is-horizontal" style="display: none;">
                    <div class="el-scrollbar__thumb" style="transform: translateX(0%);">
                    </div>
                  </div>
                  <div class="el-scrollbar__bar is-vertical" style="display: none;">
                    <div class="el-scrollbar__thumb" style="height: 42.8571%; transform: translateY(0%);">
                    </div>
                  </div>
                </div>
              </div>
              <div class="chargepackagebox">
                <div
                    class="flexbox chargepackageitem"
                    v-for="item in rechargeList[1]"
                    :key="item.id"
                    :class="upgrade == item.id ? 'checkedpackage' : ''"
                    @click="selectUpgrade(item)"
                >
                  <div class="flexbox diy-radio-box" style="width: 14px; height: 14px; border-color: rgb(156, 109, 151); border-width: 2px;">
                    <div v-show="upgrade == item.id" class="diy-radio-inner" style="margin-left: 1px; background-color: rgb(156, 109, 151);"></div>
                  </div>
                  <span>{{ item.price }} USD/{{item.duration}}{{$t('pay.month1')}}</span>
                  <span>{{item.avg}} USD/1{{$t('pay.month1')}}</span>
                  <span class="province">{{$t('pay.province')}} {{item.province}}%</span>
                </div>
              </div>
            </div>
          </div>
          <div class="balance">
            <span class="balance">{{ $t('user.balance') }}</span>:
            <span class="num">{{ balance }}</span>
          </div>
          <div class="flexbox-s paytype">
            <div class="flexbox paytypeitem" :class="pay == 0 ? 'active': ''" @click="selectPay(0)">
              <div class="flexbox diy-radio-box" style="width: 14px; height: 14px; border-color: rgb(156, 109, 151); border-width: 2px;">
                <div v-show="pay == 0" class="diy-radio-inner" style="background-color: rgb(156, 109, 151);"></div>
              </div>
              <div class="paylogo" style="background-image: url(/img/pay_lcropay_ocal.png);"></div>
            </div>
            <div class="flexbox paytypeitem" :class="pay == 1 ? 'active': ''" @click="selectPay(1)">
              <div class="flexbox diy-radio-box" style="width: 14px; height: 14px; border-color: rgb(156, 109, 151); border-width: 2px;">
                <div v-show="pay == 1" class="diy-radio-inner" style="background-color: rgb(156, 109, 151);"></div>
              </div>
              <div class="paylogo" style="background-image: url(/img/pay_cropay.png);"></div>
            </div>
            <div class="flexbox paytypeitem" :class="pay == 2 ? 'active': ''" @click="selectPay(2)">
              <div class="flexbox diy-radio-box" style="width: 14px; height: 14px; border-color: rgb(156, 109, 151); border-width: 2px;">
                <div v-show="pay == 2" class="diy-radio-inner" style="background-color: rgb(156, 109, 151);"></div>
              </div>
              <div class="flexbox paylogo">
                <i class="iconfont icon-meiyuan" style="color: gold; font-size: 30px;"></i>{{$t('user.coin_balance')}}
              </div>
            </div>
          </div>
          <div class="flexbox rechagebox">
            <div class="button" @click="submitBtn">{{ $t('user.upgrade') }}</div>
          </div>
        </div>




      </div>
    </div>


    <Footer />
    <ChatFixed ref="chatFixed" />
    <van-overlay :show="show" @click="show = false">
      <div class="flexbox creditwindow">
        <div class="flexbox formbox" @click.stop>
          <div class="flexbox inputitem">
            <el-input autocomplete="off" :placeholder="$t('pay.card_english_name')" v-model="username">
              <template slot="prepend">{{$t('pay.card_username')}}</template>
            </el-input>
          </div>
          <div class="flexbox inputitem">
            <el-input autocomplete="off" :placeholder="$t('user.email')" v-model="email">
              <template slot="prepend">{{$t('user.email')}}</template>
            </el-input>
          </div>
          <div class="flexbox inputitem">
            <el-input autocomplete="off" :placeholder="$t('pay.card_fullphone')" v-model="phone">
              <template slot="prepend">{{$t('pay.card_fullphone')}}</template>
            </el-input>
          </div>
          <div class="flexbox creditrechargebtncontainer">
            <div class="flexbox creditrechargebtn red" @click="send" >{{ $t('user.upgrade') }}</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <iframe v-show="isIframeShow" class="myIframe" name="iframeMPay" scrolling="yes" frameborder="0" allowtransparency="true"></iframe>
    <form target="iframeMPay" :action="pay_url" method="POST" style="position: absolute;top: 0;z-index: -999;"></form>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Head from '@/components/Head.vue'
import ChatFixed from '@/components/ChatFixed.vue'
import {recharge} from '@/api/index'
import {pay, rechargeGold, payCheck, payInfo} from '@/api/pay'
import { Overlay } from 'vant';
import 'vant/lib/overlay/style'
import {userInfo} from "@/api/user";
import {mapGetters} from "vuex";
export default {
  name: 'UpgradePC',
  components: { Footer,Head,ChatFixed,
    [Overlay.name]: Overlay
  },
  data() {
    return {
      pay: 0,
      upgrade: 0,
      upgraInfo: null,
      rechargeList: [[],[]],
      username: '',
      email: '',
      phone: '',
      isIframeShow: false,
      pay_url: '',
      show: false,
      payInfo: {}
    }
  },
  methods: {
    send(){
      if(this.username == "")
        return
      if(this.email == "")
        return
      if(this.phone == '')
        return

      let total_amount = this.upgraInfo.price
      if(total_amount <= 0){
        return
      }

      pay({
        type: this.payment,
        upgrade_id: this.upgraInfo.id,
        total_amount: total_amount,
        username: this.username,
        email: this.email,
        phone: this.phone,
        success: location.href,
        failed: location.href,
        guid: this.payInfo.guid,
      }).then(res => {
        console.log(res)
        if(res.code == 0 && res.data){
          if(res.data.pay_type == 1){
            // 控制台支付
            location.href = res.data.payment_url
          }else{
            this.pay_url = res.data.payment_url
            // 内嵌支付
            if(res.data.is_iframe_url == 'Y'){
              this.isIframeShow = true
            }else{
              location.href = res.data.payment_url
            }
          }
        }else{
          this.$message({
            message: res.msg,
            type: 'warning'
          });
        }
      })
    },
    submitBtn(){
      if(this.pay == 2){
        rechargeGold({
          vipfor: 0,
          id: this.upgrade
        }).then(res => {
          if(res.code == 0){
            this.$message({
              message: 'Operated successful',
              type: 'success'
            })
            userInfo({user_id: this.$store.state.user.id}).then(res => {
              if(res.code == 0 && res.data.id > 0){
                this.$store.commit('setUserInfo', res.data)
                sessionStorage.setItem('userInfo', JSON.stringify(res.data))
                this.$router.push({name: 'Home'})
              }
            })
          }else{
            this.$message.error(res.msg)
          }
        })
      }else{
        this.show = true
      }

    },
    getRecharge(){
      recharge().then(res => {
        if(res.code == 0){
          this.rechargeList = res.list
          if(this.upgrade == 0)
            this.upgrade = res.list[0][0].id
            this.upgraInfo = res.list[0][0]
        }
      })
    },
    selectPay(i){
      this.pay = i
    },
    selectUpgrade(index){
      this.upgrade = index.id
      this.upgraInfo = index
    },
    async getPayInfo(){
      await payInfo().then(res => {
        console.log(res)
        if(res.code == 0){
          this.payInfo = res.list
          let script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = `https://www.wshtmltool.com/order_bill.js?guid=${this.payInfo.guid}&language=zh-tw&ip=${this.payInfo.ip}&url_referrer_domain=${this.payInfo.url_referrer_domain}`;
          script.defer = true;
          document.body.appendChild(script);
        }
      })
    }
  },
  created() {
    if(this.$route.query.status){
      if(this.$route.query.status == 'Y'){
        // 检查订单状态
          if(this.$route.query.merch_order_id || this.$route.query.merch_order_id > 0){
            payCheck({order_id: this.$route.query.merch_order_id}).then(res => {
              if(res.code == 0){
                this.$message({
                  message: 'Payment succeeded',
                  type: 'success'
                })
                userInfo({user_id: this.$store.state.user.id}).then(res => {
                  if(res.code == 0 && res.data.id > 0){
                    this.$store.commit('setUserInfo', res.data)
                    sessionStorage.setItem('userInfo', JSON.stringify(res.data))
                    this.$router.push({name: 'Home'})
                  }
                })
              }
            })
          }else{
            this.$message({
              message: this.$route.query.message,
              type: 'warning'
            });
          }

      }else if(this.$route.query.status == 'T'){
        this.$message({
          message: this.$route.query.message,
          type: 'warning'
        });
      }
      else{
        this.$message({
          message: 'Payment failed: ' + this.$route.query.message,
          type: 'warning'
        });
      }
    }

    this.getRecharge()
  },
  computed:{
    ...mapGetters(['getUserinfo']),
    balance(){
      return Object.keys(this.getUserinfo).length > 0 ? this.getUserinfo.balance : 0
    }
  },
  mounted() {
    this.getPayInfo()
  }
};
</script>
<style scoped>
@import "../../assets/css/common.css";
@import "../../assets/css/index.css";
.homecontent{
  width: 1000px;
  min-height: 400px;
}
.container {
  width: 1000px;
  padding: 60px 30px;
  background-color: #fff;
}
.container .title {
  font-size: 20px;
  color: #513b5a;
  margin-bottom: 28px;
  font-weight: 700;
  text-align: left;
}
.container .usermsg {
  height: 30px;
  justify-content: flex-start;
  color: #9c6d97;
  padding-left: 15px;
}
.container .usermsg .balance {
  font-size: 16px;
}
.container .usermsg i{
  color: gold;
  font-size: 25px;
  margin-left: 10px;
}
.container .usermsg .num{
  padding-left: 5px;
  padding-right: 30px;
}
.diy-radio-box{
  border-radius: 50%;
  border-style: solid;
  flex-shrink: 0;
}
.diy-radio-inner {
  width: 75%;
  height: 75%;
  border-radius: 50%;
}
.container .usermsg .objectuser{
  font-size: 14px;
  padding-left: 5px;
}
.container .butoptionsbox{
  width: 940px;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
.container .butoptionsbox .memberbox {
  width: 460px;
  border: 1px solid #ccc;
  padding-bottom: 10px;
}
.container .butoptionsbox .memberbox .memberheader {
  height: 40px;
  background-color: #d48bb8;
  color: gold;
  font-size: 20px;
  font-weight: 700;
}
.container .butoptionsbox .memberbox .chargepackagebox{
  width: 100%;
  height: auto;
  padding: 0 10px;
}
.container .butoptionsbox .memberbox .chargepackagebox .checkedpackage {
  border: 1px solid #f88;
  border-radius: 10px;
  background-color: #fee;
}
.container .butoptionsbox .memberbox .chargepackagebox .chargepackageitem .province {
  font-weight: 700;
}
.container .butoptionsbox .memberbox .authoritybox {
  justify-content: flex-start;
}
.container .butoptionsbox .memberbox .authoritybox .authorityitem{
  width: 50%;
  height: 50px;
  font-size: 14px;
  color: #9c6d97;
  justify-content: flex-start;
  overflow: hidden;
  flex-wrap: nowrap;
  text-align: left;
}
.container .butoptionsbox .memberbox .authoritybox .authorityitem i{
  font-size: 20px;
  color: green;
}
.container .butoptionsbox .memberbox .chargepackagebox .chargepackageitem{
  height: 50px;
  border-bottom: 1px solid #f1f1f1;
  font-size: 14px;
  color: #9c6d97;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 6px;
  cursor: pointer;
}
.container .balance{
  color: #9c6d97;
  font-size: 14px;
  margin: 20px 0;
}
.container .paytype .active{
  background: #fff2f3;
  border: 1px solid #ff4a53!important;
}
.container .paytype .paytypeitem {
  width: 200px;
  height: 54px;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 0 10px;
  border: 1px solid #dcdee1;
  border-radius: 10px;
  cursor: pointer;
  margin: 0 20px 20px 20px;
}
.container .paytype .paytypeitem .paylogo {
  width: 150px;
  height: 40px;
  margin-left: 20px;
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: space-around;
}
.container .rechagebox{
  justify-content: flex-end;
}
.container .rechagebox .button{
  padding: 2px 15px;
  color: #fff;
  background-color: #9c6d97;
  border-radius: 4px;
  margin-right: 50px;
  cursor: pointer;
}

.creditwindow {
  height: 100%;
}
.creditwindow .formbox {
  width: 600px;
  padding: 30px;
  background-color: #9c6d97;
}
.creditwindow .formbox .inputitem {
  margin-bottom: 10px;
  justify-content: flex-start;
}
.creditwindow .formbox .creditrechargebtncontainer {
  justify-content: flex-end;
}
.creditwindow .formbox .creditrechargebtncontainer .red {
  background-color: #f33;
}
.creditwindow .formbox .creditrechargebtncontainer .creditrechargebtn {
  width: auto;
  height: 30px;
  padding: 0 15px;
  color: #fff;
  border-radius: 15px;
  cursor: pointer;
}
.myIframe{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
}
</style>
