<template>
  <div class="flexbox-c home">
    <Head />
    <div class="flexbox-c homecontent">
      <div class="container">
        <div class="title">{{ $t('chat.chats') }}</div>


        <div class="flexbox chatwindow" >
          <div class="flexbox-c chatlist" >
            <div class="searchbox" >
              <el-input v-model="search" :placeholder="$t('user.search')"></el-input>
            </div>
            <el-scrollbar >
              <div class="flexbox" >
                <div
                    v-for="item in filterContacts"
                    :key="item.id"
                    class="flexbox chatlistitem"
                    style="background-color: rgba(255, 255, 255, 0.3);"
                    @click="changeUser(item)"
                >
                  <div class="flexbox chaticon"><i class="iconfont icon-liaotian" ></i></div>
                  <template v-if="item.count > 0">
                    <el-badge :value="item.count" class="item">
                      <router-link :to="{name: 'DetailPc', query: {id: item.id}}" class="userheader" >
                        <img :src="item.avatar" >
                        <img v-show="item.vip == 2" class="vip" src="/img/dimand.png" >
                        <img v-show="item.vip == 1" class="vip" src="/img/king.png" >
                      </router-link>
                    </el-badge>
                  </template>
                  <template v-else>
                    <router-link :to="{name: 'DetailPc', query: {id: item.id}}" class="userheader" >
                      <img :src="item.avatar" >
                      <img v-show="item.vip == 2" class="vip" src="/img/dimand.png" >
                      <img v-show="item.vip == 1" class="vip" src="/img/king.png" >
                    </router-link>
                  </template>

                  <div class="flexbox chatcontent" >
                    <div class="flexbox nameline" >
                      <div class="name" >
                    <span class="online"  style="background-color: rgb(153, 153, 153);">
                    </span>{{item.username}}</div>
                      <div class="time" >{{ item.timeFormat }}</div>
                    </div>
                    <div class="flexbox contentline" >
                      <div class="expresscontent singleline"  v-html="item.content">
                        {{ item.content }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flexbox chatlistitem" style="background-color: rgba(255, 255, 255, 0.3);">
                </div>
              </div>
            </el-scrollbar>
          </div>

          <div class="flexbox-c chatcontent" >
            <div class="flexbox chatcontentbody" v-if="currentChatUser">
              <div class="flexbox chatheader" >
                <a :href="'#/w/home/user/detail/' + currentChatUser.id" class="userheader" >
                  <img :src="currentChatUser.avatar" >
                  <img v-show="currentChatUser.vip == 2" class="vip" src="/img/dimand.png" >
                  <img v-show="currentChatUser.vip == 1" class="vip" src="/img/king.png" >
                </a>
                <div class="flexbox username" >{{ currentChatUser.username }}</div>
                <div class="optionbox" >
                  <el-button type="danger" @click="deleteContact" icon="el-icon-delete" circle></el-button>
                </div>
              </div>
              <el-scrollbar ref="msgScrollbar">
                <div class="flexbox history" @click="clickHistory">{{ $t('chat.view_history') }}</div>
                <div class="flexbox chatbody"
                     v-loading="chatLoading"
                >
                  <div
                      v-for="msg in windowMsg[currentChatUser.id]"
                      :key="msg.id"
                      class="flexbox chatcontentbox"
                      :style="msg.sender_id == getUserinfo.id ? 'justify-content: flex-end; padding-right: 0px; padding-left: 40%;' : 'justify-content: flex-start; padding-right: 40%; padding-left: 0px;'">
                    <div :class="msg.sender_id == getUserinfo.id ? 'pop-right': 'pop-left'" >
                      <div v-if="msg.msg_type == 1" class="expresscontent"  v-html="msg.content">
                        {{msg.content}}
                      </div>
                      <el-image
                          v-if="msg.msg_type == 2"
                          style="max-width: 200px; height: auto;"
                          :src="msg.content"
                          :preview-src-list="[msg.content]">
                      </el-image>
                      <div
                          v-if="msg.msg_type == 3"
                          class="flexbox msggift"
                      >
                        <div class="img" :style="'background-image: url(' + msg.gift_img + ');'">
                        </div>
                        <div class="flexbox giftname">{{$t('store.gift_name')}}: {{currentLang == 'en' ? msg.gift_name_en : msg.gift_name}}</div>
                      </div>
                      <span class="ndate" >{{msg.ndate}}</span>
                    </div>
                  </div>
                </div>
              </el-scrollbar>
              <div class="flexbox-s chatinputbox" >
                <el-input ref="sendInput" v-model="send" @keyup.enter.native="sendMsg"></el-input>
                <div class="flexbox opratebox" >
                  <div class="flexbox iconbox" @click="isShowGiftDialog = true">
                    <i class="iconfont icon-liwu"  style="font-size: 20px;">
                    </i>
                  </div>
                  <div class="flexbox iconbox" @click="openImg">
                    <i class="iconfont icon-tupian"  style="font-size: 20px;">
                    </i>
                  </div>
                  <input ref="openImgId" @change="chatUploadImg" type="file" accept="image/*"  style="display: none;">
                  <div class="flexbox iconbox" @click="openBiaoQing">
                    <i class="iconfont icon-biaoqing2"  style="color: rgb(238, 221, 85); font-size: 20px;">
                    </i>
                  </div>
                  <div class="flexbox sendbtn" @click="sendMsg">{{ $t('user.send') }}</div>
                </div>
                <div class="flexbox expressbox" v-show="is_bq_show">
                  <img
                      v-for="i in 108" :key="i"
                      class="img"
                      :src="'img/face' + i + '.gif'"
                      @click="addEmoji(i)"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>

    <GiftDialog
        :add-msg="addMsg"
        :current-chat-user="currentChatUser"
        v-model="isShowGiftDialog"
    />

    <Footer />
    <ChatFixed ref="chatFixed" />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Head from '@/components/Head.vue'
import ChatFixed from '@/components/ChatFixed.vue'
import {mapGetters} from 'vuex'
import {uploadImg} from '@/api/user'
import {chatHistory,chatSendMsg,deleteContact} from '@/api/chat'
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/zh-cn";
import "dayjs/locale/zh-tw";
import "dayjs/locale/en";
dayjs.extend(relativeTime);
import { Tab, Tabs  } from 'vant';
import 'vant/lib/tab/style'
import 'vant/lib/tabs/style'
import GiftDialog from "@/components/GiftDialog";
export default {
  components: {Footer, Head,ChatFixed,GiftDialog,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
  },
  data() {
    return {
      search: '',
      send: '',
      is_bq_show: false,
      currentChatUser: null, // 当前窗口用户
      windowMsg: [],
      page: [],
      chatLoading: false,
      dayjsObj: dayjs,
      currentLang: 'tn',
      isShowGiftDialog: false
    }
  },
  computed: {
    filterContacts(){
      // this.changeLang(this.currentLang)
      return this.getContacts.filter(u => u.username.includes(this.search))
    },
    ...mapGetters([
      'getUserinfo',
      'getContacts',
      'getSocketMessage',
      'getSocketTimestamp',
      'getUserMsg',
      'getLanguage',
      'getTotalMsgCount',
    ])
  },
  watch: {
    getSocketTimestamp: {
      handler(){
        this.handlerMsg(this.getSocketMessage)
      },
      immediate: true
    },
    getUserMsg: {
      handler(v){
        this.windowMsg = v
      },
      immediate: true
    },
    getLanguage: {
      handler(newV){
        this.currentLang = newV
        this.changeLang(newV)
      },
      immediate: true
    },
  },
  created() {
    if(this.$route.query.id){
      let user = this.filterContacts.find(u => u.id == this.$route.query.id)
      if(user){
        this.changeUser(user)
      }
    }
  },
  methods: {
    // 删除联系人
    deleteContact(){
      deleteContact({user_id: this.currentChatUser.id}).then(res => {
        if(res.code == 0){
          let contacts = []
          for (let i = 0; i < this.getContacts.length; i++) {
            if(this.getContacts[i].id != this.currentChatUser.id){
              contacts.push(this.getContacts[i])
            }
          }
          this.currentChatUser = null
          this.$store.commit('setContacts', contacts)
        }
      })
    },
    openImg(){
      if(this.getUserinfo.viptype != 1 && this.currentChatUser.id != 4){
        this.$message({
          showClose: true,
          message: this.$t('user.permission'),
          type: 'error'
        });
        return
      }
      this.$refs.openImgId.click()
    },
    chatUploadImg(e){
      this.chatLoading = true
      let file = e.target.files[0]
      let formData = new FormData()
      formData.set('img', file)
      uploadImg(formData).then(res => {
        this.chatLoading = false
        if(res.code == 0){
          chatSendMsg({
            author_id: this.currentChatUser.id,
            content: res.list[0],
            msg_type: 2
          }).then(res => {
            if(res.code == 100){
              this.$message.error(this.$t('chat.upgrade_msg'))
            }else if(res.code == 0){
              this.send = ''
              this.addMsg(res.data, this.currentChatUser.id)
            }else{
              this.$message.error(this.$t('chat.send_failed'))
            }
          })
        }else{
          this.$message.error('Operated failed')
        }
      })
    },
    scrollDown() {
      if(this.$refs['msgScrollbar'])
        this.$refs['msgScrollbar'].wrap.scrollTop = this.$refs['msgScrollbar'].wrap.scrollHeight
    },
    sendMsg(){
      console.log(this.send);
      if(this.send){
        chatSendMsg({
          author_id: this.currentChatUser.id,
          content: this.send,
          msg_type: 1
        }).then(res => {
          if(res.code == 100){
            this.$message.error(this.$t('chat.upgrade_msg'))
          }else if(res.code == 0){
            this.send = ''
            this.addMsg(res.data, this.currentChatUser.id)
          }else{
            this.$message.error(this.$t('chat.send_failed'))
          }
        })
      }
    },
    clickHistory(){
      this.getHistory()
    },
    getHistory(isCurrentPage = false){
      let page = 1
      if(isCurrentPage){
        if(Object.is(this.page[this.currentChatUser.id], undefined)){
          this.page[this.currentChatUser.id] = 1
        }
      }else{
        if(!Object.is(this.page[this.currentChatUser.id], NaN)){
          page = this.page[this.currentChatUser.id]
        }else{
          this.page[this.currentChatUser.id] = 1
        }
      }
      chatHistory({
        user_id: this.currentChatUser.id,
        page: page
      }).then(res => {
        if(res.code == 0){
          // 置零消息
          let contacts = []
          let count = 0 // 未读消息
          for (let i = 0; i < this.getContacts.length; i++) {
            let item = this.getContacts[i]
            if(item.id == this.currentChatUser.id){
              count = item.count
              item.count = 0
            }

            contacts.push(item)
          }
          this.$store.commit('setTotalMsgCount', this.getTotalMsgCount - count)
          this.$store.commit('setContacts', contacts)


          this.page[this.currentChatUser.id] += 1
          let historyList = []
          let windowMsg = this.windowMsg
          this.windowMsg = []
          if(windowMsg[this.currentChatUser.id]){
            historyList = windowMsg[this.currentChatUser.id]
          }else{
            windowMsg[this.currentChatUser.id] = historyList
          }
          for (const index in res.list) {
            let sender_id,reciver_id = 0
            if(res.list[index].type == 1) {
              sender_id = res.list[index].user_id
              reciver_id = res.list[index].to_id
            }else{
              reciver_id = res.list[index].user_id
              sender_id = res.list[index].to_id
            }
            if('undefined' == typeof(historyList.find(msg => msg.id == res.list[index].id))) {
              let msg = {
                id: res.list[index].id,
                sender_id: sender_id,
                reciver_id: reciver_id,
                content: res.list[index].content.replace(/\[(face\d+)\]/g, '<img src="img/$1.gif" style="width: 24px;height: 24px;vertical-align: middle;">'),
                msg_type: res.list[index].msg_type,
                ndate: res.list[index].ndate,
                gift_name_en: res.list[index].gift_name_en,
                gift_name: res.list[index].gift_name,
                gift_img: res.list[index].gift_img,
              }
              historyList.unshift(msg)
            }
          }

          windowMsg[this.currentChatUser.id] = historyList
          this.windowMsg= windowMsg
          this.$store.commit('setUserMsg', this.windowMsg)
        }
      })
    },
    handlerMsg(data){
      if(data && Object.keys(data).length  > 0 && data.user_id > 0){
        // let chatUser = null
        // let contacts = this.getContacts.length > 0  ? this.getContacts : []
        // for (const index in contacts) {
        //   if(contacts[index].id == data.user_id){
        //     chatUser = contacts[index]
        //   }
        // }

        // this.$store.commit('setContacts', contacts)
        // console.log(data)
        this.addMsg(data)

        // if(!chatUser){
        //   userInfo({user_id: data.user_id}).then(res => {
        //     if(res.code == 0 && res.data.id > 0){
        //       contacts.unshift(res.data)
        //       this.$store.commit('setContacts', contacts)
        //       this.addMsg(data)
        //     }
        //   })
        // }else{
        //   let chatUsers = contacts.filter(u => u.id != chatUser.id)
        //   chatUsers.unshift(chatUser)
        //   this.$store.commit('setContacts', contacts)
        //   this.addMsg(data)
        // }
      }
    },
    changeUser(user){
      if(this.getUserinfo.viptype < 1 && user.id != 4){
        this.$message({
          showClose: true,
          message: this.$t('user.permission'),
          type: 'error'
        });
        return
      }
      this.currentChatUser = user
      this.getHistory(true)
    },
    addEmoji(index){
      this.send += '[face'+ index + ']'
      this.is_bq_show = false
      this.$refs.sendInput.focus()
    },
    openBiaoQing(){
      this.is_bq_show = !this.is_bq_show
    },
    _removeDuplicateObj(arr){
      let obj = {};
      arr = arr.reduce((newArr, next) => {
        obj[next.id] ? "" : (obj[next.id] = true && newArr.push(next));
        return newArr;
      }, []);
      return arr;
    },
    addMsg(data, author_id = 0){
      // 添加一条聊天
      let windowMsg = this.windowMsg
      this.windowMsg = []
      let msg = {
        id: data.msg_id,
        sender_id: data.user_id,
        reciver_id: data.reciver_id,
        content: data.msg.replace(/\[(face\d+)\]/g, '<img src="img/$1.gif" style="width: 24px;height: 24px;vertical-align: middle;">'),
        msg_type: data.msg_type,
        ndate: data.ndate,
        gift_name_en: data.gift_name_en,
        gift_name: data.gift_name,
        gift_img: data.gift_img,
      }
      if(author_id > 0){
        if(!windowMsg[author_id]){
          windowMsg[author_id] = []
        }
        windowMsg[author_id].push(msg)
        windowMsg[author_id] = this._removeDuplicateObj(windowMsg[author_id])
      }else{
        if(!windowMsg[data.user_id]){
          windowMsg[data.user_id] = []
        }
        windowMsg[data.user_id].push(msg)
        windowMsg[data.user_id] = this._removeDuplicateObj(windowMsg[data.user_id])
      }

      this.windowMsg = windowMsg
      this.$store.commit('setUserMsg', this.windowMsg)
    },
    changeLang(lang) {
      if(lang == 'cn'){
        this.dayjsObj.locale('zh-cn')
      }else if(lang == 'tn'){
        this.dayjsObj.locale('zh-tw')
      }else{
        this.dayjsObj.locale('en')
      }

      this.changeMsgTimeLang()
    },
    changeMsgTimeLang(){
      let list = []
      for (const i in this.getContacts) {
        let item = this.getContacts[i]
        item.timeFormat = this.dayjsObj.unix(this.getContacts[i].msg_time).fromNow()
        list.push(item)
      }

      this.$store.commit('setContacts', list)
    },
  },
  updated() {
    this.scrollDown()
  }
}
</script>
<style scoped>
@import "../../assets/css/common.css";
@import "../../assets/css/index.css";
.container{
  width: 1000px;
  padding: 100px 30px;
}
.container .title {
  font-size: 20px;
  color: #513b5a;
  margin-bottom: 28px;
  font-weight: 700;
  text-align: left;
}
.chatwindow{
  width: 940px;
  height: 600px;
  background-color: hsla(0,0%,100%,.2);
  border: 1px solid hsla(0,0%,100%,.3);
  border-radius: 4px;
  overflow: hidden;
  flex-wrap: nowrap;
}
.chatwindow .chatlist{
  width: 320px;
  height: 600px;
  border-right: 1px solid hsla(0,0%,100%,.3);
}
.chatwindow .chatcontent{
  width: 620px;
  height: 600px;
}
.chatwindow .chatlist .searchbox{
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid hsla(0,0%,100%,.3);
}
.chatwindow .chatcontent{
  width: 620px;
  height: 600px;
}
.el-scrollbar {
  width: 100%;
}
.chatwindow .chatlist .chatlistitem{
  width: 100%;
  height: 65px;
  background-color: hsla(0,0%,100%,.2);
  border-bottom: 1px solid hsla(0,0%,100%,.3);
  flex-wrap: nowrap;
  cursor: pointer;
}
.chatwindow .chatlist .chatlistitem .chaticon{
  width: 30px;
  height: 100%;
  color: #fff;
  flex-shrink: 0;
}
.el-badge {
  position: relative;
  vertical-align: middle;
  display: inline-block;
}
.chatwindow .chatlist .chatlistitem .chatcontent{
  width: 235px;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}
.chatwindow .chatlist .chatlistitem .userheader{
  display: block;
  width: 50px;
  height: 50px;
  position: relative;
  flex-shrink: 0;
  border: 5px solid #cebfd2;
  border-radius: 4px;
  overflow: hidden;
}
.chatwindow .chatlist .chatlistitem .userheader img{
  width: 40px;
  height: 40px;
}
.chatwindow .chatlist .chatlistitem .userheader .vip{
  position: absolute;
  right: 0;
  bottom: -2px;
  color: gold;
  font-size: 16px;
  width: 16px;
  height: 16px;
}
.chatwindow .chatlist .chatlistitem .chatcontent .nameline{
  height: 20px;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.chatwindow .chatlist .chatlistitem .chatcontent .contentline{
  height: 24px;
  justify-content: flex-start;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chatwindow .chatlist .chatlistitem .chatcontent .nameline .name{
  color: #7ce6fe;
  font-weight: 700;
}
.chatwindow .chatlist .chatlistitem .chatcontent .nameline .time{
  color: #fff;
}
.chatwindow .chatlist .chatlistitem .chatcontent .nameline .name .online{
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.chatwindow .chatlist .chatlistitem .chatcontent .contentline{
  height: 24px;
  justify-content: flex-start;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.singleline{
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.expresscontent{
  width: 100%;
  height: auto;
  vertical-align: baseline;
}
.chatwindow .chatcontent .chatcontentbody{
  width: 620px;
  height: 600px;
  flex-direction: column;
  flex-wrap: nowrap;
}
.chatwindow .chatcontent .chatcontentbody .chatheader{
  height: 61px;
  border-bottom: 1px solid hsla(0,0%,100%,.3);
  padding: 0 15px;
  flex-wrap: nowrap;
  flex-shrink: 0;
}
.chatwindow .chatcontent .chatcontentbody .chatinputbox{
  height: auto;
  flex-shrink: 0;
  border-top: 1px solid hsla(0,0%,100%,.3);
}
.chatwindow .chatcontent .chatcontentbody .history{
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}
.chatwindow .chatcontent .chatcontentbody .chatbody{
  width: 620px;
  height: 100%;
  overflow: auto;
}
.chatcontentbox{
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
.pop-right{
  position: relative;
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 5px 0 5px 5px;
  margin-right: 10px;
}
.expresscontent{
  width: 100%;
  height: auto;
  vertical-align: baseline;
}
.ndate{
  font-size: 13px;
  color: #999;
}
.pop-left{
  position: relative;
  padding: 5px 10px;
  background-color: #fa9;
  border-radius: 0 5px 5px 5px;
  margin-left: 10px;
}
.chatwindow .chatcontent .chatcontentbody .chatheader{
  height: 61px;
  border-bottom: 1px solid hsla(0,0%,100%,.3);
  padding: 0 15px;
  flex-wrap: nowrap;
  flex-shrink: 0;
}
.chatwindow .chatcontent .chatcontentbody .chatheader .userheader{
  width: 50px;
  height: 50px;
  position: relative;
  flex-shrink: 0;
  border: 5px solid #cebfd2;
  border-radius: 4px;
  overflow: hidden;
}
.chatwindow .chatcontent .chatcontentbody .chatheader .userheader img{
  width: 40px;
  height: 40px;
}
.chatwindow .chatcontent .chatcontentbody .chatheader .username{
  font-size: 19px;
  font-weight: 700;
  color: #fff;
  justify-content: flex-start;
  padding-left: 10px;
}
.chatwindow .chatcontent .chatcontentbody .chatinputbox .opratebox{
  justify-content: flex-end;
  padding: 5px;
}
.chatwindow .chatcontent .chatcontentbody .chatinputbox .opratebox .iconbox{
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-right: 10px;
}
.chatwindow .chatcontent .chatcontentbody .chatinputbox .opratebox .sendbtn{
  width: auto;
  height: 30px;
  padding: 0 8px;
  background-color: #513b5a;
  border-radius: 3px;
  border: 1px solid #333;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}
/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}
.el-scrollbar{
  height: 100%;
}
.chatwindow .expressbox{
  width: 100%;
  height: auto;
  background-color: #fff;
  justify-content: flex-start;
  align-content: flex-start;
  overflow: hidden;
}
.chatwindow .expressbox img{
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.giftcontainer{
  width: 100%;
  height: auto;
}
.giftcontainer .giftitembox{
  justify-content: flex-start;
  padding: 10px;
}
.giftcontainer .giftitembox .giftitem{
  width: 20%;
  height: auto;
  align-content: space-between;
  font-size: 12px;
  margin: 2.5%;
}
.giftcontainer .giftitembox .giftitem .img{
  width: 100%;
  padding: 50%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}
.giftcontainer .giftitembox .giftitem .giftname{
  height: 20px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333;
}
.giftcontainer .giftitembox .giftitem .giftprice{
  height: 20px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: "#999";
}
.giftcontainer .giftitembox .giftitem .sendbtn{
  padding: 0 5px;
  height: 20px;
  border: 1px solid #ff4a53;
  color: #ff4a53;
  border-radius: 10px;
  cursor: pointer;
}
.msggift{
  width: 200px;
  height: auto;
  align-content: space-between;
  font-size: 12px;
  margin: 2.5%;
}
.msggift .img{
  width: 100%;
  padding: 50%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}
.msggift .giftname{
  height: 20px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #da1d1d;
  font-size: 16px;
}
</style>
