<template>
  <div class="flexbox-c home">
    <Head />

    <div class="flexbox-c homecontent">
      <div class="container" v-if="userInfo">
        <div class="title">{{ userInfo.username }}</div>

        <div class="flexbox-s buttonbox">
          <div class="button" @click="openChat">{{ $t('user.chat_btn') }}</div>
          <div class="button" @click="likeOp">{{ userInfo.like == 1 ? $t('user.followed') : $t('user.follow') }}</div>
          <a href="#/w/home/store/index" class="button">{{ $t('user.send_gift') }}</a>
<!--          <div class="button">举报</div>-->
          <a href="#/w/home/photo/1256" class="button">{{ $t('user.photo_btn') }}</a>
        </div>


        <div class="flexbox-s usercontent" >
          <div class="leftcontent" >
            <div class="flexbox fullradiusbox userheader" >
              <img :src="userInfo.header_img" >
              <img v-show="userInfo.viptype == 1" class="vip" src="/img/king.png" >
              <img v-show="userInfo.viptype == 2" class="vip" src="/img/dimand.png" >
              <div :class="userInfo.is_online > 0 ? 'online' :'offline'"></div>
            </div>
          </div>
          <div class="rightcontent" >
            <div class="flexbox usermsgbox" >
              <div class="flexbox item" >
                <div class="flexbox left" >{{ $t('user.username') }}</div>
                <div class="flexbox right" >{{ userInfo.username }}</div>
              </div>
              <div class="flexbox item" >
                <div class="flexbox left" >{{ $t('user.sex') }}</div>
                <div class="flexbox right" >{{ userInfo.sex == 1 ? $t('user.male') : $t('user.female') }}</div>
              </div>
            </div>
            <div class="flexbox trendbox" >
              <div class="flexbox halfradiusbox" >
                <div class="flexbox title" >
                  <i class="iconfont icon-dongtai2" ></i>{{$t('user.newsfeed')}}
                </div>
              </div>
              <div class="flexbox-s trendlist" >
                  <div class="flexbox-s trenditem"
                       v-for="item in trendList"
                       :key="item.id"
                  >
                    <a class="flexbox useravatar" >
                      <img :src="item.header_img" >
                      <img v-show="item.viptype == 1" class="vip" src="/img/king.png" >
                      <img v-show="item.viptype == 2" class="vip" src="/img/dimand.png" >
                    </a>
                    <div class="flexbox trendcontent" >
                      <div class="flexbox-s textsector" >
                        <a class="username" >{{ item.username }}</a>
                        <div>{{item.event == 0 ? $t('user.joined_our_site') : (item.event == 1 ? $t('user.modified_his_avatar') :  $t('user.newsfeed'))}}</div>
                      </div>
                      <div class="flexbox-s imagesector" >
                        <div class="img">
                          <template v-if="item.type == 0 && item.event == 2">
                            <div v-html="item.content">
                              {{item.content}}
                            </div>
                          </template>
                          <template v-else-if="item.type == 1">
                            <el-image
                                :src="item.path"
                                :preview-src-list="[item.path]">
                            </el-image>
                          </template>
                          <template v-if="item.type == 2">
                            <video :src="item.path" :controls="loginUser.viptype == 1 ? 'controls': ''" style="width: 412px;"></video>
                          </template>
                        </div>
                      </div>
                      <div class="comment-box" v-if="item.comment.length > 0">
                        <div
                            class="flexbox-s trenditem comment-box-line"
                            v-for="co in item.comment"
                            :key="co.id"
                        >
                          <a class="flexbox useravatar">
                            <img :src="co.header_img">
                            <img v-show="co.viptype == 1" class="vip" src="/img/king.png" >
                            <img v-show="co.viptype == 2" class="vip" src="/img/dimand.png" >
                          </a>
                          <div class="flexbox trendcontent">
                            <div class="flexbox-s textsector">
                              <a class="username">{{ co.username }}</a>
                              <div >{{ $t('user.comments') }}</div>
                            </div>
                            <div class="flexbox-s imagesector" v-html="co.content">
                              {{ co.content }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flexbox trendtime" >
                      <!--                    <div class="report" >举报</div>-->
                      <div class="time" >{{ item.timeFormat }}</div>
                    </div>

                  </div>
              </div>
            </div>
          </div>
        </div>




      </div>
    </div>


    <Footer />
    <ChatFixed ref="chatFixed" />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Head from '@/components/Head.vue'
import ChatFixed from '@/components/ChatFixed.vue'
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/zh-cn";
import "dayjs/locale/zh-tw";
import "dayjs/locale/en";
dayjs.extend(relativeTime);
import {like, userInfo} from '@/api/user'
import {trend} from '@/api/index'
import {mapGetters} from "vuex";
export default {
  components: {Footer, Head, ChatFixed},
  data() {
    return {
      userId: 0,
      userInfo: null,
      trendList: [],
      dayjsObj: dayjs
    }
  },
  computed:{
    ...mapGetters([
      'getLanguage',
      'getUserinfo',
    ]),
    loginUser(){
      return this.getUserinfo
    }
  },
  watch: {
    getLanguage: {
      handler(newV){
        this.changeLang(newV)
      },
      immediate: true
    }
  },
  created() {
    this.userId = this.$route.query.id
    this.getUserInfo(this.userId)
    this.getTrend(this.userId, 1, 30)
  },
  methods: {
    likeOp(){
      like({like_id: this.userInfo.id, type: this.userInfo.like}).then(res => {
        if(res.code == 0){
          this.$set(this.userInfo, 'like', this.userInfo.like == 1 ? 0 : 1 )
          this.$message({
            message: 'Operated successful',
            type: 'success'
          });
        }
      })
    },
    openChat(){
      let list = this.$store.state.contacts
      list.unshift(this.userInfo)
      this.$store.commit('setContacts', list)
      this.$router.push({name: 'PcChat', query: {id:  this.userInfo.id}})
    },
    getUserInfo(id){
      userInfo({user_id: id}).then(res => {
        if(res.code == 0){
          this.userInfo = res.data
        }
      })
    },
    getTrend(id, page, limit){
      const that = this
      trend({page: page, limit: limit,user_id: id}).then(res => {
        if(res.code == 0){
          for (const i in res.list) {
            let item = res.list[i]
            if(item.content){
              item.content = item.content.replace(/\[(face\d+)\]/g, '<img src="img/$1.gif" style="width: 24px;height: 24px;vertical-align: middle;">')
            }
            if(item.comment.length > 0){
              for (const co in item.comment) {
                if(item.comment[co].content)
                  item.comment[co].content = item.comment[co].content.replace(/\[(face\d+)\]/g, '<img src="img/$1.gif" style="width: 24px;height: 24px;vertical-align: middle;">')
              }
            }
            item.timeFormat = that.dayjsObj.unix(res.list[i].create_time).fromNow()
            that.trendList.push(item)
          }
        }
      })
    },
    changeTrendLang(){
      let list = this.trendList
      this.trendList = []
      for (const i in list) {
        let item = list[i]
        item.timeFormat = this.dayjsObj.unix(list[i].create_time).fromNow()
        this.trendList.push(item)
      }
    },
    changeLang(lang) {
      if(lang == 'cn'){
        this.dayjsObj.locale('zh-cn')
      }else if(lang == 'tn'){
        this.dayjsObj.locale('zh-tw')
      }else{
        this.dayjsObj.locale('en')
      }

      this.changeTrendLang()
    }
  }
}
</script>
<style scoped>
@import "../../assets/css/common.css";
@import "../../assets/css/index.css";
.container{
  width: 1000px;
  padding: 100px 30px;
}
.container .title {
  font-size: 20px;
  color: #513b5a;
  font-weight: 700;
  text-align: left;
}
.container .buttonbox {
  margin-top: 30px;
}
.container .buttonbox .button {
  width: auto;
  padding: 3px 8px;
  color: #fff;
  font-size: 12px;
  background-color: #9c6d97;
  border-radius: 2px;
  border: 1px solid #513b5a;
  cursor: pointer;
  margin-right: 10px;
}
.container .usercontent{
  justify-content: space-between;
  margin-top: 30px;
  font-size: 14px;
}
.container .usercontent .leftcontent{
  width: 215px;
}
.container .usercontent .rightcontent{
  width: 705px;
}
.container .usercontent .leftcontent .userheader{
  position: relative;
  width: 215px;
  height: 215px;
}
.container .usercontent .leftcontent .userheader img{
  width: 175px;
  height: 175px;
}
.container .usercontent .leftcontent .userheader .offline{
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb(170, 170, 170);
}
.container .usercontent .leftcontent .userheader .online{
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #14e414;
}

.container .usercontent .rightcontent .usermsgbox {
  color: #fff;
}
.container .usercontent .rightcontent .usermsgbox .item {
  height: 30px;
}
.container .usercontent .rightcontent .usermsgbox .item .left{
  width: 30%;
  justify-content: flex-end;
  padding: 0 5px;
  border-right: 1px solid hsla(0,0%,100%,.5);
}
.container .usercontent .rightcontent .usermsgbox .item .right {
  width: 70%;
  justify-content: flex-start;
  padding: 0 5px;
}
.container .usercontent .rightcontent .trendbox {
  margin-top: 30px;
}
.fullradiusbox, .halfradiusbox{
  background: hsla(0,0%,100%,.2) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpi/P//WRoDGmAB4ifYBF9gE/w3Kkiu4P/Bp/0/DbRTIAgQYAC2PiJjuOjlPgAAAABJRU5ErkJggg==) repeat-x 0 0;
  border: none;
  border-bottom: none;
}
.halfradiusbox{
  border-radius: 6px 6px 0 0;
}
.fullradiusbox .title, .halfradiusbox .title {
  justify-content: flex-start;
  padding: 8px 10px;
  font-size: 16px;
}
.fullradiusbox .title i, .halfradiusbox .title i {
  margin-right: 10px;
  color: #513b5a;
  font-size: 16px;
}
.container .usercontent .rightcontent .inputbox, .container .usercontent .rightcontent .trendlist {
  margin-top: 15px;
}
.container .usercontent .rightcontent .trendlist .trenditem{
  flex-wrap: nowrap;
}
.trenditem {
  flex-wrap: nowrap;
  margin-bottom: 10px;
}
.trenditem .useravatar{
  width: 50px;
  height: 50px;
  background-color: #ad8cad;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
}
.trenditem .useravatar img {
  width: 44px;
  height: 44px;
  border-radius: 3px;
}
.trenditem .trendcontent{
  padding: 0 5px;
  color: #fff;
}
.trenditem .trendcontent .textsector {
  margin-bottom: 10px;
}
.trenditem .trendcontent .textsector .username{
  color: #7ce6fe;
  padding: 0 5px;
  font-weight: 700;
  cursor: pointer;
}
.trenditem .trendcontent .imagesector{
  padding: 0 10px;
  margin-bottom: 10px;
}
.trenditem .trendcontent .imagesector .img {
  max-width: 200px;
}
.container .usercontent .leftcontent .userheader .vip {
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 30px;
  width: 30px;
  height: 30px;
}
.trenditem .useravatar .vip{
  position: absolute;
  right: 2px;
  bottom: 0;
  font-size: 20px;
  width: 18px;
  height: 18px;
}
.comment-box{
  width: 100%;
  /*background: hsla(0,0%,100%,.2) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpi/P//WRoDGmAB4ifYBF9gE/w3Kkiu4P/Bp/0/DbRTIAgQYAC2PiJjuOjlPgAAAABJRU5ErkJggg==) repeat-x 0 0;*/
  /*border: none;*/
  /*border-bottom: none;*/
  /*max-height: 170px;*/
}
.flexbox.trendtime{
  width: 120px;
}

.flexbox-s.trenditem.comment-box-line {
  /* border-bottom: 1px solid #b08c8c; */
  margin-left: 10px;
  margin-bottom: 8px;
  background-color: rgb(0 0 0 / 10%);
  border-radius: 5px;
  padding: 10px 10px 0px 10px;
}
</style>
