<template>
  <van-tabbar v-model="active" route>
  <van-tabbar-item replace  to="/m/home"  icon="home-o">{{ $t('user.main') }}</van-tabbar-item>
  <van-tabbar-item replace  to="/m/newsfeed" icon="fire-o">{{ $t('user.newsfeed') }}</van-tabbar-item>
  <van-tabbar-item v-if="count > 0" replace  to="/m/chat/list" icon="chat-o" :badge="count">{{ $t('chat.msg') }}</van-tabbar-item>
  <van-tabbar-item v-else replace  to="/m/chat/list" icon="chat-o">{{ $t('chat.msg') }}</van-tabbar-item>
  <van-tabbar-item replace  to="/m/my" icon="user-o">{{ $t('user.mine') }}</van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant'
import 'vant/lib/tabbar/style'
import 'vant/lib/tabbar-item/style'
import {mapGetters} from 'vuex'
export default {
  name: "MFooter",
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  data() {
    return {
      active: 'home',
      count: 0
    }
  },
  computed: {
    ...mapGetters(['getTotalMsgCount'])
  },
  watch: {
    getTotalMsgCount: {
      handler(v){
        this.count = v
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
