<template>
<div>
  <div class="invest_area" >
    <div class="invest_area_top" >
      <div class="invest_area_top_icon" >
        <div @click="$router.back()">{{ $t('user.cancel') }}</div>
      </div>
      <span >{{ $t('user.change_password') }}</span>
      <div class="invest_area_top_save">
        <button @click="changePwd" class="el-button el-button--primary" type="button" style="background: rgb(255, 74, 83); border: 1px solid rgb(255, 74, 83);">
          <span>{{ $t('user.save') }}</span>
        </button>
      </div>
    </div>
  </div>

  <div class="menuList">
    <el-form ref="form" :model="form">
      <el-form-item>
        <el-input v-model="form.oldPwd" type="password" autocomplete="off" placeholder="Old password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.newPwd" type="password" autocomplete="off" placeholder="Password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.repPwd" type="password" autocomplete="off" placeholder="Repeat password"></el-input>
      </el-form-item>
    </el-form>
  </div>
</div>
</template>

<script>
import {changePwd} from '@/api/user'
export default {
  name: "m_changepwd",
  components: {
  },

  data(){
    return {
      form: {
        oldPwd: '',
        newPwd: '',
        repPwd: '',
      }
    }
  },
  created() {
  },
  methods: {
    changePwd(){
      if(!this.form.oldPwd)
        return
      if(!this.form.newPwd)
        return
      if(this.form.newPwd != this.form.repPwd)
        return
      changePwd({
        old_pwd: this.form.oldPwd,
        new_pwd: this.form.newPwd,
        rep_pwd: this.form.repPwd,
      }).then(res => {
        if(res.code == 0){
          this.$message({
            message: 'Operated successful',
            type: 'success'
          })
          this.$router.back()
        }else{
          this.$message.error('Operated failed')
        }
      })

    },
  }
}
</script>

<style scoped>
@import "../assets/css/common.css";
.invest_area{
  width: 100%;
  height: 20px;
  padding: 20px;
  color: #303133;
  position: relative;
}
.invest_area_top{
  text-align: center;
  font-size: 18px;
  height: 20px;
  line-height: 20px;
}
.invest_area_top_icon{
  position: absolute;
  height: 20px;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  color: #465dfe;
}
.invest_area_top_save{
  position: absolute;
  height: 20px;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
}
.menuList {
  padding: 20px 0 100px;
}
.el-form-item{
  margin-bottom: 22px;
  display: flex;
  width: 100%;
  padding: 0 16px;
  background: #f5f7fa;
  height: 60px;
  align-items: center;
  margin-bottom: 30px;
}
::v-deep .el-form-item__content{
  display: flex!important;
  width: 100%;
}
</style>
