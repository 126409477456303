import request from './request'

/**
 * @description 绑定客户端
 */
export function chatBind(data) {
    return request.post(`chat/bind`, data)
}

/**
 * @description 绑定客户端
 */
export function chatContacts(data) {
    return request.get(`chat/contacts`, data)
}

/**
 * @description 绑定客户端
 */
export function chatHistory(data) {
    return request.get(`chat/getHistory`, data)
}

/**
 * @description 强制同步消息
 */
export function pullMsg(data) {
    return request.get(`chat/pullMsg`, data)
}

/**
 * @description 发送信息
 */
export function chatSendMsg(data) {
    return request.post(`chat/sendMsg`, data)
}

/**
 * @description 删除联系人
 */
export function deleteContact(data) {
    return request.post(`chat/contact/del`, data)
}
