<template>
  <div class="flexbox-c home">
    <Head />

    <div class="flexbox-c homecontent">
      <div class="container">
        <div class="title">{{ $t('user.edit_infomation') }}</div>
        <div class="flexbox-s usercontent">
          <div class="flexbox-c bodycontainer">
            <div class="flexbox usereditcontainer">
              <div class="flexbox commonbtn" @click="dialogTableVisible = true">
                <div class="textbox">{{ $t('user.change_password') }}</div>|
                <div class="iconbox">
                  <i class="iconfont icon-youjiantou" style="font-size: 18px;"></i>
                </div>
              </div>
            </div>
            <div class="formbody">
              <div class="boxhead">
                <div class="formitemtitle">{{ $t('user.basic') }}</div>
              </div>
              <div class="boxbody">
                <div class="boxitem">
                  <div class="left">{{ $t('user.user_avatar') }}</div>
                  <div class="center" style="justify-content: flex-start;">
                    <img class="avatar" :src="form.header_img">
                  </div>
                  <div class="right">
                  </div>
                </div>
                <div class="boxitem">
                  <div class="left">{{ $t('user.email') }}</div>
                  <div class="center">
                    <el-input v-model="form.email" autocomplete="off" :placeholder="$t('user.email')"></el-input>
                  </div>
                  <div class="right">{{ $t('user.should_be_valid') }}</div>
                </div>
                <div class="boxitem">
                  <div class="left">{{ $t('user.sex') }}</div>
                  <div class="center" style="justify-content: flex-start;">
                    <el-radio v-model="form.sex" :label="1">{{$t('user.male')}}</el-radio>
                    <el-radio v-model="form.sex" :label="0">{{$t('user.female')}}</el-radio>
                  </div>
                  <div class="right">
                  </div>
                </div>
                <div class="boxitem">
                  <div class="left">{{ $t('user.birthday') }}</div>
                  <div class="center" style="justify-content: flex-start;">
                    <el-date-picker
                        v-model="form.birthday"
                        type="date"
                        :placeholder="$t('user.birthday')">
                    </el-date-picker>
                  </div>
                  <div class="right">
                  </div>
                </div>
              </div>
              <div class="flexbox usereditcontainer" v-if="false">
                <div class="flexbox commonbtn" @click="save">
                  <div class="textbox">{{ $t('user.save') }}</div>|
                  <div class="iconbox">
                    <i class="iconfont icon-youjiantou" style="font-size: 18px;"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <Footer />
    <ChatFixed ref="chatFixed" />
    <my-upload
      :modelValue.sync="imagecropperShow"
      :key="uploadLang"
      :lang-type="uploadLang"
      :headers="uploadHeader"
      img-format="png"
      @crop-upload-success="cropUploadSuccess"
      @crop-upload-fail="cropUploadFail"
      url="https://api.meet-fate.com/upload/img"
      noCircle
      :withCredentials="false"
      field="img"
      :width="300"
      :height="300"
    />

    <el-dialog width="85%" :title="$t('user.change_password')" :visible.sync="dialogTableVisible">
      <div class="changepwdbox">
        <div class="boxitem">
          <el-input placeholder="Old password" autocomplete="off" maxlength="18" v-model="old_pwd" show-password></el-input>
        </div>
        <div class="boxitem">
          <el-input placeholder="Password" autocomplete="off" maxlength="18" v-model="new_pwd" show-password></el-input>
        </div>
        <div class="boxitem">
          <el-input placeholder="Repeat password" autocomplete="off" maxlength="18" v-model="rep_pwd" show-password></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">{{$t('user.cancel')}}</el-button>
        <el-button type="primary" @click="changePwd">{{ $t('user.save') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Head from '@/components/Head.vue'
import ChatFixed from '@/components/ChatFixed.vue'
import myUpload from "vue-image-crop-upload";
import {userEdit,changePwd} from '@/api/user'
export default {
  components: {Footer, Head, ChatFixed, myUpload},
  data() {
    return {
      old_pwd: '',
      new_pwd: '',
      rep_pwd: '',
      dialogTableVisible: false,
      form: {
        header_img: '',
        birthday: '',
        email: '',
        sex: 0,
      },
      imagecropperShow: false,
      uploadHeader: {
        'token': sessionStorage.getItem('token'),
        // 'Content-Type': 'application/x-www-form-urlencoded;',
        'Accept': '*/*'
      },
      userInfo: null,
      uploadLang: 'zh-tw'
    }
  },
  created() {
    this.userInfo = this.$store.state.user
    this.form.header_img = this.userInfo.header_img
    this.form.birthday = this.userInfo.born
    this.form.sex = this.userInfo.sex
    this.form.email = this.userInfo.email
  },
  methods: {
    changePwd(){
      if(!this.old_pwd)
        return
      if(!this.new_pwd)
        return
      if(this.new_pwd != this.rep_pwd)
        return
      changePwd({
        old_pwd: this.old_pwd,
        new_pwd: this.new_pwd,
        rep_pwd: this.rep_pwd,
      }).then(res => {
        if(res.code == 0){
          this.$message({
            message: 'Operated successful',
            type: 'success'
          })
          this.dialogTableVisible = false
        }else{
          this.$message.error('Operated failed')
        }
      })

    },
    save(){
      let data = {
        header_img: this.form.header_img,
        born: this.form.birthday,
        sex: this.form.sex,
        email: this.form.email,
      }

      userEdit(data).then(res => {
        if(res.code == 0){
          this.userInfo.header_img = this.form.header_img
          this.userInfo.born = this.form.birthday
          this.userInfo.sex = this.form.sex
          this.userInfo.email = this.form.email
          this.$store.commit('setUserInfo', this.userInfo)
          sessionStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.$message({
            message: 'Operated successful',
            type: 'success'
          })
        }else{
          this.$message.error('Operated failed')
        }
      })
    },
    cropUploadSuccess( jsonData){
      this.$set(this.form, 'header_img', jsonData.list[0])
      this.imagecropperShow = false
    },
    cropUploadFail(status, field){
      console.log(status, field)
    },
  }
}
</script>
<style scoped>
@import "../../assets/css/common.css";
@import "../../assets/css/index.css";
.container{
  width: 1000px;
  padding: 100px 30px;
}
.container .title {
  font-size: 20px;
  color: #513b5a;
  font-weight: 700;
  text-align: left;
}
.container .usercontent .bodycontainer {
  width: 1000px;
}
.usereditcontainer{
  width: 800px;
  margin-bottom: 30px;
  justify-content: flex-end;
}
.commonbtn {
  width: auto;
  height: 32px;
  justify-content: space-between;
  color: #513b5a;
  background-color: #e8cbed;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}
.commonbtn .textbox{
  padding: 0 5px;
}
.commonbtn .iconbox{
  padding: 0 5px;
  font-size: 20px;
}
.container .usercontent .bodycontainer .formbody {
  width: 800px;
}
.container .usercontent .bodycontainer .boxhead{
  display: flex;
  background: hsla(0,0%,100%,.2) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpi/P//WRoDGmAB4ifYBF9gE/w3Kkiu4P/Bp/0/DbRTIAgQYAC2PiJjuOjlPgAAAABJRU5ErkJggg==) repeat-x 0 0;
  border: none;
  border-radius: 6px 6px 0 0;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding-left: 10px;
  border: 1px solid hsla(0,0%,100%,.5);
}
.container .usercontent .bodycontainer .formitemtitle{
  color: #fff;
  width: 100%;
  padding: 10px;
  text-align: center;
}
.container .usercontent .bodycontainer .boxbody{
  background: hsla(0,0%,100%,.05) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpi/P//WRoDGmAB4ifYBF9gE/w3Kkiu4P/Bp/0/DbRTIAgQYAC2PiJjuOjlPgAAAABJRU5ErkJggg==) repeat-x 0 0;
  margin-bottom: 10px;
}
.container .usercontent .bodycontainer .boxbody .boxitem{
  display: flex;
  width: 100%;
  border-bottom: 1px solid hsla(0,0%,100%,.5);
  border-left: 1px solid hsla(0,0%,100%,.5);
  border-right: 1px solid hsla(0,0%,100%,.5);
}
.container .usercontent .bodycontainer .boxbody .boxitem .left{
  width: 20%;
  display: flex;
  padding: 10px;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  color: #fff;
  font-size: 14px;
}
.container .usercontent .bodycontainer .boxbody .boxitem .center {
  width: 60%;
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #fff;
  font-size: 14px;
  border-left: 1px solid hsla(0,0%,100%,.5);
  border-right: 1px solid hsla(0,0%,100%,.5);
}
.container .usercontent .bodycontainer .boxbody .boxitem .center .avatar{
  width: 100px;
  height: 100px;
  cursor: pointer;
}
.container .usercontent .bodycontainer .boxbody .boxitem .right{
  width: 20%;
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  color: #fff;
  font-size: 14px;
}
.usereditcontainer{
  width: 800px;
  margin-bottom: 30px;
  justify-content: flex-end;
}
.commonbtn{
  width: auto;
  height: 32px;
  justify-content: space-between;
  color: #513b5a;
  background-color: #e8cbed;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}
.commonbtn .textbox{
  padding: 0 5px;
}
.commonbtn .iconbox{
  padding: 0 5px;
  font-size: 20px;
}
.changepwdbox .boxitem{
  margin-top: 30px;
}
.el-button--default {
  border-color: #9f759b;
}
.el-button--primary, .el-popper.is-light .cardcontainer+.el-popper__arrow:before {
  background-color: #9f759b;
  border-color: #9f759b;
}
::v-deep .el-dialog__header{
  text-align: left;
}
</style>
