import { render, staticRenderFns } from "./m_pay.vue?vue&type=template&id=0c358c04&scoped=true&"
import script from "./m_pay.vue?vue&type=script&lang=js&"
export * from "./m_pay.vue?vue&type=script&lang=js&"
import style0 from "./m_pay.vue?vue&type=style&index=0&id=0c358c04&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c358c04",
  null
  
)

export default component.exports