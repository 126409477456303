<template>
  <div class="flexbox container">
    <div class="flexbox head">
      <Menu />
    </div>
    <div class="flexbox body">
      <h2 class="title" >meet-fate</h2>
      <div class="flexbox form" >
      <div class="loginbody" >
        <div class="formtitle" style="text-align: left;">
          <i class="iconfont icon-wenjian">
          </i>{{ $t('user.plogin') }}</div>
        <div class="inputitem" >
          <el-input v-model="username" prefix-icon="el-icon-user" :placeholder="$t('user.username')"></el-input>
        </div>
        <div class="inputitem">
          <el-input v-model="password" show-password prefix-icon="el-icon-lock" :placeholder="$t('user.password')"></el-input>
        </div>
        <div class="flexbox inputitem" >
          <el-checkbox v-model="checked">{{ $t('user.remember_me') }}</el-checkbox>
        </div>
        <div class="flexbox inputitem">
          <div class="flexbox loginbtn" @click="loginSubmit">
            <div >{{ $t('user.login') }}</div>|
            <i class="iconfont icon-youjiantou" ></i>
          </div>
          <router-link :to="{name: 'Info', params: {query: 'register'}}" class="forgot" >{{$t('user.register')}}</router-link>
        </div>
      </div>
    </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Menu from '@/components/Menu.vue'
import {login} from '../api/user'
export default {
  components: { Footer,Menu },
  data() {
    return {
      username: '',
      password: '',
      checked: true
    }
  },
  methods: {
    loginSubmit(){
      if(this.username.length <= 0){
        this.$message({
          message: this.$t('user.please_enter') + ' ' + this.$t('user.username'),
          type: 'warning'
        })
        return
      }
      if(this.password.length <= 0){
        this.$message({
          message: this.$t('user.please_enter') + ' ' + this.$t('user.password'),
          type: 'warning'
        })
        return
      }
      if(!this.checked){
        return
      }
      const that = this
      login({
        username: this.username,
        password: this.password,
        type: 0
      }).then(res => {
        if(res.code != 0){
          this.$message.error('Login failed');
        }else{
          this.$store.commit('setUserInfo', res.user)
          this.$store.commit('loginState', true)
          this.$message({
            message: 'Login successful',
            type: 'success'
          });
          sessionStorage.setItem('token', res.user.token)
          sessionStorage.setItem('user_id', res.user.id)
          sessionStorage.setItem('loginState', true)
          that.$router.push({name: 'Home'})
        }
      })
    },
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }

  },
  created() {},
  mounted() {
    if (this.isMobile()) {
      this.$router.replace({
        name: 'MLogin',
        query: this.$route.query

      });
    }
  }
};
</script>
<style scoped>
@import "../assets/css/common.css";
.container {
  height: 100vh;
  background-image: url(../assets/img/bg1.jpg);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  flex-direction: column;
  flex-wrap: nowrap;
}
.head{
  position: relative;
  height: 80px;
}

.head .menu {
  width: auto;
  height: auto;
  position: fixed;
  right: 0;
  top: 0;
}

.body{
  width: 400px;
  height: 100%;
}

.title {
  width: 100%;
  font-size: 28px;
  line-height: 36px;
  margin: 0 8px 10px 8px;
  padding: 0 40px;
  color: #513b5a;
  font-family: open_sanssemibold,Tahoma,Geneva,sans-serif;
  text-align: left;
}
.form {
  width: 400px;
  margin: 8px;
  padding: 40px;
  background: rgba(137,122,166,.4);
  border-radius: 5px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
  border: none;
}

.loginbody {
  width: 320px;
  background-color: hsla(0,0%,100%,.2);
  border-radius: 6px;
}

.formtitle {
  padding: 10px;
  height: auto;
  font-size: 16px;
  overflow: hidden;
  font-weight: 400;
  color: #513b5a;
}

.inputitem {
  width: 100%;
  padding: 8px 20px;
  justify-content: space-between;
}
.forgot {
  font-size: 14px;
  color: #7ce6fe;
  cursor: pointer;
}

.loginbtn {
  width: 100px;
  height: 32px;
  background-color: #e8cbed;
  padding: 0 8px;
  border: 1px solid rgba(93,69,110,.6);
  border-radius: 4px;
  color: #513b5a;
  font-size: 14px;
  justify-content: space-between;
  cursor: pointer;
}
</style>
