<template>
  <div>
    <div v-if="userInfo">
      <div class="headBox">
        <van-swipe @change="onChange">
          <van-swipe-item><img class="bg" src="/img/dbj.jpg" alt="" srcset=""></van-swipe-item>
          <template #indicator>
            <div class="custom-indicator">{{ swipeCurrent + 1 }}/4</div>
          </template>
        </van-swipe>
        <div class="headBox_top">
          <img @click="$router.back()" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAABj0lEQVRYR+2YrU4EMRSFvxMSNJKXwYIBh8LheAHAEBLcwgPgSAgCBVnBA2DAYDC8BIKENRBAXNJklnTLdCeI2d6SHTc/ab85c9o594rKDlXGyxy47y82V9ilwma2CFwAm8BA0kHfoOPx/2yJBvYK2GgGGUlacgncAhs4zyVtuwM2swVgGCkbGG+CLSR9ugJuYINntyKwmcOGuTs97Am2EzgDewusS3qblQ3iebIKm1m4dwbEC6oobFbhBvYU2InerjhsK3AG9g5YK2WDrCUysA/AqqRRCc+mc0542MxOgN3oIVewE5YwswGw7xn2B7gF9glYkfTqwQYTHjazI+AwuvgCXAPvhWG/gEtJjynwBxDiosfjWdJy9cB1WSLIXdWiG/ujqm0tgj4G9jzvxemfLpynocfV3+5XvMzkifsmTxTJwJ15uKp4Gfm5ngAfQYdKOS0+iwb5/1WEdijts8xPoOtopETQIdXFfbVwy2eragq032ZgAl1Hu7V0yu/c1koDTi3zvcG18cwV7vsrVafwN+j4vi3HfpsqAAAAAElFTkSuQmCC" alt="">
          <img @click="sheet_show = true" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAABTUlEQVRoQ+2WMUoFUQxFz92Jgu5Ae7H270EbLVyEuggLbXQP31rsdQcKuhAhMjJYfPgkPObFYci0mdy83JPJG7GQRwvpg2pkbiSLSBHp5ECNVidjm2WLSLN1nRKLSCdjm2WLSLN1nRKLSCdjm2WLSLN1nRKXR8TMToAVcDya9gysJT21mGhmB8AlcAR8A131fomY2TVwteXAN5KGePgxs0FrW04XPY3OvTqnPJT0FulkJLtO1zOzB+DUKfwo6SzYyB1wnq5nZp/AjlP4S9JusJF3YC9dz8wihT8k7c+9kcgo3Eu6CDYS0Zt+VIMf5yq6hoN60y+PTut36nXu6v3d7BsX2NDfC3AbXbubY/cvF2Jk9uf+zvL+tebuuHe+IuI5lB0vItmOe/WKiOdQdryIZDvu1SsinkPZ8SKS7bhXr4h4DmXHi0i24169xRD5AbO7nYWx6jMKAAAAAElFTkSuQmCC" />
        </div>
      </div>
      <div class="contBox">
        <div class="userBox">
          <div class="left">
            <span class="name">{{ userInfo.username }}</span>
            <span class="age">
              <i v-show="userInfo.sex == 1" class="el-icon-male" style="color: rgb(153, 153, 255); font-size: 18px;"></i>
              <i v-show="userInfo.sex == 0" class="el-icon-female" style="color: rgb(255, 68, 170); font-size: 18px;"></i>
              {{userInfo.age}}
            </span>
          </div>
          <div class="like">
            <i class="van-badge__wrapper van-icon van-icon-like-o" style="color: rgb(102, 102, 102); font-size: 1.52rem;"></i>
            <div @click="likeOp">{{userInfo.like ? userInfo.like : 0}} {{ $t('user.like') }}</div>
          </div>
        </div>
      </div>
      <div class="tab">
        <div class="tabItem" :class="content_show == 0 ? 'on' : ''"  @touchstart="tabChange(0)">動態</div>
        <div class="tabItem" :class="content_show == 1 ? 'on' : ''"  @touchstart="tabChange(1)">照片</div>
        <div class="tabItem" :class="content_show == 3 ? 'on' : ''"  @touchstart="tabChange(3)">視頻</div>
        <div class="tabItem" :class="content_show == 2 ? 'on' : ''"  @touchstart="tabChange(2)">個人資料</div>
      </div>
      <div v-show="content_show == 0">
        <div
            class="flexbox-s trenditem"
            v-for="item in trendTextList"
            :key="item.id"
        >
          <div class="flexbox userinfo">
            <span class="flexbox useravatar">
              <img class="avatar" :src="item.header_img">
            </span>
            <div class="flexbox-s namebox">
              <span class="username">
                <i class="iconfont icon-V" style="color: silver;"></i>{{ item.username }}</span>
              <div class="flexbox sexandage">
                <i class="iconfont icon-nan" style="color: rgb(153, 153, 255);"></i>
                <div class="age">{{item.age}}</div>
              </div>
            </div>
            <div class="chatbox">
            </div>
          </div>
          <div class="flexbox trendcontent">
            <div class="flexbox-s textsector">
              <div>
                <div class="expresscontent" v-html="item.content">{{ item.content }}</div>
              </div>
            </div>
          </div>
          <div class="flexbox trendtime">{{item.timeFormat}}</div>
        </div>
      </div>
      <div class="photoList" v-show="content_show == 1">
        <div
            class="item"
            v-for="item in trendImgList"
            :key="item.id"
        >
          <div class="img">
            <el-image
                :src="item.path"
                :preview-src-list="imgList">
            </el-image>
          </div>
        </div>
      </div>
      <div class="information" v-show="content_show == 2">
        <div class="title" >基本資料</div>
        <div class="sex" >{{ $t('user.sex') }}：{{userInfo.sex == 1 ? $t('user.male') : $t('user.female')}}</div>
      </div>
      <div class="video" v-show="content_show == 3">
        <div
            class="flexbox-s trenditem"
            v-for="item in trendVideoList"
            :key="item.id"
        >
          <video :src="item.path" :controls="loginUser.viptype == 1 ? 'controls': ''" style="width: 100%;border-radius: 4px;"></video>
          <div class="flexbox trendtime">{{item.timeFormat}}</div>
        </div>
      </div>
      <input type="file" ref="videoInput"  @change="inputFileChange" accept="video/*"  style="display: none;">
      <input type="file" ref="imgInput"  @change="inputFileChange" accept="image/*"  style="display: none;">
      <input type="file" ref="upImage" accept="image/*" style="display: none;">
      <div class="placeholder"></div>
      <div class="btn">
          <van-button type="danger" round size="large" @touchstart="btnOp" v-show="content_show == 0">{{ btnName }}</van-button>
      </div>
    </div>
    <van-action-sheet
        v-model="sheet_show"
        :actions="shellActions"
        cancel-text="取消"
        close-on-click-action
        @select="onSelect"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/zh-cn";
import "dayjs/locale/zh-tw";
import "dayjs/locale/en";
dayjs.extend(relativeTime);
import {like, userInfo} from '@/api/user'
import {trend} from '@/api/index'
import {mapGetters} from "vuex"
import { Swipe, SwipeItem,Button,ActionSheet   } from 'vant';
import 'vant/lib/swipe/style'
import 'vant/lib/swipe-item/style'
import 'vant/lib/button/style'
import 'vant/lib/action-sheet/style'
import {uploadImg} from '@/api/user'
export default {
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Button.name]: Button,
    [ActionSheet.name]: ActionSheet,
  },
  data() {
    return {
      shellActions: [{name: '上傳背景圖片'}, {name: '删除背景圖片'}, {name: '資料設定'}],
      sheet_show: false,
      userId: 0,
      userInfo: null,
      trendList: [],
      trendTextList: [],
      trendImgList: [],
      trendVideoList: [],
      content_show: 0,
      swipeCurrent: 0,
      btnName: '最新动态',
      isImgOrVideo: false,
      dayjsObj: dayjs,
      imgList: [],
    }
  },
  computed:{
    ...mapGetters([
      'getLanguage',
      'getUserinfo',
    ]),
    loginUser(){
      return this.getUserinfo
    }
  },
  watch: {
    getLanguage: {
      handler(newV){
        this.changeLang(newV)
      },
      immediate: true
    },
    trendList: {
      handler(v){
        this.trendTextList = []
        this.trendImgList = []
        this.trendVideoList = []
        this.imgList = []
        for (const vKey in v) {
          if(v[vKey].type == 0){
            if(v[vKey].content){
              v[vKey].content = v[vKey].content.replace(/\[(face\d+)\]/g, '<img src="img/$1.gif" style="width: 24px;height: 24px;vertical-align: middle;">')
            }
            this.trendTextList.push(v[vKey])
          }else if(v[vKey].type == 1){
            this.imgList.push(v[vKey].path)
            this.trendImgList.push(v[vKey])
          }else if(v[vKey].type == 2){
            this.trendVideoList.push(v[vKey])
          }
        }
      }
    }
  },
  created() {
    this.userId = this.$route.query.id
    if(this.$route.query.type){
      this.content_show = this.$route.query.type
    }

    this.getUserInfo(this.userId)
    this.getTrend(this.userId, 1, 30)
  },
  methods: {
    onSelect(item) {
      console.log(item)
      this.sheet_show = false;
    },
    inputFileChange(e){
      let file = e.target.files[0]
      if(file.type.split('/')[0] == 'video'){
        this.isImgOrVideo = true
      }else{
        this.isImgOrVideo = false
      }
      let formData = new FormData()
      formData.set('img', file)
      uploadImg(formData).then(res => {
        if(res.code == 0){
          this.uploadImgRes = res.list[0]
        }else{
          this.$message.error('Operated failed')
        }
      })
    },
    btnOp(){
      if(this.content_show == 0){
        this.$router.push({name: 'MTrend'})
      }else if(this.content_show == 1){
        this.$refs.upImage.click()
      }
    },
    onChange(e){
      console.log(e)
    },
    tabChange(index){
      this.content_show = index
      if(index == 0){
        this.btnName = this.$t('user.newsfeed')
      }else if(index == 1){
        this.btnName = this.$t('user.upload_photo')
      }else{
        this.btnName = ''
      }
    },
    likeOp(){
      like({like_id: this.userInfo.id, type: this.userInfo.like}).then(res => {
        if(res.code == 0){
          this.$set(this.userInfo, 'like', this.userInfo.like == 1 ? 0 : 1 )
          this.$message({
            message: 'Operated successful',
            type: 'success'
          });
        }
      })
    },
    openChat(){
      let list = this.$store.state.contacts
      list.unshift(this.userInfo)
      this.$store.commit('setContacts', list)
      this.$router.push({name: 'PcChat', query: {id:  this.userInfo.id}})
    },
    getUserInfo(id){
      userInfo({user_id: id}).then(res => {
        if(res.code == 0){
          this.userInfo = res.data
        }
      })
    },
    getTrend(id, page, limit){
      const that = this
      trend({page: page, limit: limit,user_id: id}).then(res => {
        if(res.code == 0){
          for (const i in res.list) {
            let item = res.list[i]
            item.timeFormat = that.dayjsObj.unix(res.list[i].create_time).fromNow()
            that.trendList.push(item)
          }
        }
      })
    },
    changeTrendLang(){
      let list = this.trendList
      this.trendList = []
      for (const i in list) {
        let item = list[i]
        item.timeFormat = this.dayjsObj.unix(list[i].create_time).fromNow()
        this.trendList.push(item)
      }
    },
    changeLang(lang) {
      if(lang == 'cn'){
        this.dayjsObj.locale('zh-cn')
      }else if(lang == 'tn'){
        this.dayjsObj.locale('zh-tw')
      }else{
        this.dayjsObj.locale('en')
      }

      this.changeTrendLang()
    }
  }
}
</script>
<style scoped>
@import "../../assets/css/common.css";
@import "../../assets/css/mconment.css";
.headBox{
  width: 100%;
  position: relative;
}
.headBox .bg{
  width: 100%;
  height: 100%;
}
.headBox_top{
  position: absolute;
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  padding: 18px;
  top: 0;
  z-index: 999;
}
.contBox{
  background: #fff;
  border-radius: 15px 15px 0 0;
  margin-top: -35px;
  padding: 30px 20px 0;
  position: relative;
  z-index: 2;
}
.contBox .userBox{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding-bottom: 25px;
}
.contBox .userBox .name{
  font-size: 20px;
}
.contBox .userBox .age{
  font-size: 14px;
  color: #465dfe;
  margin-left: 15px;
}
.contBox .userBox .like{
  text-align: center;
  color: #8f8f94;
  font-size: 14px;
}
contBox .userBox .like div{
  margin-top: 3px;
}
.tab{
  display: flex;
  padding: 20px;
  align-items: center;
}
.tab .tabItem.on{
  font-size: 20px;
  color: #ff4a53;
}
.tab .tabItem{
  margin-right: 20px;
  font-size: 14px;
  position: relative;
  line-height: 35px;
  cursor: pointer;
  color: #333;
}
.trenditem{
  padding: 10px;
  border-bottom: 1px solid #eee;
}
.trenditem .userinfo{
  height: 70px;
  flex-wrap: nowrap;
}
.trenditem .userinfo .useravatar{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}
.trenditem .userinfo .useravatar .avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.trenditem .userinfo .namebox{
  padding-left: 10px;
}
.trenditem .userinfo .namebox .username{
  width: 100%;
  color: #333;
  padding: 0 5px;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 5px;
  text-align: left;
}
.trenditem .userinfo .namebox .sexandage {
  width: auto;
  font-size: 14px;
  background-color: #f1f2f3;
  padding: 2px 8px;
  height: 20px;
  border-radius: 10px;
}
.trenditem .userinfo .namebox .sexandage .age{
  padding-left: 5px;
}
.trenditem .trendcontent{
  padding: 0 5px;
  color: #333;
}
.trenditem .trendcontent .textsector{
  margin-bottom: 10px;
}
.trenditem .trendcontent .imagesector {
  padding: 0 10px;
  margin-bottom: 10px;
}
.trenditem .trendcontent .imagesector .img {
  max-width: 200px;
  height: auto;
}
.trenditem .trendtime{
  color: #aaa;
  width: auto;
  height: 30px;
  align-items: flex-end;
  align-content: flex-end;
  flex-shrink: 0;
  font-size: 12px;
}
.photoList{
  display: flex;
  padding: 14px;
  flex-wrap: wrap;
}
.photoList .item{
  width: 25%;
  text-align: center;
  padding: 0 1.5%;
  padding-bottom: 12px;
}
.photoList .item .img{
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
  bottom: 15px;
  background: rgba(0,0,0,.3);
  color: #fff;
  font-size: 14px;
  width: auto;
  padding: 8px 15px;
  border-radius: 30px;
  right: 20px;
  left: auto;
  display: inline-block;
}
.btn{
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 100;
  background: #fff;
  height: 60px;
  padding: 0 16px;
}
.btn button{
  margin-top: 5px;
}
.placeholder{
  height: 100px;
}
.photoList{
  display: flex;
  padding: 14px;
  flex-wrap: wrap;
}
.photoList .item{
  width: 25%;
  text-align: center;
  padding: 0 1.5%;
  padding-bottom: 12px;
}
.photoList .item .img{
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.photoList .item .el-image{
  width: 100%;
   height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.information{
  padding: 15px 20px;
  text-align: left;
}
.information .title{
  color: #303133;
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 20px;
}
.information .sex{
  margin-bottom: 10px;
  color: #909399;
  font-size: 14px;
}
.headBox_top img{
  height: 22px;
  width: 22px;
}
</style>
