import request from './request'

/**
 * @description 金币支付
 */
export function rechargeGold(data) {
    return request.post(`pay/rechargeGold`, data)
}

/**
 * @description 充值选项
 */
export function golds(data) {
    return request.get(`pay/gold`, data)
}

/**
 * @description 获取充值参数
 */
export function payInfo(data) {
    return request.get(`pay/info`, data)
}


/**
 * @description 支付
 */
export function pay(data) {
    return request.post(`pay/payment`, data)
}

/**
 * @description 支付
 */
export function payCheck(data) {
    return request.post(`pay/check`, data)
}
