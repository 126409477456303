import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui';
// import localeEn from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css';
// import VueI18n from 'vue-i18n'
import i18n from './language'
import socket from 'vue-native-websocket'

Vue.use(socket,  'wss://api.meet-fate.com/wss',{
  connectManually: true, // 手动连接
  format: 'json', // json格式
  reconnection: true, // 是否自动重连
  reconnectionAttempts: 5, // 自动重连次数
  reconnectionDelay: 2000, // 重连间隔时间
})


Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.use(ElementUI);

// axios.defaults.baseURL='https://saima.digilinx.net.cn'


new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')

