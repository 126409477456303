<template>
  <div>
    <div class="content_area">
      <div class="language" @click="isLang = true">{{currentLangText}}</div>
      <div class="pleaselogin">{{$t('user.plogin')}}</div>
      <input type="text" v-model="username" class="van-field1" :placeholder="$t('user.username')+'/' + $t('user.email')">
      <input type="password" v-model="password" :placeholder="$t('user.password')">
      <div class="forgot_item" v-if="false">
        <a href="#/m/Forgotpassword" class="forgot">Forgot Password</a>
      </div>
      <div style="margin: 16px;margin-top: 100px;">
        <van-button @click="loginBtn" type="danger" color="#FF4A53" round block>{{ $t('user.login') }}</van-button>
      </div>
      <div class="joinin">
        <router-link to="register" class="register">{{$t('user.register2')}}</router-link>
      </div>
      <div class="down_area">
        <router-link to="info" class="terms">
          <span>{{ $t('user.terms_of_use') }}</span>
        </router-link>
      </div>
    </div>
    <van-action-sheet
        v-model="isLang"
        :actions="lactions"
        @select="lSelect" />
  </div>
</template>

<script>
import {login} from '../api/user'
import {ActionSheet,Button ,Locale} from 'vant';
import 'vant/lib/action-sheet/style'
import 'vant/lib/button/style'
import en from 'vant/es/locale/lang/en-US';
import cn from 'vant/es/locale/lang/zh-CN';
import tn from 'vant/es/locale/lang/zh-TW';
import {mapGetters} from "vuex";
export default {
  components: {
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
  },
  data() {
    return {
      username: '',
      password: '',
      isLang: false,
      currentLangText: '',
      lactions: [{ name: 'English'}, {name: '繁體中文'}, {name: '简体中文' }],
    }
  },
  computed: {
    ...mapGetters([
      'getUserinfo',
      'getLanguage',
    ]),
  },
  watch: {
    getLanguage: {
      handler(newV){
        if(newV == 'cn'){
          this.currentLangText = '简体中文'
          Locale.use('zh-CN',cn)
        }else if(newV == 'en'){
          this.currentLangText = 'English'
          Locale.use('en-US',en)
        }else{
          this.currentLangText = '繁體中文'
          Locale.use('zh-TW', tn)
        }
      },
      immediate: true
    }
  },
  methods: {
    lSelect(item){
      this.currentLangText = item.name
      let lang = ''
      if(item.name == '简体中文'){
        lang = 'cn'
      }else if(item.name == 'English'){
        lang = 'en'
      }else{
        lang = 'tn'
      }
      localStorage.setItem('lang', lang)
      this.$store.commit('changeLanguage', lang)
      this.$i18n.locale = lang
      this.isLang = false
    },
    loginBtn(){
      if(this.username.length <= 0){
        this.$message({
          message: this.$t('user.please_enter') + ' ' + this.$t('user.username'),
          type: 'warning'
        })
        return
      }
      if(this.password.length <= 0){
        this.$message({
          message: this.$t('user.please_enter') + ' ' + this.$t('user.password'),
          type: 'warning'
        })
        return
      }
      const that = this
      login({
        username: this.username,
        password: this.password,
        type: 0
      }).then(res => {
        if(res.code != 0){
          this.$message.error('Login failed');
        }else{
          this.$store.commit('setUserInfo', res.user)
          this.$store.commit('loginState', true)
          this.$message({
            message: 'Login successful',
            type: 'success'
          });
          sessionStorage.setItem('token', res.user.token)
          sessionStorage.setItem('user_id', res.user.id)
          sessionStorage.setItem('loginState', true)
          that.$router.push({name: 'Mhome'})
        }
      })
    },
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }

  },
  created() {},
  mounted() {
    if (!this.isMobile()) {
      this.$router.replace({
        name: 'Login',
        query: this.$route.query
      });
    }
  }
};
</script>
<style scoped>
@import "../assets/css/common.css";
.content_area {
  width: 100%;
  height: 100vh;
  background: url(/img/m_sign_back.png) no-repeat;
  background-size: 100% 100%;
  padding: 20px;
  color: #fff;
  text-align: left;
}
.language {
  width: 100%;
  text-align: right;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}
.pleaselogin {
  width: 100%;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  padding-left: 10px;
  margin-top: 10px;
}
.van-field1 {
  margin-top: 80px;
  margin-bottom: 30px;
}
input {
  background: border-box!important;
  border: none;
  color: #fff;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 2px solid #fff;
  outline: none;
}
.forgot_item {
  margin: 20px 0;
  margin-bottom: 50px;
  width: 100%;
  text-align: right;
}
.forgot {
  color: #fff;
  font-size: 16px;
}
.joinin {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  color: #ff4a53!important;
  font-size: 20px;
}
.register {
  color: #ff4a53!important;
  font-weight: 700;
}
.down_area {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
input::-webkit-input-placeholder{
  color: #fff;
}
input::-moz-placeholder{   /* Mozilla Firefox 19+ */
  color: #fff;
}
input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
  color: #fff;
}
input:-ms-input-placeholder{  /* Internet Explorer 10-11 */
  color: #fff;
}
.down_area span{
  color: #fff;
}
</style>
