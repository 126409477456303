<template>
<div>
  <el-dialog :title="$t('store.gift_box')" :visible.sync="isShow" :modal-append-to-body="false">
    <div class="giftcontainer">
      <van-tabs v-model="giftActive" ref="tabs">
        <van-tab ref="firstTab" :title="$t('store.love_space')">
          <div class="flexbox giftitembox">
            <div
                class="flexbox giftitem"
                v-for="item in giftList[0]"
                :key="item.id"
            >
              <div class="img" :style="'background-image: url(' + item.img + ');'">
              </div>
              <div class="flexbox giftname">{{lang == 'en' ? item.name_en : item.name}}</div>
              <div class="flexbox giftprice">{{item.price}} {{ $t('pay.coin') }}</div>
              <a @click="sendGift(item)" class="flexbox sendbtn">{{ $t('user.send_gift') }}</a>
            </div>
          </div>
        </van-tab>
        <van-tab :title="$t('store.lovers_space')">
          <div class="flexbox giftitembox">
            <div
                class="flexbox giftitem"
                v-for="item in giftList[1]"
                :key="item.id"
            >
              <div class="img" :style="'background-image: url(' + item.img + ');'">
              </div>
              <div class="flexbox giftname">{{lang == 'en' ? item.name_en : item.name}}</div>
              <div class="flexbox giftprice">{{item.price}} {{ $t('pay.coin') }}</div>
              <a @click="sendGift(item)" class="flexbox sendbtn">{{ $t('user.send_gift') }}</a>
            </div>
          </div>
        </van-tab>
        <van-tab :title="$t('store.goddess_space')">
          <div class="flexbox giftitembox">
            <div
                class="flexbox giftitem"
                v-for="item in giftList[2]"
                :key="item.id"
            >
              <div class="img" :style="'background-image: url(' + item.img + ');'">
              </div>
              <div class="flexbox giftname">{{lang == 'en' ? item.name_en : item.name}}</div>
              <div class="flexbox giftprice">{{item.price}} {{ $t('pay.coin') }}</div>
              <a @click="sendGift(item)" class="flexbox sendbtn">{{ $t('user.send_gift') }}</a>
            </div>
          </div>
        </van-tab>
        <van-tab :title="$t('store.lovers')">
          <div class="flexbox giftitembox">
            <div
                class="flexbox giftitem"
                v-for="item in giftList[3]"
                :key="item.id"
            >
              <div class="img" :style="'background-image: url(' + item.img + ');'">
              </div>
              <div class="flexbox giftname">{{lang == 'en' ? item.name_en : item.name}}</div>
              <div class="flexbox giftprice">{{item.price}} {{ $t('pay.coin') }}</div>
              <a @click="sendGift(item)" class="flexbox sendbtn">{{ $t('user.send_gift') }}</a>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </el-dialog>

  <el-dialog width="50%" :title="$t('user.send_gift')" :visible.sync="dialogGiftSendVisible" :modal-append-to-body="false">
    <div class="details" v-if="currentGift">
      <div class="dleft">
        <img :src="currentGift.img" alt="">
      </div>
      <div class="dright" v-if="currentGift">
        <div class="gtitle">{{ $t('store.gift_name') }}：{{lang == 'en' ? currentGift.name_en : currentGift.name}}</div>
        <p class="ginfo">{{ $t('store.gift_description') }}：{{lang == 'en' ? currentGift.desc_en : currentGift.desc}}</p>
        <p class="gprice">{{ $t('store.price') }}：{{ currentGift.price }} {{$t('pay.coin')}}</p>
        <p class="ginfo" v-if="currentChatUser" style="margin-top: 12px;">{{ $t('store.send_gift_to') }}：{{currentChatUser.username}}</p>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogGiftSendVisible = false">{{$t('user.cancel')}}</el-button>
      <el-button type="primary" @click="sendChatByGift">{{ $t('user.send') }}</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
import {Tab, Tabs} from "vant";
import 'vant/lib/tab/style'
import 'vant/lib/tabs/style'
import {mapGetters} from "vuex";
import {giftList, sendGift} from "@/api";
// import {chatSendMsg} from "@/api/chat";
export default {
  name: "GiftDialog",
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
  },
  data() {
    return {
      dialogGiftSendVisible: false,
      giftActive: 0,
      giftList: [[],[],[],[]],
      currentGift: null,
      isShow: false
    }
  },
  props: {
    currentChatUser: {
      type: Object,
      default: null
    },
    addMsg: {
      type: Function,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'getLanguage',
    ]),
    lang(){
      return this.getLanguage
    },
  },
  watch: {
    value(is) {
      this.isShow = is;
    },
    isShow(is){
      this.$emit('input', is)
    }
  },
  created() {
    this.tabClick(0)
    this.tabClick(1)
    this.tabClick(2)
    this.tabClick(3)
  },
  methods: {
    sendChatByGift(){
      if(!this.currentChatUser){
        return
      }
      sendGift({
        username: this.currentChatUser.username,
        gift_id: this.currentGift.id
      }).then(res => {
        if(res.code == 0){
          this.$message({
            message: 'Operated successful',
            type: 'success'
          })
          // // 发送礼物消息
          // chatSendMsg({
          //   author_id: this.currentChatUser.id,
          //   content: this.currentGift.id,
          //   msg_type: 3
          // }).then(res => {
          //   if(res.code == 100){
          //     this.$message.error(this.$t('chat.upgrade_msg'))
          //   }else if(res.code == 0){
          //     this.addMsg(res.data, this.currentChatUser.id)
          //   }else{
          //     this.$message.error(this.$t('chat.send_failed'))
          //   }
          // })
          this.currentGift = null
          this.dialogGiftSendVisible = false
        }else{
          let err = res.code == 500 ? this.$t('store.your_balance_is_not_enough') : 'Operated failed'
          this.$message.error(err)
        }
      })
    },
    sendGift(gift){
      this.currentGift = gift
      this.dialogGiftSendVisible = true
    },
    tabClick(index) {
      let list = this.giftList
      giftList({cate_id: index + 1}).then(res => {
        if(res.code == 0){
          this.giftList = []
          list[index] = res.list
          this.giftList = list
        }
      })
    },
  }
}
</script>

<style scoped>
.giftcontainer{
  width: 100%;
  height: auto;
}
.giftcontainer .giftitembox{
  justify-content: flex-start;
  padding: 10px;
}
.giftcontainer .giftitembox .giftitem{
  width: 20%;
  height: auto;
  align-content: space-between;
  font-size: 12px;
  margin: 2.5%;
}
.giftcontainer .giftitembox .giftitem .img{
  width: 100%;
  padding: 50%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}
.giftcontainer .giftitembox .giftitem .giftname{
  height: 20px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333;
}
.giftcontainer .giftitembox .giftitem .giftprice{
  height: 20px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: "#999";
}
.giftcontainer .giftitembox .giftitem .sendbtn{
  padding: 0 5px;
  height: 20px;
  border: 1px solid #ff4a53;
  color: #ff4a53;
  border-radius: 10px;
  cursor: pointer;
}
.details{
  display: flex;
  flex-wrap: wrap;
}
.details .dleft{
  width: 40%;
  padding: 0 0 20px 20px;
}
.details .dright {
  width: 55%;
  margin-left: 5%;
  text-align: left;
}
.sendtextarea{
  margin-top: 20px;
}
.details .dleft img{
  width: 100%;
}
</style>
