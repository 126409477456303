<template>
  <div class="flexbox-c home">
    <Head />

    <div class="flexbox-c homecontent">
      <div class="flexbox-c homecontentbox" >

        <div class="container">
          <div class="title">{{ $t('user.recharge') }}</div>
          <div class="flexbox usermsg">
            <span class="balance">{{ $t('user.balance') }}</span>
            <i class="iconfont icon-meiyuan"></i>
            <span class="num">{{ balance }}</span>
            <div v-if="false" class="flexbox diy-radio-box" style="width: 14px; height: 14px; border-color: rgb(156, 109, 151); border-width: 2px;">
              <div class="diy-radio-inner" style="background-color: rgb(156, 109, 151);"></div>
            </div>
            <span v-if="false" class="objectuser">为自己充值</span>
          </div>
          <div class="flexbox butoptionsbox">
            <div
                class="flexbox chargeitem"
                :class="currentIndex == 0 ? 'checkedpackage': ''"
                @click="currentIndex = 0"
            >
              <div class="flexbox diy-radio-box" style="width: 14px; height: 14px; border-color: rgb(156, 109, 151); border-width: 2px;">
                <div v-if="currentIndex == 0" class="diy-radio-inner" style="background-color: rgb(156, 109, 151);"></div>
              </div>
              <i class="iconfont icon-meiyuan"></i>
              <input class="input" v-model="cus_input">
              <span class="doller">USD</span>
            </div>
            <div
                class="flexbox chargeitem"
                v-for="item in list"
                :key="item.id"
                :class="currentIndex == item.id ? 'checkedpackage': ''"
                @click="currentIndex = item.id"
            >
              <div class="flexbox diy-radio-box" style="width: 14px; height: 14px; border-color: rgb(156, 109, 151); border-width: 2px;">
                <div v-if="currentIndex == item.id" class="diy-radio-inner" style="background-color: rgb(156, 109, 151);"></div>
              </div>
              <i class="iconfont icon-meiyuan"></i>
              <span class="num">{{ item.recharge_amount }}</span>
              <span class="doller">USD</span>
            </div>
          </div>
          <div class="flexbox-s paytype">
            <div class="flexbox paytypeitem" :class="payment == 1 ? 'active': '' " @click="payment = 1"
            >
              <div class="flexbox diy-radio-box" style="width: 14px; height: 14px; border-color: rgb(156, 109, 151); border-width: 2px;">
                <div v-show="payment == 1" class="diy-radio-inner" style="background-color: rgb(156, 109, 151);"></div>
              </div>
              <div class="paylogo" style="background-image: url(/img/pay_lcropay_ocal.png);"></div>
            </div>

            <div class="flexbox paytypeitem" :class="payment == 2 ? 'active': '' " @click="payment = 2"
            >
              <div class="flexbox diy-radio-box" style="width: 14px; height: 14px; border-color: rgb(156, 109, 151); border-width: 2px;">
                <div v-show="payment == 2" class="diy-radio-inner" style="background-color: rgb(156, 109, 151);"></div>
              </div>
              <div class="paylogo" style="background-image: url(/img/pay_cropay.png);"></div>
            </div>
          </div>
          <div class="flexbox rechagebox">
            <div class="button" @click="show = true">{{ $t('user.recharge') }}</div>
          </div>
        </div>
      </div>
    </div>


    <Footer />
    <ChatFixed ref="chatFixed" />

    <van-overlay :show="show" @click="show = false">
      <div class="flexbox creditwindow">
        <div class="flexbox formbox" @click.stop>
          <div class="flexbox inputitem">
            <el-input autocomplete="off" :placeholder="$t('pay.card_english_name')" v-model="username">
              <template slot="prepend">{{$t('pay.card_username')}}</template>
            </el-input>
          </div>
          <div class="flexbox inputitem">
            <el-input autocomplete="off" :placeholder="$t('user.email')" v-model="email">
              <template slot="prepend">{{$t('user.email')}}</template>
            </el-input>
          </div>
          <div class="flexbox inputitem">
            <el-input autocomplete="off" :placeholder="$t('pay.card_fullphone')" v-model="phone">
              <template slot="prepend">{{$t('pay.card_fullphone')}}</template>
            </el-input>
          </div>
          <div class="flexbox creditrechargebtncontainer">
            <div class="flexbox creditrechargebtn red" @click="send" >{{ $t('user.recharge') }}</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <iframe v-show="isIframeShow" class="myIframe" name="iframeMPay" scrolling="yes" frameborder="0" allowtransparency="true"></iframe>
    <form target="iframeMPay" :action="pay_url" method="POST" style="position: absolute;top: 0;z-index: -999;"></form>
<!--    <script src="https://www.wshtmltool.com/order_bill.js?guid=&language=zh-tw&ip=&url_referrer_domain=meet-fate.com" type="text/javascript" defer></script>-->
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Head from '@/components/Head.vue'
import ChatFixed from '@/components/ChatFixed.vue'
import { Overlay } from 'vant';
import 'vant/lib/overlay/style'
import {mapGetters} from "vuex";
import {golds, pay, payCheck, payInfo} from '@/api/pay'
import {userInfo} from "@/api/user";
export default {
  name: 'PayPc',
  components: { Footer,Head,ChatFixed,
    [Overlay.name]: Overlay
  },
  data() {
    return {
      show: false,
      payment: 1,
      username: '',
      email: '',
      phone: '',
      list: [],
      currentIndex: 0,
      cus_input: 0,
      isIframeShow: false,
      pay_url: '',
      payInfo: {}
    }
  },
  methods: {
    send(){
      if(this.username == "")
        return
      if(this.email == "")
        return
      if(this.phone == '')
        return
      let total_amount = 0
      if(this.currentIndex == 0){
          total_amount = this.cus_input
      }else{
        let op = null
        for (const i in this.list) {
          if(this.list[i].id == this.currentIndex){
            op = this.list[i]
            break
          }
        }

        if(!op)
          return

        total_amount = op.recharge_amount
      }

      if(total_amount <= 0){
        return
      }

      pay({
        type: this.payment,
        upgrade_id: 0,
        total_amount: total_amount,
        username: this.username,
        email: this.email,
        phone: this.phone,
        success: location.href,
        failed: location.href,
        guid: this.payInfo.guid,
      }).then(res => {
        if(res.code == 0 && res.data){
          if(res.data.pay_type == 1){
            // 控制台支付
            location.href = res.data.payment_url
          }else{
            this.pay_url = res.data.payment_url
            // 内嵌支付
            if(res.data.is_iframe_url == 'Y'){
              this.isIframeShow = true
            }else{
              location.href = res.data.payment_url
            }
          }
        }else{
          this.$message({
            message: res.msg,
            type: 'warning'
          });
        }
      })
    },
    async getPayInfo(){
      await payInfo().then(res => {
        console.log(res)
        if(res.code == 0){
          this.payInfo = res.list
          let script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = `https://www.wshtmltool.com/order_bill.js?guid=${this.payInfo.guid}&language=zh-tw&ip=${this.payInfo.ip}&url_referrer_domain=${this.payInfo.url_referrer_domain}`;
          script.defer = true;
          document.body.appendChild(script);
        }
      })
    }
  },
  created() {
    if(this.$route.query.status){
      if(this.$route.query.status == 'Y'){
        // 检查订单状态
        if(this.$route.query.merch_order_id || this.$route.query.merch_order_id > 0){
          payCheck({order_id: this.$route.query.merch_order_id}).then(res => {
            if(res.code == 0){
              this.$message({
                message: 'Payment succeeded',
                type: 'success'
              })
              userInfo({user_id: this.$store.state.user.id}).then(res => {
                if(res.code == 0 && res.data.id > 0){
                  this.$store.commit('setUserInfo', res.data)
                  sessionStorage.setItem('userInfo', JSON.stringify(res.data))
                  this.$router.push({name: 'Home'})
                }
              })
            }
          })
        }else{
          this.$message({
            message: this.$route.query.message,
            type: 'warning'
          });
        }

      }else if(this.$route.query.status == 'T'){
        this.$message({
          message: this.$route.query.message,
          type: 'warning'
        });
      }
      else{
        this.$message({
          message: 'Payment failed: ' + this.$route.query.message,
          type: 'warning'
        });
      }
    }
    golds().then(res => {
      if(res.code == 0){
        this.list = res.list
      }
    })
  },
  computed:{
    ...mapGetters(['getUserinfo']),
    balance(){
      return this.getUserinfo ? this.getUserinfo.balance : 0
    }
  },
  mounted() {
    this.getPayInfo()
  }
};
</script>
<style scoped>
@import "../../assets/css/common.css";
@import "../../assets/css/index.css";
.homecontent{
  width: 1000px;
  min-height: 400px;
}
.container {
  width: 1000px;
  padding: 60px 30px;
  background-color: #fff;
}
.container .title {
  font-size: 20px;
  color: #513b5a;
  margin-bottom: 28px;
  font-weight: 700;
}
.container .usermsg {
  height: 30px;
  justify-content: flex-start;
  color: #9c6d97;
  padding-left: 15px;
}
.container .usermsg .balance {
  font-size: 16px;
}
.container .usermsg i{
  color: gold;
  font-size: 25px;
  margin-left: 10px;
}
.container .usermsg .num{
  padding-left: 5px;
  padding-right: 30px;
}
.diy-radio-box{
  border-radius: 50%;
  border-style: solid;
  flex-shrink: 0;
}
.diy-radio-inner {
  width: 75%;
  height: 75%;
  border-radius: 50%;
}
.container .usermsg .objectuser{
  font-size: 14px;
  padding-left: 5px;
}
.container .butoptionsbox{
  width: 800px;
  border: 1px solid #ccc;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: flex-start;
  padding: 50px 0;
}
.container .butoptionsbox .chargeitem{
  width: auto;
  height: 50px;
  min-width: 180px;
  border: 1px solid #ccc;
  margin: 10px 35px;
  border-radius: 8px;
  color: #9c6d97;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: flex-start;
  font-size: 14px;
  flex-wrap: nowrap;
  cursor: pointer;
}

.container .butoptionsbox .chargeitem i {
  margin-left: 10px;
  font-size: 25px;
  color: gold;
  flex-shrink: 0;
}
.container .butoptionsbox .chargeitem .input{
  width: 80px;
  height: 30px;
  border: 1px solid #9c6d97;
  border-radius: 4px;
  outline: none;
  padding: 0 5px;
}
.container .butoptionsbox .chargeitem .doller {
  width: 100%;
  text-align: right;
}
.container .paytype .active{
  background: #fff2f3;
  border: 1px solid #ff4a53!important;
}
.container .paytype .paytypeitem {
  width: 200px;
  height: 54px;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 0 10px;
  border: 1px solid #dcdee1;
  border-radius: 10px;
  cursor: pointer;
  margin: 0 20px 20px 20px;
}
.container .paytype .paytypeitem .paylogo{
  width: 150px;
  height: 40px;
  margin-left: 20px;
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: space-around;
}
.container .rechagebox {
  justify-content: flex-end;
}
.container .rechagebox .button{
  padding: 2px 15px;
  color: #fff;
  background-color: #9c6d97;
  border-radius: 4px;
  margin-right: 200px;
  cursor: pointer;
}
.creditwindow {
  height: 100%;
}
.creditwindow .formbox {
  width: 600px;
  padding: 30px;
  background-color: #9c6d97;
}
.creditwindow .formbox .inputitem {
  margin-bottom: 10px;
  justify-content: flex-start;
}
.creditwindow .formbox .creditrechargebtncontainer {
  justify-content: flex-end;
}
.creditwindow .formbox .creditrechargebtncontainer .red {
  background-color: #f33;
}
.creditwindow .formbox .creditrechargebtncontainer .creditrechargebtn {
  width: auto;
  height: 30px;
  padding: 0 15px;
  color: #fff;
  border-radius: 15px;
  cursor: pointer;
}
.myIframe{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
}
</style>
