<template>
<div class="giftcontainer">
  <van-tabs v-model="active" ref="tabs">
    <van-tab ref="firstTab" :title="$t('store.love_space')">
      <div class="flexbox giftitembox">
        <div
            class="flexbox giftitem"
            v-for="item in giftList[0]"
            :key="item.id"
        >
          <div class="img" :style="'background-image: url(' + item.img + ');'">
          </div>
          <div class="flexbox giftname">{{lang == 'en' ? item.name_en : item.name}}</div>
          <div class="flexbox giftprice">{{item.price}} {{ $t('pay.coin') }}</div>
          <a @click="sendGift(item)" class="flexbox sendbtn">{{ $t('user.send_gift') }}</a>
        </div>
      </div>
    </van-tab>
    <van-tab :title="$t('store.lovers_space')">
      <div class="flexbox giftitembox">
        <div
            class="flexbox giftitem"
            v-for="item in giftList[1]"
            :key="item.id"
        >
          <div class="img" :style="'background-image: url(' + item.img + ');'">
          </div>
          <div class="flexbox giftname">{{lang == 'en' ? item.name_en : item.name}}</div>
          <div class="flexbox giftprice">{{item.price}} {{ $t('pay.coin') }}</div>
          <a @click="sendGift(item)" class="flexbox sendbtn">{{ $t('user.send_gift') }}</a>
        </div>
      </div>
    </van-tab>
    <van-tab :title="$t('store.goddess_space')">
      <div class="flexbox giftitembox">
        <div
            class="flexbox giftitem"
            v-for="item in giftList[2]"
            :key="item.id"
        >
          <div class="img" :style="'background-image: url(' + item.img + ');'">
          </div>
          <div class="flexbox giftname">{{lang == 'en' ? item.name_en : item.name}}</div>
          <div class="flexbox giftprice">{{item.price}} {{ $t('pay.coin') }}</div>
          <a @click="sendGift(item)" class="flexbox sendbtn">{{ $t('user.send_gift') }}</a>
        </div>
      </div>
    </van-tab>
    <van-tab :title="$t('store.lovers')">
      <div class="flexbox giftitembox">
        <div
            class="flexbox giftitem"
            v-for="item in giftList[3]"
            :key="item.id"
        >
          <div class="img" :style="'background-image: url(' + item.img + ');'">
          </div>
          <div class="flexbox giftname">{{lang == 'en' ? item.name_en : item.name}}</div>
          <div class="flexbox giftprice">{{item.price}} {{ $t('pay.coin') }}</div>
          <a @click="sendGift(item)" class="flexbox sendbtn">{{ $t('user.send_gift') }}</a>
        </div>
      </div>
    </van-tab>
  </van-tabs>
</div>
</template>

<script>
import { Tab, Tabs  } from 'vant';
import 'vant/lib/tab/style'
import 'vant/lib/tabs/style'
import {giftList} from "@/api";
import {mapGetters} from "vuex";
export default {
  name: "m_gift",
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
  },
  data() {
    return {
      active: 0,
      giftList: [[],[],[],[]],
      lang: 'tn',
      username: ''
    };
  },
  computed:{
    ...mapGetters([
      'getLanguage'
    ]),
  },
  watch: {
    getLanguage: {
      handler(newV){
        this.lang = newV
      },
      immediate: true
    }
  },
  created() {
    if(this.$route.query.username)
      this.username = this.$route.query.username

    this.tabClick(0)
    this.tabClick(1)
    this.tabClick(2)
    this.tabClick(3)
  },
  mounted() {
  },
  methods: {
    tabClick(index) {
      let list = this.giftList
      giftList({cate_id: index + 1}).then(res => {
        if(res.code == 0){
          this.giftList = []
          list[index] = res.list
          this.giftList = list
        }
      })
    },
    sendGift(gift){
      this.$router.push({name: 'MGiftSend', query: {gift_id: gift.id, username: this.username}})
    },
  }
}
</script>

<style scoped>
@import "../../assets/css/common.css";
.giftcontainer{
  width: 100%;
  height: auto;
}
.giftcontainer .giftitembox{
  justify-content: flex-start;
  padding: 10px;
}
.giftcontainer .giftitembox .giftitem{
  width: 20%;
  height: auto;
  align-content: space-between;
  font-size: 12px;
  margin: 2.5%;
}
.giftcontainer .giftitembox .giftitem .img{
  width: 100%;
  padding: 50%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}
.giftcontainer .giftitembox .giftitem .giftname{
  height: 20px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333;
}
.giftcontainer .giftitembox .giftitem .giftprice{
  height: 20px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: "#999";
}
.giftcontainer .giftitembox .giftitem .sendbtn{
  padding: 0 5px;
  height: 20px;
  border: 1px solid #ff4a53;
  color: #ff4a53;
  border-radius: 10px;
  cursor: pointer;
}
</style>
