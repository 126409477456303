<template>
<div>
  <div class="content_area">
    <el-form ref="form" @submit.native.prevent>
    <div class="basicinfo">{{ $t('user.basic') }}</div>
    <el-form-item>
      <el-input v-model="username" :placeholder="$t('user.username')"></el-input>
    </el-form-item>
    <el-form-item>
      <el-input v-model="email" :placeholder="$t('user.email')"></el-input>
    </el-form-item>
    <el-form-item>
      <el-input v-model="lienId" :placeholder="$t('user.line')"></el-input>
    </el-form-item>
    <el-form-item>
      <el-input v-model="password" :placeholder="$t('user.password')"></el-input>
    </el-form-item>
    <el-form-item :label="$t('user.sex')">
      <el-radio-group v-model="sex">
        <el-radio :label="1">{{$t('user.male')}}</el-radio>
        <el-radio :label="0">{{$t('user.female')}}</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item :label="$t('user.birthday')">
      <el-input v-model="born" @focus="openDatePicke"></el-input>
    </el-form-item>
    <el-form-item>
      <el-checkbox-group v-model="isCheck">
        <el-checkbox :label="true" name="type">{{$t('user.i_agree_with_it')}}</el-checkbox>
      </el-checkbox-group>
    </el-form-item>
    <div style="margin: 16px;">
      <van-button @click="registerBtn" type="danger" color="#FF4A53" round block>{{ $t('user.login') }}</van-button>
    </div>
    </el-form>
  </div>
  <van-action-sheet v-model="show" title="">
    <van-datetime-picker
    v-model="birthday"
    type="date"
    :min-date="minDate"
    :max-date="maxDate"
    @confirm="dateChange"
    @cancel="show = false"
    />
  </van-action-sheet>
</div>
</template>

<script>
import {ActionSheet,Button,DatetimePicker ,Locale} from 'vant';
import 'vant/lib/action-sheet/style'
import 'vant/lib/button/style'
import 'vant/lib/datetime-picker/style'
import en from 'vant/es/locale/lang/en-US';
import cn from 'vant/es/locale/lang/zh-CN';
import tn from 'vant/es/locale/lang/zh-TW';
import {mapGetters} from "vuex";
import {login} from "@/api/user";
export default {
  name: "m_register",
  components: {
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [DatetimePicker.name]: DatetimePicker,
  },
  data() {
    return {
      username: '',
      password: '',
      email: '',
      lienId: '',
      sex: 1,
      born: '1990-01-01',
      isCheck: true,
      show: false,
      minDate: new Date(1955, 0, 1),
      maxDate: new Date(),
      birthday: new Date('1990-01-01')
    }
  },
  computed: {
    ...mapGetters([
      'getUserinfo',
      'getLanguage',
    ]),
  },
  watch: {
    getLanguage: {
      handler(newV){
        if(newV == 'cn'){
          Locale.use('zh-CN',cn)
        }else if(newV == 'en'){
          Locale.use('en-US',en)
        }else{
          Locale.use('zh-TW', tn)
        }
      },
      immediate: true
    }
  },
  methods: {
    dateChange(e){
      let date = new Date(e)
      this.born = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      this.show = false
    },
    openDatePicke(){
      this.show = true
    },
    registerBtn(){
      if(this.username.length <= 0){
        this.$message({
          message: this.$t('user.please_enter') + ' ' + this.$t('user.username'),
          type: 'warning'
        })
        return false
      }
      if(this.password.length <= 0){
        this.$message({
          message: this.$t('user.please_enter') + ' ' + this.$t('user.password'),
          type: 'warning'
        })
        return false
      }
      if(this.email.length <= 0){
        this.$message({
          message: this.$t('user.please_enter') + ' ' + this.$t('user.email'),
          type: 'warning'
        })
        return false
      }
      if(this.lienId.length <= 0){
        this.$message({
          message: this.$t('user.please_enter') + ' ' + this.$t('user.line'),
          type: 'warning'
        })
        return false
      }
      if(!this.isCheck){
        return false
      }
      const that = this
      let age = Math.floor((((new Date().getTime()) - (new Date(this.born).getTime())) / 86400 / 1000/ 365))
      let data = {
        username: this.username,
        password: this.password,
        type: 1,
        born: this.born,
        age: age,
        email: this.email,
        lienId: this.lienId,
        sex: this.sex,
        salesman_id: localStorage.getItem('salesman_id') ? localStorage.getItem('salesman_id') : 0
      }
      login(data).then(res => {
        if(res.code != 0){
          this.$message.error('Register failed');
          return false
        }else{
          this.$store.commit('setUserInfo', res.user)
          this.$store.commit('loginState', true)
          this.$message({
            message: 'Register successful',
            type: 'success'
          });
          sessionStorage.setItem('token', res.user.token)
          sessionStorage.setItem('user_id', res.user.id)
          sessionStorage.setItem('loginState', true)
          that.$router.push({name: 'Mhome'})
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
@import "../assets/css/common.css";
.content_area {
  width: 100%;
  height: 100vh;
  padding: 20px;
  text-align: left;
}
.basicinfo {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
::v-deep .el-form-item__content {
  display: flex!important;
}
</style>
