<template>
  <div>
    <div class="invest_area">
      <div class="invest_area_top">
        <div class="invest_area_top_icon" @click="$router.back()">
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAABAklEQVRIS8XVz2oCMRAG8G+Mq9BDD32Agogg2/3X7t1L1XfwPfpKfQgvWnsqlNo0gZbe6sl36Lo7soVVinhYw2DO4ceXSWZCEFok5OI8cBDcRcqjKTE/aL18rHO6o4n9OL7xSM1KjIvNwBjz7QyHYdhvNFtzAqt8k91ba20dtNx7kDhJkh5IPQFo/3I+/NRa10UP4CBIu6rJCwAXRZ6NrLVvp6D/4CiKOqS8BQGX4HyktX49Fd3Bvp9et9r8zExXXGRjY8yLC7qD49v0i4A+wGsGVo7oz8f7cvJ3eWKwWCnK1CKXV9VU5LlVuEiDVLhIS1e4yBDa11xgbDo2yZl+EJfUW+dUiBfEiYriAAAAAElFTkSuQmCC" alt="">
        </div>
        <span>{{ filterTile }}</span>
      </div>
    </div>
    <div class="pay_number">
      <div class="pay_box">
        <p>實付金額</p>
        <p class="sum">
          <span>$</span>
          <span class="money">{{ filterMoney }}</span>
        </p>
      </div>
    </div>
    <div class="pay_way">
      <p>支付方式</p>
      <div class="pay_list">
        <div class="pay_item" @click="payment = 1">
          <img v-if="payment == 1" class="pay_choose" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAAEx0lEQVRYR9WZX2zTVRTHP6f7g25EE7aIxokajGh0Pogg0YchPJjNDV27MpCZoDGguGkkJjhkc7LxJ4oPZhA2QvAFmKN0GDY6eRBR439BER5w0QdlPKCdDwrIytZr7tquXftr+/v92oHcx+2c7/3c0/O799xzhWtsyDXGS0bAqqXFwbcn5wCPAQ8BdwO3AoXhQFwAzgIDwPfAJ8wt/U5aWoJ2A2ULWJU7SxB5CVgG3GZx8jPAHpTaJv09gxZ9rUVYVVcXMSzrQZ4H8q1OFmcfALWTKapZDhwYMqtlOsKqwrUY1DaQYrPi5uyUH0W99Pd0m7FPC6zmz8+loKgdeMGMYAY2HVwcapCjR0dSaaQEVlVVBYzm65VXZgBixbWPnECt9PZeTOaUFHgsstcXexG1yMqMGdsqOci/fleySCcHrnB2gKzMGMCWgOoUX49hChoCq3JnLSIf2Jore05Pi8/bFS+XABzeuk5nfzewvJK/UI57pN/zZ6xnInCFa/sV2BFM0iemxgRgVemeQTD4C5BnUnGyzS7jcNwlfZ7fIxNNBK5wbQJen2wKi/qbxedtTAAOFzK/ASUWBTMzz8uDV1ZB2aNw+GPY2hmvN8jc0tsjBdN4hFWF62Hg68xmt+hdWABNa6D0vpDj6CgsqjUSmSc+7zf6HzHAzkaQjRantG9+4w3Q2gQz74xq7P8Q3t9tpNkoPu/mOGDXfsBln8CC5/SbYEMz3HJz1Gl3N3R5kol4xeetiQc+CdxvYVp7pnfMgNZ1MG1ayF8p2L4TDh1OpXdKfN7SicDlLj9CUUqK3FwYSVlMpV7EvbPgrbVQGL6QjIzClvfg8y9T+ymGpN87VtbGfnTDKYvy1Q2wsAw++wLebbcOPudBWPsa5Ifr/kvD0PY2/HDCzC8VEJ93ijXgg92QkxMSP/4jtL0Dw3qNJsaCstDWlRv2//sfeHMDDOgzytQwAE6XEnW1sNQdVf/pFLRsSg/9ZCWsWB718w/BG+thUN9NTY4kKZH+o3u2DmqeMgctAs8sgdqYjefMIKxrBQ1tbRh8dBUmtzUz0A4H1K+ExxdGsfTPr9NAp4P1YbStWTg4dGroFImM0wPQ3AYXLoLeSda8Co/ogzM8jp8IfWBmcz5xQQYHR7lzHiJfmV68uxqW67ZEeOgIbtwCq+vhgZjt3O6uMhHE4GgOdXGsFT/x0MEg6HSIjL6PoHMX6L/bH8bFj9ZTdsrLeOgI2N59sGeffcyop3F5OQYcakH9armro/dZndO6Rjj3B+i64Min2YANoNTM2JaWwRXJqbs7q7IxWxY0OsTnfTFWx/gSesnxc9q6Igs0KSUUQ1wXnBXfdzO+5j9RswSlEq7Yk804UV8tE1/P3vg5/5+NFMUO6fcaNnGSA7vd+ZxXnqvSqpoqbvF4Aka/qJlmoL6JlF+ZdJBD5AwvttUMjACq2SvymO5vn/w+m+rkXHGDHNtxOVVw0vaHx8HHPsSgBs9+Qxt52aiPZjkl4h1U1dJiRgOtwHOWD5fE2XWO7iInv0l6u/xmU850hGMFx05EqEekLvxqZHY+bXcWpfRdfuukP8okRHz82UstAJkdevZSJSBTQ7bqPIh+KRoAdQzkyFV59rISzmzb2kqJbENY0fsPy3enPPHPCQAAAAAASUVORK5CYII=" alt="">
          <img v-else class="pay_choose" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAAFJElEQVRYR9WZW2wUVRjH/9+0WyheUmw1qVaIwSg1auKFSARMqImGaHxBC7H4Quiels5MZxMe7INmoonyoO3szNTubIlPRQXkxUhIUGqiGDV4SZRYNF4Cgk2kq40Xqt12PnP20steym7b7WVe95zz/fabb77zP/9DWGYPLTNezAnYNE2lqqZ2AxhbAdwP4DYANwG4KpWIfwBcBPA9gM9B+GB4aPC0aZr+bBM1K2Db7q3zyW8D0ATg5iKD/wLgoMJKt643XyhybnEZdl23eozLXwBoD4CKVLCfADrBjFNQeCCA+LmhoRv+kr/V1Px2TRyBtfCpngibAX4UwC2peaMAHyinsedVVY0VCl5whsNutJF97gahBoB8pUeYOBxSWz4pNBgzU/i16Cb4kG+nEYACxhCIVUNrOVTIOlcENk2zvKr6RgfgluSC3K8wNF1v+baQAPnG2HbkDp/gANSQHEOR4divmmmaYzOtOyOw53mrRkb5EECPA/iXCftCquieC2jmXMuJ7gX4VQArAX63soJ2CCEu54uRFziZ2dqjAJ4AEAPhMUMVn80nbHoty/UeAOMYgGoA7wzHBrfny3ReYMv1ImAIAJfKgIc0TZwtBWx6Tcfx1o8DHwK4HgTPUEWqBKdHzQlsOZEdAL0FYASEraXKbFZ5JDPdD2AVMz8d0lvezByTBZxoXX7grOwGRCza1ZZoKTObuXbYjQSZyQPwu8KB9bq++9LUMVnAlhPtSXYEes/Qgo8sJOxETTuRk4nukaM0pgFblrcGZfhB9hhWlLtDbc0DiwHc1d1bT77/teyhGMethiHOpzmmAzveywCeBdBnaOKZxYBNxwy7kT5mklv/fkMTHVnACSFTXXsOQB3D3xzSWj9eTOBOu3eLQr7sGheGY4Nr04JpIsOpXvgpgJ/b1eA6IuLFBE5s4270x4T2IGxMd6oJ4C432kHML83UAxf6D0zZCzoMTexPbOCTX6b3NoDtzLQrpAcPLjRcrnhddrSJiPsAHDU08WQm8DcA7lTYv1fXW79aCsC23XOPT8qXAM4YmrgrE3hI7uXlFK8pRp+W8o8l9XdAcsUMTUhZO60k/pOifDi2eoVpNo6WEqTQtU3zcEVV9R+Sa9TQxIplD7zkSqKz88B1SmBcHp9ylsSy++iWV1ub2DhAEUMLthb6YZRy3KRyRPbGYdu9G33y5Ql4eWzNS038dDk9mwjKqbziR75aawnJS8vx5PFoZ155KYFTFpRUSMoSEPBnAIwprKybamnlOCJFugHay8CJkCaktbTgj2V774PwsDRXMhtA7kMoB75LeARMQUMP9i4ksWVHm0EsD76xcorfnqlrch7zw250JzPLGroMQsNiHPMJaGrXxBuZyVqSRgoD0ZAmpImT9cxgVSWU0pEJq0qhbUZb8HQpysPqjm6Az8cnrarVT+VTjAWYgZBb9raUGaiFVHFgPqG7XG8PMWwAlQQcW1mBxlmZgWkoz/MCI3E4KZ9NOgUnFbA+V7s14T2M+06yGySUuVcZgCaEiM+UkCv6w+nJiQ/RZxkgbWgfZmK7WEPbciMPEhR1qqHNYD2Xj1ZUDeca/Irn1ZSP0osA7868MiDwR8T+gKKMnw8EAn/K+fF4/FrfL1vDpNQzaEv2lQG9PlbBz+0TQmrxgp6CMzx1tcSOCFZBvCt1a1RQsNSgi2DqU0BuyS9lMqnS114MaiDm+1LXXnUArk6N/VuKF3ntxURfELh/Ua69iknnfI+dVUnMN0Qx6/0PO2ttSxuR+KUAAAAASUVORK5CYII=" alt="">
          <img class="pay_type" src="img/pay_lcropay_ocal.png" alt="">
        </div>
        <div class="pay_item" @click="payment = 2">
          <img v-if="payment == 2" class="pay_choose" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAAEx0lEQVRYR9WZX2zTVRTHP6f7g25EE7aIxokajGh0Pogg0YchPJjNDV27MpCZoDGguGkkJjhkc7LxJ4oPZhA2QvAFmKN0GDY6eRBR439BER5w0QdlPKCdDwrIytZr7tquXftr+/v92oHcx+2c7/3c0/O799xzhWtsyDXGS0bAqqXFwbcn5wCPAQ8BdwO3AoXhQFwAzgIDwPfAJ8wt/U5aWoJ2A2ULWJU7SxB5CVgG3GZx8jPAHpTaJv09gxZ9rUVYVVcXMSzrQZ4H8q1OFmcfALWTKapZDhwYMqtlOsKqwrUY1DaQYrPi5uyUH0W99Pd0m7FPC6zmz8+loKgdeMGMYAY2HVwcapCjR0dSaaQEVlVVBYzm65VXZgBixbWPnECt9PZeTOaUFHgsstcXexG1yMqMGdsqOci/fleySCcHrnB2gKzMGMCWgOoUX49hChoCq3JnLSIf2Jore05Pi8/bFS+XABzeuk5nfzewvJK/UI57pN/zZ6xnInCFa/sV2BFM0iemxgRgVemeQTD4C5BnUnGyzS7jcNwlfZ7fIxNNBK5wbQJen2wKi/qbxedtTAAOFzK/ASUWBTMzz8uDV1ZB2aNw+GPY2hmvN8jc0tsjBdN4hFWF62Hg68xmt+hdWABNa6D0vpDj6CgsqjUSmSc+7zf6HzHAzkaQjRantG9+4w3Q2gQz74xq7P8Q3t9tpNkoPu/mOGDXfsBln8CC5/SbYEMz3HJz1Gl3N3R5kol4xeetiQc+CdxvYVp7pnfMgNZ1MG1ayF8p2L4TDh1OpXdKfN7SicDlLj9CUUqK3FwYSVlMpV7EvbPgrbVQGL6QjIzClvfg8y9T+ymGpN87VtbGfnTDKYvy1Q2wsAw++wLebbcOPudBWPsa5Ifr/kvD0PY2/HDCzC8VEJ93ijXgg92QkxMSP/4jtL0Dw3qNJsaCstDWlRv2//sfeHMDDOgzytQwAE6XEnW1sNQdVf/pFLRsSg/9ZCWsWB718w/BG+thUN9NTY4kKZH+o3u2DmqeMgctAs8sgdqYjefMIKxrBQ1tbRh8dBUmtzUz0A4H1K+ExxdGsfTPr9NAp4P1YbStWTg4dGroFImM0wPQ3AYXLoLeSda8Co/ogzM8jp8IfWBmcz5xQQYHR7lzHiJfmV68uxqW67ZEeOgIbtwCq+vhgZjt3O6uMhHE4GgOdXGsFT/x0MEg6HSIjL6PoHMX6L/bH8bFj9ZTdsrLeOgI2N59sGeffcyop3F5OQYcakH9armro/dZndO6Rjj3B+i64Min2YANoNTM2JaWwRXJqbs7q7IxWxY0OsTnfTFWx/gSesnxc9q6Igs0KSUUQ1wXnBXfdzO+5j9RswSlEq7Yk804UV8tE1/P3vg5/5+NFMUO6fcaNnGSA7vd+ZxXnqvSqpoqbvF4Aka/qJlmoL6JlF+ZdJBD5AwvttUMjACq2SvymO5vn/w+m+rkXHGDHNtxOVVw0vaHx8HHPsSgBs9+Qxt52aiPZjkl4h1U1dJiRgOtwHOWD5fE2XWO7iInv0l6u/xmU850hGMFx05EqEekLvxqZHY+bXcWpfRdfuukP8okRHz82UstAJkdevZSJSBTQ7bqPIh+KRoAdQzkyFV59rISzmzb2kqJbENY0fsPy3enPPHPCQAAAAAASUVORK5CYII=" alt="">
          <img v-else class="pay_choose" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAAFJElEQVRYR9WZW2wUVRjH/9+0WyheUmw1qVaIwSg1auKFSARMqImGaHxBC7H4Quiels5MZxMe7INmoonyoO3szNTubIlPRQXkxUhIUGqiGDV4SZRYNF4Cgk2kq40Xqt12PnP20steym7b7WVe95zz/fabb77zP/9DWGYPLTNezAnYNE2lqqZ2AxhbAdwP4DYANwG4KpWIfwBcBPA9gM9B+GB4aPC0aZr+bBM1K2Db7q3zyW8D0ATg5iKD/wLgoMJKt643XyhybnEZdl23eozLXwBoD4CKVLCfADrBjFNQeCCA+LmhoRv+kr/V1Px2TRyBtfCpngibAX4UwC2peaMAHyinsedVVY0VCl5whsNutJF97gahBoB8pUeYOBxSWz4pNBgzU/i16Cb4kG+nEYACxhCIVUNrOVTIOlcENk2zvKr6RgfgluSC3K8wNF1v+baQAPnG2HbkDp/gANSQHEOR4divmmmaYzOtOyOw53mrRkb5EECPA/iXCftCquieC2jmXMuJ7gX4VQArAX63soJ2CCEu54uRFziZ2dqjAJ4AEAPhMUMVn80nbHoty/UeAOMYgGoA7wzHBrfny3ReYMv1ImAIAJfKgIc0TZwtBWx6Tcfx1o8DHwK4HgTPUEWqBKdHzQlsOZEdAL0FYASEraXKbFZ5JDPdD2AVMz8d0lvezByTBZxoXX7grOwGRCza1ZZoKTObuXbYjQSZyQPwu8KB9bq++9LUMVnAlhPtSXYEes/Qgo8sJOxETTuRk4nukaM0pgFblrcGZfhB9hhWlLtDbc0DiwHc1d1bT77/teyhGMethiHOpzmmAzveywCeBdBnaOKZxYBNxwy7kT5mklv/fkMTHVnACSFTXXsOQB3D3xzSWj9eTOBOu3eLQr7sGheGY4Nr04JpIsOpXvgpgJ/b1eA6IuLFBE5s4270x4T2IGxMd6oJ4C432kHML83UAxf6D0zZCzoMTexPbOCTX6b3NoDtzLQrpAcPLjRcrnhddrSJiPsAHDU08WQm8DcA7lTYv1fXW79aCsC23XOPT8qXAM4YmrgrE3hI7uXlFK8pRp+W8o8l9XdAcsUMTUhZO60k/pOifDi2eoVpNo6WEqTQtU3zcEVV9R+Sa9TQxIplD7zkSqKz88B1SmBcHp9ylsSy++iWV1ub2DhAEUMLthb6YZRy3KRyRPbGYdu9G33y5Ql4eWzNS038dDk9mwjKqbziR75aawnJS8vx5PFoZ155KYFTFpRUSMoSEPBnAIwprKybamnlOCJFugHay8CJkCaktbTgj2V774PwsDRXMhtA7kMoB75LeARMQUMP9i4ksWVHm0EsD76xcorfnqlrch7zw250JzPLGroMQsNiHPMJaGrXxBuZyVqSRgoD0ZAmpImT9cxgVSWU0pEJq0qhbUZb8HQpysPqjm6Az8cnrarVT+VTjAWYgZBb9raUGaiFVHFgPqG7XG8PMWwAlQQcW1mBxlmZgWkoz/MCI3E4KZ9NOgUnFbA+V7s14T2M+06yGySUuVcZgCaEiM+UkCv6w+nJiQ/RZxkgbWgfZmK7WEPbciMPEhR1qqHNYD2Xj1ZUDeca/Irn1ZSP0osA7868MiDwR8T+gKKMnw8EAn/K+fF4/FrfL1vDpNQzaEv2lQG9PlbBz+0TQmrxgp6CMzx1tcSOCFZBvCt1a1RQsNSgi2DqU0BuyS9lMqnS114MaiDm+1LXXnUArk6N/VuKF3ntxURfELh/Ua69iknnfI+dVUnMN0Qx6/0PO2ttSxuR+KUAAAAASUVORK5CYII=" alt="">
          <img class="pay_type" src="img/pay_cropay.png" alt="">
        </div>
        <div v-if="filterIsCoinShow" class="pay_item" @click="payment = 3">
          <img v-if="payment == 3" class="pay_choose" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAAEx0lEQVRYR9WZX2zTVRTHP6f7g25EE7aIxokajGh0Pogg0YchPJjNDV27MpCZoDGguGkkJjhkc7LxJ4oPZhA2QvAFmKN0GDY6eRBR439BER5w0QdlPKCdDwrIytZr7tquXftr+/v92oHcx+2c7/3c0/O799xzhWtsyDXGS0bAqqXFwbcn5wCPAQ8BdwO3AoXhQFwAzgIDwPfAJ8wt/U5aWoJ2A2ULWJU7SxB5CVgG3GZx8jPAHpTaJv09gxZ9rUVYVVcXMSzrQZ4H8q1OFmcfALWTKapZDhwYMqtlOsKqwrUY1DaQYrPi5uyUH0W99Pd0m7FPC6zmz8+loKgdeMGMYAY2HVwcapCjR0dSaaQEVlVVBYzm65VXZgBixbWPnECt9PZeTOaUFHgsstcXexG1yMqMGdsqOci/fleySCcHrnB2gKzMGMCWgOoUX49hChoCq3JnLSIf2Jore05Pi8/bFS+XABzeuk5nfzewvJK/UI57pN/zZ6xnInCFa/sV2BFM0iemxgRgVemeQTD4C5BnUnGyzS7jcNwlfZ7fIxNNBK5wbQJen2wKi/qbxedtTAAOFzK/ASUWBTMzz8uDV1ZB2aNw+GPY2hmvN8jc0tsjBdN4hFWF62Hg68xmt+hdWABNa6D0vpDj6CgsqjUSmSc+7zf6HzHAzkaQjRantG9+4w3Q2gQz74xq7P8Q3t9tpNkoPu/mOGDXfsBln8CC5/SbYEMz3HJz1Gl3N3R5kol4xeetiQc+CdxvYVp7pnfMgNZ1MG1ayF8p2L4TDh1OpXdKfN7SicDlLj9CUUqK3FwYSVlMpV7EvbPgrbVQGL6QjIzClvfg8y9T+ymGpN87VtbGfnTDKYvy1Q2wsAw++wLebbcOPudBWPsa5Ifr/kvD0PY2/HDCzC8VEJ93ijXgg92QkxMSP/4jtL0Dw3qNJsaCstDWlRv2//sfeHMDDOgzytQwAE6XEnW1sNQdVf/pFLRsSg/9ZCWsWB718w/BG+thUN9NTY4kKZH+o3u2DmqeMgctAs8sgdqYjefMIKxrBQ1tbRh8dBUmtzUz0A4H1K+ExxdGsfTPr9NAp4P1YbStWTg4dGroFImM0wPQ3AYXLoLeSda8Co/ogzM8jp8IfWBmcz5xQQYHR7lzHiJfmV68uxqW67ZEeOgIbtwCq+vhgZjt3O6uMhHE4GgOdXGsFT/x0MEg6HSIjL6PoHMX6L/bH8bFj9ZTdsrLeOgI2N59sGeffcyop3F5OQYcakH9armro/dZndO6Rjj3B+i64Min2YANoNTM2JaWwRXJqbs7q7IxWxY0OsTnfTFWx/gSesnxc9q6Igs0KSUUQ1wXnBXfdzO+5j9RswSlEq7Yk804UV8tE1/P3vg5/5+NFMUO6fcaNnGSA7vd+ZxXnqvSqpoqbvF4Aka/qJlmoL6JlF+ZdJBD5AwvttUMjACq2SvymO5vn/w+m+rkXHGDHNtxOVVw0vaHx8HHPsSgBs9+Qxt52aiPZjkl4h1U1dJiRgOtwHOWD5fE2XWO7iInv0l6u/xmU850hGMFx05EqEekLvxqZHY+bXcWpfRdfuukP8okRHz82UstAJkdevZSJSBTQ7bqPIh+KRoAdQzkyFV59rISzmzb2kqJbENY0fsPy3enPPHPCQAAAAAASUVORK5CYII=" alt="">
          <img v-else class="pay_choose" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAAFJElEQVRYR9WZW2wUVRjH/9+0WyheUmw1qVaIwSg1auKFSARMqImGaHxBC7H4Quiels5MZxMe7INmoonyoO3szNTubIlPRQXkxUhIUGqiGDV4SZRYNF4Cgk2kq40Xqt12PnP20steym7b7WVe95zz/fabb77zP/9DWGYPLTNezAnYNE2lqqZ2AxhbAdwP4DYANwG4KpWIfwBcBPA9gM9B+GB4aPC0aZr+bBM1K2Db7q3zyW8D0ATg5iKD/wLgoMJKt643XyhybnEZdl23eozLXwBoD4CKVLCfADrBjFNQeCCA+LmhoRv+kr/V1Px2TRyBtfCpngibAX4UwC2peaMAHyinsedVVY0VCl5whsNutJF97gahBoB8pUeYOBxSWz4pNBgzU/i16Cb4kG+nEYACxhCIVUNrOVTIOlcENk2zvKr6RgfgluSC3K8wNF1v+baQAPnG2HbkDp/gANSQHEOR4divmmmaYzOtOyOw53mrRkb5EECPA/iXCftCquieC2jmXMuJ7gX4VQArAX63soJ2CCEu54uRFziZ2dqjAJ4AEAPhMUMVn80nbHoty/UeAOMYgGoA7wzHBrfny3ReYMv1ImAIAJfKgIc0TZwtBWx6Tcfx1o8DHwK4HgTPUEWqBKdHzQlsOZEdAL0FYASEraXKbFZ5JDPdD2AVMz8d0lvezByTBZxoXX7grOwGRCza1ZZoKTObuXbYjQSZyQPwu8KB9bq++9LUMVnAlhPtSXYEes/Qgo8sJOxETTuRk4nukaM0pgFblrcGZfhB9hhWlLtDbc0DiwHc1d1bT77/teyhGMethiHOpzmmAzveywCeBdBnaOKZxYBNxwy7kT5mklv/fkMTHVnACSFTXXsOQB3D3xzSWj9eTOBOu3eLQr7sGheGY4Nr04JpIsOpXvgpgJ/b1eA6IuLFBE5s4270x4T2IGxMd6oJ4C432kHML83UAxf6D0zZCzoMTexPbOCTX6b3NoDtzLQrpAcPLjRcrnhddrSJiPsAHDU08WQm8DcA7lTYv1fXW79aCsC23XOPT8qXAM4YmrgrE3hI7uXlFK8pRp+W8o8l9XdAcsUMTUhZO60k/pOifDi2eoVpNo6WEqTQtU3zcEVV9R+Sa9TQxIplD7zkSqKz88B1SmBcHp9ylsSy++iWV1ub2DhAEUMLthb6YZRy3KRyRPbGYdu9G33y5Ql4eWzNS038dDk9mwjKqbziR75aawnJS8vx5PFoZ155KYFTFpRUSMoSEPBnAIwprKybamnlOCJFugHay8CJkCaktbTgj2V774PwsDRXMhtA7kMoB75LeARMQUMP9i4ksWVHm0EsD76xcorfnqlrch7zw250JzPLGroMQsNiHPMJaGrXxBuZyVqSRgoD0ZAmpImT9cxgVSWU0pEJq0qhbUZb8HQpysPqjm6Az8cnrarVT+VTjAWYgZBb9raUGaiFVHFgPqG7XG8PMWwAlQQcW1mBxlmZgWkoz/MCI3E4KZ9NOgUnFbA+V7s14T2M+06yGySUuVcZgCaEiM+UkCv6w+nJiQ/RZxkgbWgfZmK7WEPbciMPEhR1qqHNYD2Xj1ZUDeca/Irn1ZSP0osA7868MiDwR8T+gKKMnw8EAn/K+fF4/FrfL1vDpNQzaEv2lQG9PlbBz+0TQmrxgp6CMzx1tcSOCFZBvCt1a1RQsNSgi2DqU0BuyS9lMqnS114MaiDm+1LXXnUArk6N/VuKF3ntxURfELh/Ua69iknnfI+dVUnMN0Qx6/0PO2ttSxuR+KUAAAAASUVORK5CYII=" alt="">
          <div class="pay_type pay_type1">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAADa0lEQVRIS8WXT2xUVRTGf9+TlkoNRSom2g2JQRNNcIENM1NCbDQl1c5UNOoOogbDSomsYOVKV2JITGyMTqKs1fAYwDYqJoTOFG0XhmAaJC4aUAOVoBb6Z+YdM29m2r46zH0lFe/yvXO+755zz/nuuSLGslNPtrC6rRdZD7AF7CFQW8XVroMuAqOYhpi5flLd3027YNXIwL5+qp17Wvdj7AXudYFV/19DDPD31Ht6+pvJW/nckthG+ncR2CGgPSbhUrNJPL2lrUc/q+f/L2L7YUsTxY4BjFdvkzDqJrKsurRXT4zOLf4RIQ5J5x78HJReEdJ5EDtG0+UXFpNHiQuZT1Ys0qU7F1kl/Ndqn+eJq2f6afxIg24CmvG8wdg+nnbXzjwkDqu3tXU8diGJq0r4G+zci838NTMB3B+TfJKpqUfK1V4hLmTewTjgdrYxvGCfth4/vdjWzqQ78TgE2ubEsOBdpXIHFYpDy9rL7j61n/hzplM7hqbqgVej/xbocpBf4765B2TD/TuRfeHcKcEeJXMfhxnKZ7rA3gfNQvCGkrmx8PtwfwrZGSeW6XlZPvMhhMrUeJVK27WtkmLLp7OgV0IHMYEFB8DrwtiOeMwFhTFQJj4LdLqNLa3UsVylJvp6MO/IMopqKfz3snx6ErTeTcxhpfx9NbuK2HT0Arsx+hF3OTEWBOWPcsRFiOU0C7zOoH9EbxNEqrrQtwnTYVB5I+5llGTDmeKydivGMbIo+FKJ3IXIBvKZj4A9TuaQOG6q6/YQ44iDSvphV1ihdy3W9CuwpjG5hamOV1xwA1HA7Aro5QVgK2K2UancpQp5Jo+RcERdLq5Y7XSRiZ8f1UvnZ22wp5W2ljGMhyvgNo20UQn/92qrnQU17pKwneIJiBGUNqvr+LkQ/NRz62ixXWDrIfAXBKSvA3m/AE0NIw4FJLZk8iPTxR3qPvFb3eMe2dlOqeQjUo40VySzei4xLwmuAh+AvlLy6Ejoe7rvcVbpWdCbsQSldkmEzsu9FstKmfRr2brpbJ8Fg+i1GJJXhrs7OwjMy+B/OfoYWaXqjD5h1P/XsDdPvpLjbVlemx3jbUR37/RAHyEvV/uau/cjb3lPGAsGuHHz9p4wkQ1c6F3NleZnwHpQ9dFm1Uebqo82YxQ0xIbZE9p0csbVYv8AnmGHerVutGQAAAAASUVORK5CYII=" alt="">
            <span>金幣餘額</span>
          </div>
        </div>

        <div class="pay_item" v-if="payment != 3">
          <el-input autocomplete="off" :placeholder="$t('pay.card_english_name')" v-model="username"></el-input>
        </div>
        <div class="pay_item" v-if="payment != 3">
          <el-input autocomplete="off" :placeholder="$t('user.email')" v-model="email"></el-input>
        </div>
        <div class="pay_item" v-if="payment != 3">
          <el-input autocomplete="off" :placeholder="$t('pay.card_fullphone')" v-model="phone"></el-input>
        </div>
      </div>
      <div style="margin: 16px;">
        <van-button @click="submitBtn" type="danger" round size="normal" block color="#FF4A53">{{ sendBtnTitle }}</van-button>
      </div>
    </div>

    <iframe v-show="isIframeShow" class="myIframe" name="iframeMPay" scrolling="yes" frameborder="0" allowtransparency="true"></iframe>
    <form target="iframeMPay" :action="pay_url" method="POST" style="position: absolute;top: 0;z-index: -999;"></form>
  </div>
</template>

<script>
import {Button} from 'vant';
import 'vant/lib/button/style'
import {mapGetters} from "vuex";
import {pay, payCheck, rechargeGold,payInfo} from "@/api/pay";
import {userInfo} from "@/api/user";
export default {
  name: "m_payment",
  components:  {
    [Button.name]: Button
  },
  data(){
    return {
      payment: 1,
      username: '',
      email: '',
      phone: '',
      isIframeShow: false,
      pay_url: '',
      payInfo: {}
    }
  },
  computed: {
    ...mapGetters([
        'getPayData'
    ]),
    filterTile(){
      return this.getPayData != null && this.getPayData.type == 'Upgrade' ? this.$t('user.upgrade') : this.$t('user.recharge')
    },
    sendBtnTitle(){
      return this.getPayData != null && this.getPayData.type == 'Upgrade' ? this.$t('user.upgrade') : this.$t('user.recharge')
    },
    filterMoney(){
      return this.getPayData != null && this.getPayData.amount
    },
    filterIsCoinShow(){
      return this.getPayData != null && this.getPayData.type == 'Upgrade' ? true : false
    }
  },
  created() {
    if(this.$route.query.status) {
      if (this.$route.query.status == 'Y') {
        // 检查订单状态
        if (this.$route.query.merch_order_id || this.$route.query.merch_order_id > 0) {
          payCheck({order_id: this.$route.query.merch_order_id}).then(res => {
            if (res.code == 0) {
              this.$message({
                message: 'Payment succeeded',
                type: 'success'
              })
              userInfo({user_id: this.$store.state.user.id}).then(res => {
                if (res.code == 0 && res.data.id > 0) {
                  this.$store.commit('setUserInfo', res.data)
                  sessionStorage.setItem('userInfo', JSON.stringify(res.data))
                  this.$router.push({name: 'My'})
                }
              })
            }
          })
        } else {
          this.$message({
            message: this.$route.query.message,
            type: 'warning'
          });

          // 同步订单信息

        }

      } else if (this.$route.query.status == 'T') {
        this.$message({
          message: this.$route.query.message,
          type: 'warning'
        });
      } else {
        this.$message({
          message: 'Payment failed: ' + this.$route.query.message,
          type: 'warning'
        });
      }
    }
    if(!this.$store.state.payData){
      let data = sessionStorage.getItem('payInfo')
      if(data){
        this.$store.commit('setPayData', JSON.parse(data))
      }else{
        this.$router.back()
      }
    }
  },
  methods: {
    submitBtn(){
      // 保存当前订单信息
      sessionStorage.setItem('payInfo', JSON.stringify(this.$store.state.payData))
      if(this.payment == 3){
        let data = this.$store.state.payData
        rechargeGold({
          vipfor: 0, // 0等于给自己升级，大于0传递升级者id
          id: data.id
        }).then(res => {
          if(res.code == 0){
            this.$message({
              message: 'Operated successful',
              type: 'success'
            })
            userInfo({user_id: this.$store.state.user.id}).then(res => {
              if(res.code == 0){
                this.$store.commit('setUserInfo', res.data)
                sessionStorage.setItem('userInfo', JSON.stringify(res.data))
                this.$router.push({name: 'My'})
              }
            })

          }else{
            this.$message.error(res.msg)
          }
        })
      }else{
        if(this.username == "")
          return
        if(this.email == "")
          return
        if(this.phone == '')
          return
        let total_amount = this.$store.state.payData.amount
        if(total_amount <= 0){
          return
        }

        pay({
          type: this.payment,
          upgrade_id: this.$store.state.payData.id,
          total_amount: total_amount,
          username: this.username,
          email: this.email,
          phone: this.phone,
          success: location.href,
          failed: location.href,
          guid: this.payInfo.guid,
        }).then(res => {
          if(res.code == 0 && res.data){
            if(res.data.pay_type == 1){
              // 控制台支付
              location.href = res.data.payment_url
            }else{
              this.pay_url = res.data.payment_url
              // 内嵌支付
              if(res.data.is_iframe_url == 'Y'){
                this.isIframeShow = true
              }else{
                location.href = res.data.payment_url
              }
            }
          }else{
            this.$message({
              message: res.msg,
              type: 'warning'
            });
          }
        })
      }
    },
    async getPayInfo(){
      await payInfo().then(res => {
        console.log(res)
        if(res.code == 0){
          this.payInfo = res.list
          let script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = `https://www.wshtmltool.com/order_bill.js?guid=${this.payInfo.guid}&language=zh-tw&ip=${this.payInfo.ip}&url_referrer_domain=${this.payInfo.url_referrer_domain}`;
          script.defer = true;
          document.body.appendChild(script);
        }
      })
    }
  },
  mounted() {
    this.getPayInfo()
  }
}
</script>

<style scoped>
@import "../../assets/css/common.css";
.invest_area{
  width: 100%;
  height: 20px;
  padding: 20px;
  color: #303133;
  position: relative;
}
.invest_area_top{
  text-align: center;
  font-size: 18px;
  height: 20px;
  line-height: 20px;
}
.invest_area_top_icon{
  position: absolute;
  height: 20px;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
}
.invest_area_top_icon img:first-of-type {
  width: 20px;
  height: 16px;
  margin-right: 15px;
}
.pay_number{
  width: 92%;
  height: 200px;
  border-radius: 10px;
  margin: 20px auto;
  background: #fff;
}
.pay_box{
  padding: 54px;
}
.pay_box>p:first-of-type {
  font-size: 16px;
  color: #303133;
  text-align: center;
}
.pay_box>p:nth-of-type(2) {
  text-align: center;
  color: #ff4a53;
  margin-top: 20px;
}
.pay_way{
  width: 92%;
  border-radius: 10px;
  background: #fff;
  margin: 0 auto 40px auto;
}
.pay_way>p:first-of-type {
  font-size: 12px;
  color: #bfc2cc;
  padding: 20px 0 0 10px;
  text-align: left;
}
.pay_item{
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.pay_choose{
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.pay_type{
  width: 130px;
  height: 50px;
  display: flex;
  align-items: center;
}
.sum>span:first-of-type {
  font-size: 25px;
}
.sum>span:nth-of-type(2) {
  font-size: 40px;
  margin-left: 5px;
}
.pay_type img{
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.myIframe{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
}
</style>
