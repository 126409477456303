<template>
<div>
  <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
  >
    <div
        class="flexbox-s trenditem"
        v-for="item in trendList"
        :key="item.id"
    >
      <div class="flexbox userinfo">
        <a @touchstart="toDetail(item.user_id)" class="flexbox useravatar">
          <img class="avatar" :src="item.header_img" >
        </a>
        <div class="flexbox-s namebox">
          <a @touchstart="toDetail(item.user_id)" class="username">
            <i v-show="item.viptype == 2" class="iconfont icon-zuanshihuiyuan"  style="color: silver;"></i>
            <i v-show="item.viptype == 1" class="iconfont icon-V"  style="color: gold;"></i>
            {{item.username}}
          </a>
          <div class="flexbox sexandage">
            <i v-show="item.sex == 1" class="el-icon-male" style="color: rgb(153, 153, 255); font-size: 18px;"></i>
            <i v-show="item.sex == 0" class="el-icon-female" style="color: rgb(255, 68, 170); font-size: 18px;"></i>
            <div class="age">{{item.age}}</div>
          </div>
        </div>
        <div class="chatbox">
        </div>
      </div>
      <div class="flexbox trendcontent">
        <div class="flexbox-s textsector">
          <div>{{item.event == 0 ? $t('user.joined_our_site') : (item.event == 1 ? $t('user.modified_his_avatar') :  $t('user.newsfeed'))}}</div>
        </div>
        <div class="flexbox-s imagesector">
            <div v-if="item.type == 0 && item.event == 2" v-html="item.content">
              {{item.content}}
            </div>
            <div v-else-if="item.type == 1" class="img">
              <el-image
                  :src="item.path"
                  :preview-src-list="[item.path]">
              </el-image>
            </div>
            <div v-if="item.type == 2">
              <video :src="item.path" controls="controls" style="width: 100%;border-radius: 4px;"></video>
            </div>
        </div>

        <div class="comment-box" v-if="item.comment.length > 0">
          <div
              class="flexbox-s trenditem comment-box-line"
              v-for="co in item.comment"
              :key="co.id"
              style="width: 95%;"
          >
            <div  class="flexbox userinfo" style="height: 34px;">
              <a  class="flexbox useravatar" style="width: 32px; height: 32px;"><img style="width: 100%;height: 100%;"  :src="co.header_img" class="avatar"></a>
              <div  class="flexbox-s namebox">
                <a  class="username" style="font-weight: normal;">
                  <i v-show="co.viptype == 2" class="iconfont icon-zuanshihuiyuan"  style="color: silver;"></i>
                  <i v-show="co.viptype == 1" class="iconfont icon-V"  style="color: gold;"></i>
                  {{co.username}}
                  <i v-show="co.sex == 1" class="el-icon-male" style="color: rgb(153, 153, 255); font-size: 18px;"></i>
                  <i v-show="co.sex == 0" class="el-icon-female" style="color: rgb(255, 68, 170); font-size: 18px;"></i>
                  <span class="age">{{co.age}}</span>
                </a>
              </div>
            </div>
            <div  class="flexbox trendcontent">
              <div  class="flexbox-s textsector" style="margin-top: 10px;">
                <div v-html="co.content">{{ co.content }}</div>
              </div>
          </div>
          </div>
        </div>

      </div>
      <div class="flexbox trendtime">{{ item.timeFormat }}</div>
    </div>
  </van-list>
  <m-footer />
</div>
</template>

<script>
import { List } from 'vant'
import 'vant/lib/list/style'
import {trend} from "@/api";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/zh-cn";
import "dayjs/locale/zh-tw";
import "dayjs/locale/en";
dayjs.extend(relativeTime);
import {mapGetters} from "vuex"
import MFooter from "@/components/MFooter"
export default {
  name: "newsfeed_m",
  components: {
    [List.name]: List,
    MFooter
  },
  data() {
    return {
      trendList: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,
      dayjsObj: dayjs
    }
  },
  computed: {
    ...mapGetters([
      'getLanguage',
    ]),
  },
  watch: {
    getLanguage: {
      handler(newV){
        this.changeLang(newV)
      },
      immediate: true
    },
  },
  methods: {
    toDetail(id){
      this.$router.push({name: 'MDetail', query: {id: id}})
    },
    changeTrendLang(){
      let list = this.trendList
      this.trendList = []
      for (const i in list) {
        let item = list[i]
        item.timeFormat = this.dayjsObj.unix(list[i].create_time).fromNow()
        this.trendList.push(item)
      }
    },
    changeLang(lang) {
      if(lang == 'cn'){
        this.dayjsObj.locale('zh-cn')
      }else if(lang == 'tn'){
        this.dayjsObj.locale('zh-tw')
      }else{
        this.dayjsObj.locale('en')
      }

      this.changeTrendLang()
    },
    onLoad() {
      this.getTrend()
    },
    getTrend(){
      console.log(this.loading)
      const that = this
      trend({page: this.page, limit: this.limit}).then(res => {
        that.loading = false
        console.log(this.loading)
        if(res.code == 0){
          if(res.list.length < 1){
            that.finished = true
            return
          }
          this.page += 1
          for (const i in res.list) {
            let item = res.list[i]
            if(item.content){
              item.content = item.content.replace(/\[(face\d+)\]/g, '<img src="img/$1.gif" style="width: 24px;height: 24px;vertical-align: middle;">')
            }

            if(item.comment.length > 0){
              for (const co in item.comment) {
                if(item.comment[co].content)
                  item.comment[co].content = item.comment[co].content.replace(/\[(face\d+)\]/g, '<img src="img/$1.gif" style="width: 24px;height: 24px;vertical-align: middle;">')
              }
            }

            item.timeFormat = that.dayjsObj.unix(res.list[i].create_time).fromNow()
            that.trendList.push(item)
          }
        }
      })
    },
  },
}
</script>

<style scoped>
@import "../assets/css/common.css";
.trenditem{
  padding: 10px;
  border-bottom: 1px solid #eee;
}
.trenditem .userinfo{
  height: 70px;
  flex-wrap: nowrap;
}
.trenditem .userinfo .useravatar{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
}
.trenditem .userinfo .useravatar .avatar{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.trenditem .userinfo .namebox{
  padding-left: 10px;
}
.trenditem .userinfo .namebox .username{
  width: 100%;
  color: #333;
  padding: 0 5px;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 5px;
  text-align: left;
}
.trenditem .userinfo .namebox .sexandage {
  width: auto;
  font-size: 14px;
  background-color: #f1f2f3;
  padding: 2px 8px;
  height: 20px;
  border-radius: 10px;
}
.trenditem .userinfo .namebox .sexandage .age{
  padding-left: 5px;
}
.trenditem .trendcontent{
  padding: 0 5px;
  color: #333;
}
.trenditem .trendcontent .textsector{
  margin-bottom: 10px;
}
.trenditem .trendcontent .imagesector{
  padding: 0 10px;
  margin-bottom: 10px;
}
.trenditem .trendcontent .imagesector .img{
  width: 80%;
  height: auto;
}
.trenditem .trendtime{
  color: #aaa;
  width: auto;
  height: 30px;
  align-items: flex-end;
  align-content: flex-end;
  flex-shrink: 0;
  font-size: 12px;
}

.flexbox-s.trenditem.comment-box-line {
  /* border-bottom: 1px solid #b08c8c; */
  margin-left: 10px;
  margin-bottom: 8px;
  background-color: #f5f7fa;
  border-radius: 5px;
  padding: 10px 10px 0px 10px;
}
</style>
