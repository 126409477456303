<template>
  <div class="globalchatwindow" v-if="isfiexdShow">
    <div class="chatlistfixedwindow" :style="'bottom: '+ bottomHeight +'px;'">
      <div @click="toogle" class="flexbox chatlistfixedwindowheader" style="cursor: pointer;">
        <div>
        </div>
        <div>{{ $t('chat.chat_list') }}[{{filterContacts.length}}]</div>
        <div>
          <i v-show="bottomHeight == 370" class="el-icon-arrow-down close" style="">
          </i>
        </div>
      </div>
      <div class="flexbox-c chatlistfixedwindowchatlist">
        <div class="searchbox">
          <input class="searchinput" type="text" placeholder="搜索" v-model="search">
        </div>
        <el-scrollbar style="height: 100%;">
          <div class="flexbox" style="padding-top: 10px;">
            <div
                v-for="item in filterContacts"
                :key="item.id"
                class="flexbox chatlistitem"
                style="background-color: rgba(255, 255, 255, 0.2);"
                @click="openUser(item)"
            >
              <template v-if="item.count > 0">
                <el-badge :value="item.count" class="item">
                  <router-link :to="{name: 'DetailPc', query: {id: item.id}}" class="userheader">
                    <img :src="item.avatar">
                    <i v-show="item.vip == 2" class="iconfont icon-zuanshihuiyuan vip"  style="color: silver;"></i>
                    <i v-show="item.vip == 1" class="iconfont icon-V vip"  style="color: silver;"></i>
                  </router-link>
                </el-badge>
              </template>
              <template v-else>
                  <router-link :to="{name: 'DetailPc', query: {id: item.id}}" class="userheader">
                    <img :src="item.avatar">
                    <i v-show="item.vip == 2" class="iconfont icon-zuanshihuiyuan vip"  style="color: silver;"></i>
                    <i v-show="item.vip == 1" class="iconfont icon-V vip"  style="color: silver;"></i>
                  </router-link>
              </template>

              <div class="flexbox chatcontent">
                <div class="name">
                    <span class="online" style="background-color: rgb(153, 153, 153);"></span> {{item.username}}</div>
                <div class="time">{{ item.timeFormat }}</div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>

    <ChatWindow
      v-for="item in openUsers"
      :key="item.id"
      :user="item"
      :removeUser="removeUser"
    />


    <div class="hidenchatwindow" v-show="getHiddenUsers.length > 0">
      <el-dropdown
          trigger="click"
          @command="changeChatUser"
      >
        <div class="flexbox chat">
          <i class="iconfont icon-liaotian" ></i> {{getHiddenUsers.length}}
        </div>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="user in getHiddenUsers"
            :key="user.id"
            :command="user"
          >
            {{user.username}}
          </el-dropdown-item>

        </el-dropdown-menu>
      </el-dropdown>
    </div>

  </div>
</template>
<script>
import ChatWindow from './ChatWindow.vue'
import {mapGetters} from 'vuex'
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/zh-cn";
import "dayjs/locale/zh-tw";
import "dayjs/locale/en";
dayjs.extend(relativeTime);
export default {
  name: 'ChatFixed',
  components: {ChatWindow},
  data(){
    return {
      isfiexdShow: false,
      search: '',
      openUsers:[],
      currentLang: 'tn',
      dayjsObj: dayjs,
      windowWidth: document.body.clientWidth,
      openUserList: [] // 已经打开的窗口
    }
  },
  watch: {
    windowWidth: {
      handler(v){
        this.changeChatWindow(v)
      },
      immediate: false
    },
    getChatWindowOpen: {
      handler(v){
        this.openUserList = []
        for (const item in v) {
          this.openUserList.push(item.split('_')[1])
        }
      },
      immediate: true
    },
    openUserList: {
      handler(v){
        let contacts = this.getContacts.filter(u => u.id == v.find(id => id == u.id))
        for (const contactsKey in contacts) {
          this.openUser(contacts[contactsKey])
        }
      },
      immediate: true
    },
    getLanguage: {
      handler(newV){
        this.currentLang = newV
        this.changeLang(newV)
      },
      immediate: true
    },
  },
  computed: {
    ...mapGetters([
      'getContacts',
      'getChatFixedOpen',
      'getChatWindowOpen',
      'getHiddenUsers',
      'getLanguage',
    ]),
    filterContacts(){
      this.changeLang(this.currentLang)
      return this.getContacts.filter(u => u.username.includes(this.search))
    },
    bottomHeight(){
      return this.getChatFixedOpen ? 370 : 0
    }
  },
  methods: {
    changeLang(lang) {
      if(lang == 'cn'){
        this.dayjsObj.locale('zh-cn')
      }else if(lang == 'tn'){
        this.dayjsObj.locale('zh-tw')
      }else{
        this.dayjsObj.locale('en')
      }

      this.changeMsgTimeLang()
    },
    openUser(user){
      let hiddenUsers = this.getHiddenUsers.length > 0 ? JSON.parse(JSON.stringify(this.getHiddenUsers)) : []
      if(this.openUsers.find(u => u.id == user.id) || (hiddenUsers.length > 0 && hiddenUsers.find(u => u.id == user.id))){
        return
      }
      let width = this.openUsers.length * 290 + 250
      let bodyWidth = this.windowWidth - 70
      if((bodyWidth - width) >= 290){
        this.openUsers.push(user)
        this.addChatWindowState(user)
      }else{
        hiddenUsers.push(user)
        this.$store.commit('setHiddenUsers', hiddenUsers)
      }
    },
    removeUser(id){
      this.openUsers = this.openUsers.filter(u => u.id != id)
      this.changeChatWindow(this.windowWidth, id)
    },
    toogle(){
      this.$store.commit('setChatFixedOpen', !this.getChatFixedOpen)
    },
    changeChatUser(user){
      let popOpenUser = this.openUsers.pop()
      let hiddenUsers = this.getHiddenUsers.length > 0 ? JSON.parse(JSON.stringify(this.getHiddenUsers)) : []
      hiddenUsers = hiddenUsers.filter(u => u.id != user.id)
      this.$store.commit('setHiddenUsers', hiddenUsers)
      this.openUsers.push(user)
      this.changeChatWindowState(user, popOpenUser)
    },
    changeChatWindowState(add, remove){
      let openList = {}
      for (const openListKey in this.getChatWindowOpen) {
        if(('id_' + remove.id) != openListKey){
          openList[openListKey] = this.getChatWindowOpen[openListKey]
        }
      }

      openList['id_' + add.id] = true
      this.$store.commit('setChatWindowOpen', openList)
    },
    addChatWindowState(user){
      let openList = this.getChatWindowOpen
      if(typeof(openList['id_' + user.id]) == 'undefined'){
        openList['id_' + user.id] = true
      }
      this.$store.commit('setChatWindowOpen', openList)
    },
    removeChatWindowState(user){
      let openList = {}
      for (const openListKey in this.getChatWindowOpen) {
        if(('id_' + user.id) != openListKey){
          openList[openListKey] = this.getChatWindowOpen[openListKey]
        }
      }
      this.$store.commit('setChatWindowOpen', openList)
    },
    changeChatWindow(v, id = 0){
      let width = this.openUsers.length * 290 + 250
      let bodyWidth = v - 70
      if((bodyWidth - width) >= 290){
        let removeUser = null
        if(id > 0){
          removeUser = this.getContacts.find(u => u.id == id)
        }
        if(this.getHiddenUsers.length > 0){
          let hiddenUsers = this.getHiddenUsers.length > 0 ? JSON.parse(JSON.stringify(this.getHiddenUsers)) : []
          let hiUser = hiddenUsers.shift()
          this.$store.commit('setHiddenUsers', hiddenUsers)
          this.openUsers.push(hiUser)
          if(id > 0){
            this.changeChatWindowState(hiUser, removeUser)
          }else{
            this.addChatWindowState(hiUser)
          }
        }else{
          if(id > 0)
            this.removeChatWindowState(removeUser)
        }
      }else if((bodyWidth - width) <= 0){
        if(this.openUsers.length > 0){
          let hiddenUsers = this.getHiddenUsers.length > 0 ? JSON.parse(JSON.stringify(this.getHiddenUsers)) : []
          let opUser = this.openUsers.pop()
          hiddenUsers.push(opUser)
          this.$store.commit('setHiddenUsers', hiddenUsers)
          this.removeChatWindowState(opUser)
        }
      }
    },
    changeMsgTimeLang(){
      let list = []
      for (const i in this.getContacts) {
        let item = this.getContacts[i]
        item.timeFormat = this.dayjsObj.unix(this.getContacts[i].msg_time).fromNow()
        list.push(item)
      }

      this.$store.commit('setContacts', list)
    },
  },
  mounted() {
    let that = this;
    window.onresize =() =>{
      return (()=>{
        window.clientWidth = document.body.clientWidth
        that.windowWidth = window.clientWidth
      })()
    }
  }
}
</script>
<style scoped>
@import "../assets/css/common.css";
.globalchatwindow{
  max-width: 100%;
  height: 30px;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row-reverse;
  z-index: 2;
}
.chatlistfixedwindow {
  position: relative;
  width: 300px;
  height: 400px;
  transition: bottom .1s;
  font-size: 12px;
  background-color: #eee;
  border-radius: 4px 4px 0 0;
}
.chatlistfixedwindow .chatlistfixedwindowheader{
  height: 30px;
  justify-content: space-between;
  padding: 0 10px;
}
.chatlistfixedwindow .chatlistfixedwindowchatlist{
  flex-direction: column;
  width: 300px;
  height: 370px;
  border-right: 1px solid hsla(0,0%,100%,.3);
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.chatlistfixedwindow .chatlistfixedwindowchatlist .searchbox{
  width: 100%;
  height: 22px;
  border-bottom: 1px solid #999;
  border-top: 1px solid #999;
  flex-shrink: 0;
}
.chatlistfixedwindow .chatlistfixedwindowchatlist .searchbox .searchinput{
  border: none;
  outline: none;
  font-size: 12px;
  width: 100%;
  height: 100%;
  padding: 10px;
}
.chatlistfixedwindow .chatlistfixedwindowchatlist .chatlistitem{
  width: 100%;
  height: 40px;
  flex-wrap: nowrap;
  cursor: pointer;
  justify-content: flex-start;
  padding: 0 10px;
}
.chatlistfixedwindow .chatlistfixedwindowchatlist .chatlistitem .chatcontent{
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  justify-content: space-between;
}
.chatlistfixedwindow .chatlistfixedwindowchatlist .chatlistitem .userheader{
  width: 30px;
  height: 30px;
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
}
.chatlistfixedwindow .chatlistfixedwindowchatlist .chatlistitem .userheader img{
  width: 30px;
  height: 30px;
}
.chatlistfixedwindow .chatlistfixedwindowchatlist .chatlistitem .chatcontent{
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  justify-content: space-between;
}
.chatlistfixedwindow .chatlistfixedwindowchatlist .chatlistitem .chatcontent .name{
  color: #333;
}
.chatlistfixedwindow .chatlistfixedwindowchatlist .chatlistitem .chatcontent .name .online{
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
.chatlistfixedwindow .chatlistfixedwindowchatlist .chatlistitem .chatcontent .time{
  color: #999;
}
/deep/ .el-scrollbar__wrap {
  margin-right: 0px;
  margin-bottom: 0px;
}
.chatlistfixedwindow .chatlistfixedwindowchatlist .chatlistitem .userheader .vip{
  position: absolute;
  right: 0;
  bottom: 0;
  color: gold;
  font-size: 12px;
}
.hidenchatwindow{
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  padding-right: 10px;
}
.el-dropdown {
  color: #000!important;
}
.hidenchatwindow .chat{
  width: 60px;
  height: 30px;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  font-size: 12px;
}
.el-dropdown .el-dropdown-selfdefine {
  outline: 0;
}
.hidenchatwindow .chat i{
  font-size: 20px;
  margin-right: 10px;
}

.el-dropdown-menu {
  background-color: #8f75a2!important;
  padding: 0;
  border: none;
}
li.el-dropdown-menu__item:hover{
  background-color: #e8cbed !important;
}
.el-dropdown-menu__item {
  color: #333!important;
}
.icon-V{
  color: gold !important;
}
.icon-zuanshihuiyuan{
  color: #409eff !important;
}
</style>
