export default{
    user: {
        username: '用户名',
        password: '密码',
        login: '登录',
        register: '注册',
        register2: '立即注册~~',
        plogin: '请登录',
        remember_me: '記住我',
        about_us: '关于我们',
        terms_of_use: '服务条款',
        privacy_policy: '隐私政策',
        help_center: '帮助中心',
        contact_us: '联系我们',
        join_our: '加入我们的社区',
        join_our2: '加入我们这个富有激情的社区。找到新朋友,约会,分享照片和视频。 嗨起来!',
        register_profile: '请注册您的个人资料',
        basic: '基本资料',
        email: '邮箱',
        line: '赖ID',
        sex: '性别',
        male: '男',
        female: '女',
        birthday: '生日',
        i_agree_with_it: '我同意',
        join: '加入',
        should_contain_only_letters_and_digits: '必须包含字母和数字',
        should_be_valid: '必须是有效的',
        please_enter: '请输入',
        my_profile: '我的个人主页',
        profile_edit: '资料编辑',
        my_preferences: '个性化设置',
        mail_notifications: '数据标签',
        logout: '退出',
        main: '首页',
        member: '会员',
        chat : '聊天',
        recharge : '充值',
        upgrade : '升级',
        store : '商店',
        members : '会员',
        my_credits : '我的账号',
        users : '社区用户',
        newsfeed : '最新动态',
        balance : '余额',
        coin_balance : '金币余额',
        buy_more : '充值',
        latest : '最新',
        online : '在线',
        all : '查看全部',
        joined_our_site: '加入了我们的网站。',
        modified_his_avatar: '修改了他的头像。',
        age: '岁',
        active: '活跃',
        follow: '关注',
        followed: '取消关注',
        send_gift: '发送礼物',
        send: '发送',
        hours_ago: '小时前',
        chat_btn: '聊天',
        photo_btn: '照片',
        browse_user: '浏览用户',
        whole: ' 全部',
        search: '搜索',
        whats_happening: '聊一聊~',
        add_signature: "你的个性签名",
        modify_btn: '修改',
        modify_avatar: "修改头像",
        user_avatar: "用户头像",
        change_password: '修改密码',
        edit_infomation: '资料编辑',
        save: '保存',
        cancel: '取消',
        virtual_gifts: '礼物',
        me_received: '我收到的礼物',
        like: '喜欢',
        mine: '我的',
        delBtn: '删除',
        membership_privileges: '会员特权',
        choose_from_album: '从相册中选择',
        my_head: '我的头像',
        data_setting: '资料设定',
        personal_signature: '个性签名',
        language: '语言',
        state: '国家',
        more: '查看更多',
        comments: '的评论',
        free: '非会员',
        expires: '到期',
        permission: '您沒有权限',
        search_by_name: '用户名搜索',
        notifications : '通知 ',
    },
    pay: {
        recharge_for_self: '为自己升级',
        crown_card: '皇冠会员',
        diamond_card: '钻石会员',
        allow_profile_wall_posts: '允许个人资料上墙帖',
        search_users: '搜索用户',
        view_profiles: '查看個人资料',
        auth_message: '聊天发图片免费',
        allow_comments_on_newsfeed: '允许在动态下面评论',
        dynamic_unrestricted: '无免费条数限制',
        allows_newsfeed_to_be_ent: '允许发动态',
        upload_photo: '上传照片',
        view_photo: '查看图片',
        comment_photo: '评论图片',
        add_video: '添加视频',
        view_video: '查看视频',
        comment_video: '评论视频',
        start_mail_dialog: '启用邮件对话框',
        continue_mail_dialog: '继续邮件对话框',
        read_chat_messages: '阅读聊天消息',
        send_chat_messages_for_free: '免费发送聊天消息',
        start_chat_dialog: '启用聊天輸入框',
        read_mails: '阅读邮件',
        continue_chat_dialog: '继续聊天对话框',
        free_use_of_the_translation_function: '免费使用翻译',
        month1: '月',
        province: '优惠',
        coin: '金币',
        total_price: '总价',
        card_username: '持卡人名字',
        card_english_name: `请填写英文姓名`,
        card_fullphone: '电话',
        account: '账户',
        remaining_quantity: '剩余数量',
        select_recharge_type: '选择充值类型',
    },
    store: {
        love_space: '爱情空间',
        lovers_space: 'LOVERS空间',
        goddess_space: '女神空间',
        lovers: '情趣空间',
        leave_a_message: '赠送留言',
        gift_name: '礼物名称',
        gift_description: '礼物说明',
        price: '售价',
        send: '赠送',
        gift_box: '礼物盒子',
        send_gift_to: '赠送礼物給',
        your_balance_is_not_enough: '余额不足',
    },
    chat: {
        upgrade_msg: `您沒有权限, 需要升级~`,
        send_failed: `发送失败`,
        view_history: `查看历史消息`,
        msg: `消息`,
        chats: '聊天',
        customer: '客服',
        chat_list: '会话列表',
    }
}
