<template>
  <div class="flexbox-c home">
    <Head />

    <div class="flexbox-c homecontent">
      <div class="container" v-if="userInfo">
        <div class="title">{{ userInfo.username }}</div>

        <div class="flexbox-s usercontent" >
          <div class="leftcontent" >
            <div class="flexbox fullradiusbox userheader" >
              <img :src="userInfo.header_img" >
              <img v-show="userInfo.viptype == 1" class="vip" src="/img/king.png" >
              <img v-show="userInfo.viptype == 2" class="vip" src="/img/dimand.png" >
              <div :class="userInfo.is_online > 0 ? 'online' :'offline'"></div>
              <div class="editbtn" @click="imagecropperShow = true">{{$t('user.modify_avatar')}}</div>
            </div>
            <div class="usersign">
              <el-input type="textarea" :rows="5" v-model="signatureInput" :placeholder="$t('user.add_signature')" style="min-height: 33px;"></el-input>
              <div class="flexbox btncontainer" v-show="!signatureBtn">
                <div class="flexbox commonbtn" @click="changeSignature">
                  <div class="textbox">{{ $t('user.modify_btn') }}</div>|
                  <div class="iconbox">
                    <i class="el-icon-circle-plus-outline" style="font-size: 16px;"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="rightcontent" >
            <div class="flexbox usereditcontainer">
              <a href="#/member/index/user/edit" class="flexbox commonbtn">
                <div class="textbox">{{ $t('user.profile_edit') }}</div>|
                <div class="iconbox">
                  <i class="el-icon-circle-plus-outline"></i>
                </div>
              </a>
            </div>
            <div class="flexbox usermsgbox" >
              <div class="flexbox item" >
                <div class="flexbox left" >{{ $t('user.username') }}</div>
                <div class="flexbox right" >{{ userInfo.username }}</div>
              </div>
              <div class="flexbox item" >
                <div class="flexbox left" >{{ $t('user.sex') }}</div>
                <div class="flexbox right" >{{ userInfo.sex == 1 ? $t('user.male') : $t('user.female') }}</div>
              </div>
            </div>
            <div class="flexbox trendbox" >
              <div class="flexbox halfradiusbox" >
                <div class="flexbox title" >
                  <i class="iconfont icon-dongtai2" ></i>{{$t('user.newsfeed')}}
                </div>
              </div>
              <div class="flexbox inputbox">
                <el-input type="textarea" v-model="input" :placeholder="$t('user.whats_happening')" style="min-height: 33px;"></el-input>
              </div>
              <div class="flexbox opratebox" >
                <div class="flexbox iconbox" v-if="false">
                  <i class="el-icon-video-camera-solid" @click="uploadVideo"  style="font-size: 20px;"></i>
                </div>
                <input type="file" ref="videoInput"  @change="inputFileChange" accept="video/*"  style="display: none;">
                <div class="flexbox iconbox" >
                  <i class="iconfont icon-tupian" @click="uploadImg"  style="font-size: 20px;"></i>
                </div>
                <input type="file" ref="imgInput"  @change="inputFileChange" accept="image/*"  style="display: none;">
                <div class="flexbox iconbox" @click="openBiaoQing">
                  <i class="iconfont icon-biaoqing2"  style="color: rgb(238, 221, 85); font-size: 20px;">
                  </i>
                </div>
                <div class="flexbox sendbtn" @click="sendInput">{{ $t('user.send') }}</div>
              </div>
              <div class="flexbox expressbox" v-show="is_bq_show">
                <img
                    v-for="i in 108" :key="i"
                    class="img"
                    :src="'img/face' + i + '.gif'"
                    @click="addEmoji(i)"
                >
              </div>
              <div class="flexbox trendimgprev" v-show="isPreview" :style="isImgOrVideo ? 'background-image: url(' + uploadImgRes + ');' : ''">
                <video v-show="isImgOrVideo" :src="uploadImgRes" controls="controls" style="width: 412px;"></video>
                <i class="el-icon-close" @click="clearImg" style="cursor: pointer;"></i>
              </div>
              <div class="flexbox-s trendlist" >
                <div
                    class="flexbox-s trenditem"
                    v-for="item in trendList"
                    :key="item.id"
                >
                  <a class="flexbox useravatar" >
                    <img :src="item.header_img" >
                    <img v-show="item.viptype == 1" class="vip" src="/img/king.png" >
                    <img v-show="item.viptype == 2" class="vip" src="/img/dimand.png" >
                  </a>
                  <div class="flexbox trendcontent" >
                    <div class="flexbox-s textsector" >
                      <a class="username" >{{ item.username }}</a>
                      <div>{{item.event == 0 ? $t('user.joined_our_site') : (item.event == 1 ? $t('user.modified_his_avatar') :  $t('user.newsfeed'))}}</div>
                    </div>
                    <div class="flexbox-s imagesector" >
                      <div class="img">
                          <template v-if="item.type == 0 && item.event == 2">
                            <div v-html="item.content">
                              {{item.content}}
                            </div>
                          </template>
                          <template v-else-if="item.type == 1">
                            <el-image
                                :src="item.path"
                                :preview-src-list="[item.path]">
                            </el-image>
                          </template>
                          <template v-if="item.type == 2">
                            <video :src="item.path" controls="controls" style="width: 412px;"></video>
                          </template>
                        </div>
                      </div>

                    <div class="comment-box" v-if="item.comment.length > 0">
                      <div
                          class="flexbox-s trenditem comment-box-line"
                          v-for="co in item.comment"
                          :key="co.id"
                      >
                        <a class="flexbox useravatar">
                          <img :src="co.header_img">
                          <img v-show="co.viptype == 1" class="vip" src="/img/king.png" >
                          <img v-show="co.viptype == 2" class="vip" src="/img/dimand.png" >
                        </a>
                        <div class="flexbox trendcontent">
                          <div class="flexbox-s textsector">
                            <a class="username">{{ co.username }}</a>
                            <div >{{ $t('user.comments') }}</div>
                          </div>
                          <div class="flexbox-s imagesector" v-html="co.content">
                            {{ co.content }}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="flexbox trendtime" >
<!--                    <div class="report" >举报</div>-->
                    <div class="time" >{{ item.timeFormat }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>


    <Footer />
    <ChatFixed ref="chatFixed" />
    <my-upload
      :modelValue.sync="imagecropperShow"
      :key="uploadLang"
      :lang-type="uploadLang"
      :headers="uploadHeader"
      img-format="png"
      @crop-upload-success="cropUploadSuccess"
      @crop-upload-fail="cropUploadFail"
      url="https://api.meet-fate.com/upload/img"
      noCircle
      :withCredentials="false"
      field="img"
      :width="300"
      :height="300"
    />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Head from '@/components/Head.vue'
import ChatFixed from '@/components/ChatFixed.vue'
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/zh-cn";
import "dayjs/locale/zh-tw";
import "dayjs/locale/en";
dayjs.extend(relativeTime);
import {like, userInfo, updateAvatar, signature,uploadImg} from '@/api/user'
import {trend, sendTrend} from '@/api/index'
import {mapGetters} from "vuex";
import myUpload from "vue-image-crop-upload";

export default {
  components: {Footer, Head, ChatFixed, myUpload},
  data() {
    return {
      isImgOrVideo: false,
      isPreview: false,
      uploadImgRes: '',
      uploadHeader: {
        'token': sessionStorage.getItem('token'),
        // 'Content-Type': 'application/x-www-form-urlencoded;',
        'Accept': '*/*'
      },
      imagecropperShow: false,
      userId: 0,
      userInfo: null,
      trendList: [],
      dayjsObj: dayjs,
      input: '',
      is_bq_show: false,
      signatureInput: '',
      signatureBtn: false,
      uploadLang: 'zh-tw'
    }
  },
  computed:{
    ...mapGetters([
      'getLanguage'
    ]),
  },
  watch: {
    getLanguage: {
      handler(newV){
        this.changeLang(newV)
      },
      immediate: true
    }
  },
  created() {
    this.userId = this.$route.query.id
    this.userInfo = this.$store.state.user
    this.signatureInput = this.userInfo.signature
    // this.getUserInfo(this.userId)
    this.getTrend(this.userId, 1, 30)
  },
  methods: {
    inputFileChange(e){
      let file = e.target.files[0]
      console.log(file.type.split('/')[0])
      if(file.type.split('/')[0] == 'video'){
        this.isImgOrVideo = true
      }
      let formData = new FormData()
      formData.set('img', file)
      uploadImg(formData).then(res => {
        if(res.code == 0){
          this.uploadImgRes = res.list[0]
          this.isPreview = true;
        }else{
          this.$message.error('Operated failed')
        }
      })
    },
    clearImg(){
      this.isPreview = false;
      this.uploadImgRes = ''
      this.isImgOrVideo = false
    },
    uploadVideo(){
      this.$refs.videoInput.click()
    },
    uploadImg(){
      this.$refs.imgInput.click()
      // this.isPreview = true;
    },
    sendInput(){
      if(this.input || this.uploadImgRes){
        let type = this.isImgOrVideo ? 2 : (this.uploadImgRes ? 1 : 0)
        let event = 2
        let path = this.uploadImgRes
        sendTrend({str: this.input, type: type, event: event, path: path}).then(res => {
          if(res.code == 0){
            this.$message({
              message: 'Operated successful',
              type: 'success'
            })
            this.input = ''
            this.uploadImgRes = ''
            this.isPreview = false
            this.isImgOrVideo = false
            this.getTrend(this.userId, 1, 30)
          }else{
            this.$message.error('Operated failed')
          }
        })
      }
    },
    cropUploadSuccess( jsonData){
      updateAvatar({avatar: jsonData.list[0]}).then(res => {
        if(res.code == 0){
          this.$set(this.userInfo, 'header_img', jsonData.list[0])
          this.$store.commit('setUserInfo', this.userInfo)
          sessionStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.imagecropperShow = false
        }
      })
    },
    cropUploadFail(status, field){
      console.log(status, field)
    },
    changeSignature(){
      if(this.signatureInput){
        signature({str: this.signatureInput}).then(res => {
          if(res.code == 0){
            this.$message({
              message: 'Operated successful',
              type: 'success'
            })
          }else{
            this.$message.error('Operated failed')
          }

        })
      }
    },
    openBiaoQing(){
      this.is_bq_show = !this.is_bq_show
    },
    addEmoji(index){
      this.input += '[face'+ index + ']'
      this.is_bq_show = false
    },
    likeOp(){
      like({like_id: this.userInfo.id, type: this.userInfo.like}).then(res => {
        if(res.code == 0){
          this.$set(this.userInfo, 'like', this.userInfo.like == 1 ? 0 : 1 )
          this.$message({
            message: 'Operated successful',
            type: 'success'
          });
        }
      })
    },
    openChat(){
      let list = this.$store.state.contacts
      list.unshift(this.userInfo)
      this.$store.commit('setContacts', list)
      this.$router.push({name: 'PcChat', query: {id:  this.userInfo.id}})
    },
    getUserInfo(id){
      userInfo({user_id: id}).then(res => {
        if(res.code == 0){
          this.userInfo = res.data
          this.signatureInput = this.userInfo.signature
        }
      })
    },
    getTrend(id, page, limit){
      const that = this
      trend({page: page, limit: limit,user_id: id}).then(res => {
        if(res.code == 0){
          that.trendList = []
          for (const i in res.list) {
            let item = res.list[i]
            if(item.content){
              item.content = item.content.replace(/\[(face\d+)\]/g, '<img src="img/$1.gif" style="width: 24px;height: 24px;vertical-align: middle;">')
            }

            if(item.comment.length > 0){
              for (const co in item.comment) {
                if(item.comment[co].content)
                  item.comment[co].content = item.comment[co].content.replace(/\[(face\d+)\]/g, '<img src="img/$1.gif" style="width: 24px;height: 24px;vertical-align: middle;">')
              }
            }

            item.timeFormat = that.dayjsObj.unix(res.list[i].create_time).fromNow()
            that.trendList.push(item)
          }
        }
      })
    },
    changeTrendLang(){
      let list = this.trendList
      this.trendList = []
      for (const i in list) {
        let item = list[i]
        item.timeFormat = this.dayjsObj.unix(list[i].create_time).fromNow()
        this.trendList.push(item)
      }
    },
    changeLang(lang) {
      if(lang == 'cn'){
        this.dayjsObj.locale('zh-cn')
        this.uploadLang = 'zh'
      }else if(lang == 'tn'){
        this.dayjsObj.locale('zh-tw')
        this.uploadLang = 'zh-tw'
      }else{
        this.dayjsObj.locale('en')
        this.uploadLang = 'en'
      }

      this.changeTrendLang()
    }
  }
}
</script>
<style scoped>
@import "../../assets/css/common.css";
@import "../../assets/css/index.css";
.container{
  width: 1000px;
  padding: 100px 30px;
}
.container .title {
  font-size: 20px;
  color: #513b5a;
  font-weight: 700;
  text-align: left;
}
.container .buttonbox {
  margin-top: 30px;
}
.container .buttonbox .button {
  width: auto;
  padding: 3px 8px;
  color: #fff;
  font-size: 12px;
  background-color: #9c6d97;
  border-radius: 2px;
  border: 1px solid #513b5a;
  cursor: pointer;
  margin-right: 10px;
}
.container .usercontent{
  justify-content: space-between;
  margin-top: 30px;
  font-size: 14px;
}
.container .usercontent .leftcontent{
  width: 215px;
}
.container .usercontent .rightcontent{
  width: 705px;
}
.container .usercontent .leftcontent .userheader{
  position: relative;
  width: 215px;
  height: 215px;
}
.container .usercontent .leftcontent .userheader img{
  width: 175px;
  height: 175px;
}
.container .usercontent .leftcontent .userheader .offline{
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb(170, 170, 170);
}
.container .usercontent .leftcontent .userheader .online{
  position: absolute;
  bottom: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #14e414;
}

.container .usercontent .rightcontent .usermsgbox {
  color: #fff;
}
.container .usercontent .rightcontent .usermsgbox .item {
  height: 30px;
}
.container .usercontent .rightcontent .usermsgbox .item .left{
  width: 30%;
  justify-content: flex-end;
  padding: 0 5px;
  border-right: 1px solid hsla(0,0%,100%,.5);
}
.container .usercontent .rightcontent .usermsgbox .item .right {
  width: 70%;
  justify-content: flex-start;
  padding: 0 5px;
}
.container .usercontent .rightcontent .trendbox {
  margin-top: 30px;
}
.fullradiusbox, .halfradiusbox{
  background: hsla(0,0%,100%,.2) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAgCAYAAADaDrJgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACpJREFUeNpi/P//WRoDGmAB4ifYBF9gE/w3Kkiu4P/Bp/0/DbRTIAgQYAC2PiJjuOjlPgAAAABJRU5ErkJggg==) repeat-x 0 0;
  border: none;
  border-bottom: none;
}
.halfradiusbox{
  border-radius: 6px 6px 0 0;
}
.fullradiusbox .title, .halfradiusbox .title {
  justify-content: flex-start;
  padding: 8px 10px;
  font-size: 16px;
}
.fullradiusbox .title i, .halfradiusbox .title i {
  margin-right: 10px;
  color: #513b5a;
  font-size: 16px;
}
.container .usercontent .rightcontent .inputbox, .container .usercontent .rightcontent .trendlist {
  margin-top: 15px;
}
.container .usercontent .rightcontent .trendlist .trenditem{
  flex-wrap: nowrap;
}
.trenditem {
  flex-wrap: nowrap;
  margin-bottom: 10px;
}
.trenditem .useravatar{
  width: 50px;
  height: 50px;
  background-color: #ad8cad;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
}
.trenditem .useravatar img {
  width: 44px;
  height: 44px;
  border-radius: 3px;
}
.trenditem .trendcontent{
  padding: 0 5px;
  color: #fff;
}
.trenditem .trendcontent .textsector {
  margin-bottom: 10px;
}
.trenditem .trendcontent .textsector .username{
  color: #7ce6fe;
  padding: 0 5px;
  font-weight: 700;
  cursor: pointer;
}
.trenditem .trendcontent .imagesector{
  padding: 0 10px;
  margin-bottom: 10px;
}
.trenditem .trendcontent .imagesector .img {
  max-width: 200px;
}
.container .usercontent .leftcontent .userheader .vip {
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 30px;
  width: 30px;
  height: 30px;
}
.trenditem .useravatar .vip{
  position: absolute;
  right: 2px;
  bottom: 0;
  font-size: 20px;
  width: 18px;
  height: 18px;
}
.container .usercontent .rightcontent .opratebox {
  height: 30px;
  justify-content: flex-end;
}
.container .usercontent .rightcontent .opratebox .iconbox {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-right: 10px;
}
.container .usercontent .rightcontent .opratebox .sendbtn{
  width: auto;
  height: 30px;
  padding: 0 8px;
  background-color: #513b5a;
  border-radius: 3px;
  border: 1px solid #333;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}
.container .usercontent .rightcontent .expressbox{
  width: 100%;
  height: auto;
  background-color: #fff;
  justify-content: flex-start;
  align-content: flex-start;
  overflow: hidden;
}
.container .usercontent .rightcontent .expressbox img{
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.trenditem .trendtime{
  color: #513b5a;
  width: auto;
  min-height: 50px;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  flex-shrink: 0;
}
.container .usercontent .leftcontent .usersign{
  width: 215px;
  margin-top: 30px;
  background-color: #fff;
  padding: 5px;
  border-radius: 6px;
}
.container .usercontent .leftcontent .usersign .btncontainer{
  margin-top: 20px;
}
.commonbtn{
  width: auto;
  height: 32px;
  justify-content: space-between;
  color: #513b5a;
  background-color: #e8cbed;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}
.commonbtn .textbox{
  padding: 0 5px;
}
.commonbtn .iconbox{
  padding: 0 5px;
  font-size: 20px;
}
.container .usercontent .rightcontent .usereditcontainer{
  justify-content: flex-end;
}
.commonbtn{
  width: auto;
  height: 32px;
  justify-content: space-between;
  color: #513b5a;
  background-color: #e8cbed;
  padding: 0 8px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}
.commonbtn .textbox {
  padding: 0 5px;
}
.commonbtn .iconbox {
  padding: 0 5px;
  font-size: 20px;
}
.container .usercontent .leftcontent .userheader .editbtn{
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 2px 8px;
  color: #513b5a;
  cursor: pointer;
  background-color: rgba(156,109,151,.8);
  border-radius: 4px;
}
::v-deep .vue-image-crop-upload .vicp-wrap .vicp-operate a {
  position: relative;
  float: left;
  display: block;
  margin-left: 10px;
  width: 100px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  color: #9c6d97;
  border-radius: 2px;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #9c6d97;
}
.container .usercontent .rightcontent .trendimgprev{
  /*width: 300px;*/
  height: 300px;
  margin-top: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: start start;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
  color: #fff;
}

.flexbox.trendtime{
  width: 120px;
}

.flexbox-s.trenditem.comment-box-line {
  /* border-bottom: 1px solid #b08c8c; */
  margin-left: 10px;
  margin-bottom: 8px;
  background-color: rgb(0 0 0 / 10%);
  border-radius: 5px;
  padding: 10px 10px 0px 10px;
}
</style>
