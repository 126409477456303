<template>
<div>
  <div class="invest_area" >
    <div class="invest_area_top" >
      <div class="invest_area_top_icon" >
        <img @click="$router.back()" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAABAklEQVRIS8XVz2oCMRAG8G+Mq9BDD32Agogg2/3X7t1L1XfwPfpKfQgvWnsqlNo0gZbe6sl36Lo7soVVinhYw2DO4ceXSWZCEFok5OI8cBDcRcqjKTE/aL18rHO6o4n9OL7xSM1KjIvNwBjz7QyHYdhvNFtzAqt8k91ba20dtNx7kDhJkh5IPQFo/3I+/NRa10UP4CBIu6rJCwAXRZ6NrLVvp6D/4CiKOqS8BQGX4HyktX49Fd3Bvp9et9r8zExXXGRjY8yLC7qD49v0i4A+wGsGVo7oz8f7cvJ3eWKwWCnK1CKXV9VU5LlVuEiDVLhIS1e4yBDa11xgbDo2yZl+EJfUW+dUiBfEiYriAAAAAElFTkSuQmCC" alt="" >
        <img @click="$router.push({name: 'Mhome'})" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAACmklEQVRIS+3VTUgUYRgH8P8zOxpW1EFTCO1Qixd3vsm6hAUeCvIkEhHZxyWowEtEQUHhReokZNDJMiLCPBnUQSjpUsbMvDO7Xsw6pASaBkUl6O77xM66scqqqx26NMd5n+fHO/+Z5x3CGldtbW1F5Y7qWwRqz5YyuG/2y/TlycnJudVaabXFRMLZo5bxADM0EB5FtYwTREimF6g1lXI/rNS/IqzrdouioI+jTaI9CLxnWcQw7KMg9BFAUqI9DL3BYngxWDFNu5OBKwCFMjPfmkwmPxY2a5q2W4mVDwCsE9AlhHcdgCysWQLXO07V5gw/BlEzM9+fnZk+v1KWUfZV1XeJ6DSYh37F6PiY687k8T+wYRiNpKj9zFwDRkcQePfWerGL0ZwDoZuIplim24IgGMnej2BNs5oUVXlB4KkFlm2jQrwrBc3XNJjm3jJS+hlUI9PycDLpD0ewadojDKqci2Ff4eOsB8/GWJHBWwLPCuE15mDLSbOUt4PAv7oebHmtYTldBFwSvqvmYQbzDSG8m38JdxJwTfgu/TtY1+0DFEMHATtzg4fPnEF3GHqvi0RR2o41y2qOQXnOwAzAozmIGgioykAeSfr+UCFuWE5psGnZr5ip7uePb9b4+Pj3LBKPx7dt2brdJ+IJ4XsHNwQblvOJGC+FcE8VAqbpPGDCocB3d20MNp0JAoaEcM8sg3sZaA6EW/cfLi0Kw3LmwPw0EN7Z3KcKJBKJypi66T2BHwrhXViasd3DoJMsF+JhGH5dXCPDtHtB1Br4bkU0eYZp3yGiJc3RMDDPs0RTGHpvCmFdt/eTgmEiKl8+JMzcEwjvYv48VnTLOqYw1RcUptNpDKZSXljs/EgkbF1V0QJAza9L4rHQ959k/ya/AUtYkibkTA55AAAAAElFTkSuQmCC" alt="" >
      </div>
      <span >Gift box</span>
    </div>
  </div>
  <div class="giftcontainer">
    <div class="receivebox">
      <div class="flexbox giftitembox">
        <div class="flexbox giftitem"
             v-for="item in myGiftList"
             :key="item.id"
        >
          <div class="img" :style="'background-image: url(' + item.img +');'">
          </div>
          <div class="flexbox giftname">{{lang == 'en' ? item.name_en : item.name}}</div>
          <div class="flexbox giftprice" style="color: rgb(68, 170, 255);">{{ item.username }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {myGift} from "@/api";
import {mapGetters} from "vuex";
export default {
  name: "mmy_gift",
  components: {
  },
  data() {
    return {
      lang: 'tn',
      myGiftList: []
    };
  },
  computed:{
    ...mapGetters([
      'getLanguage'
    ]),
  },
  watch: {
    getLanguage: {
      handler(newV){
        this.lang = newV
      },
      immediate: true
    }
  },
  created() {
    this.myGift()

  },
  mounted() {
  },
  methods: {
    myGift(){
      myGift().then(res => {
        if(res.code == 0){
          this.myGiftList = res.list
        }
      })
    },
  }
}
</script>

<style scoped>
@import "../../assets/css/common.css";
.invest_area{
  width: 100%;
  height: 20px;
  padding: 20px;
  color: #303133;
  position: relative;
}
.invest_area_top{
  text-align: center;
  font-size: 18px;
  height: 20px;
  line-height: 20px;
}
.invest_area_top_icon{
  position: absolute;
  height: 20px;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
}
.invest_area_top_icon img:first-of-type {
  width: 20px;
  height: 16px;
  margin-right: 15px;
}
.invest_area_top_icon img:nth-of-type(2) {
  width: 25px!important;
  height: 25px!important;
  top: 10px;
}
.giftcontainer{
  width: 100%;
  height: auto;
}
.giftcontainer .giftitembox{
  justify-content: flex-start;
  padding: 10px;
}
.giftcontainer .giftitembox .giftitem{
  width: 20%;
  height: auto;
  align-content: space-between;
  font-size: 12px;
  margin: 2.5%;
}
.giftcontainer .giftitembox .giftitem .img{
  width: 100%;
  padding: 50%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}
.giftcontainer .giftitembox .giftitem .giftname{
  height: 20px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #333;
}
.giftcontainer .giftitembox .giftitem .giftprice{
  height: 20px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: "#999";
}
.giftcontainer .giftitembox .giftitem .sendbtn{
  padding: 0 5px;
  height: 20px;
  border: 1px solid #ff4a53;
  color: #ff4a53;
  border-radius: 10px;
  cursor: pointer;
}
</style>
