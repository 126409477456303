<template>
  <div class="flexbox-c home">
    <Head />

    <div class="flexbox-c homecontent">
      <div class="container">
        <div class="title">{{ $t('user.store') }}</div>
        <div class="linkbox">
          <a  class="linkitem" :class="type == 0 ? 'router-link-active router-link-exact-active': ''" @click="type = 0">
            <i class="iconfont icon-fix-full"></i> {{ $t('user.virtual_gifts') }}
          </a>
          <a  class="linkitem" :class="type == 1 ? 'router-link-active router-link-exact-active': ''" @click="type = 1">
            <i class="iconfont icon-sousuo"></i> {{$t('user.me_received')}}
          </a>
        </div>


        <div class="flexbox-s storecontent" v-show="type == 0">
          <div class="flexbox gifttypebox">
            <div class="flexbox gifttypeitem" :class="currentIndex == 1 ? 'gifttypeactive' : ''" @click="changeGift(1)">{{ $t('store.love_space') }}</div>
            <div class="flexbox gifttypeitem" :class="currentIndex == 2 ? 'gifttypeactive' : ''" @click="changeGift(2)">{{ $t('store.lovers_space') }}</div>
            <div class="flexbox gifttypeitem" :class="currentIndex == 3 ? 'gifttypeactive' : ''" @click="changeGift(3)">{{ $t('store.goddess_space') }}</div>
            <div class="flexbox gifttypeitem" :class="currentIndex == 4 ? 'gifttypeactive' : ''" @click="changeGift(4)">{{ $t('store.lovers') }}</div>
          </div>
          <div class="flexbox giftitembox">
            <div
                class="flexbox giftitem"
                v-for="item in giftList"
                :key="item.id"
            >
              <div class="img" :style="'background-image: url(' + item.img + ');'">
              </div>
              <div class="flexbox giftname">{{lang == 'en' ? item.name_en : item.name}}</div>
              <div class="flexbox giftprice">{{item.price}} {{ $t('pay.coin') }}</div>
              <div class="flexbox sendbtn" @click="sendGift(item)">{{ $t('user.send_gift') }}</div>
            </div>
          </div>
        </div>

        <div class="flexbox-s storecontent" v-show="type == 1">
          <div class="flexbox giftitembox">
            <div class="flexbox giftitem"
                 v-for="item in myGiftList"
                 :key="item.id"
            >
              <div class="img" :style="'background-image: url(' + item.img +');'">
              </div>
              <div class="flexbox giftname">{{lang == 'en' ? item.name_en : item.name}}</div>
              <div class="flexbox giftsender">
                <span class="sender">{{ item.username }}</span>{{ $t('store.send') }}</div>
            </div>
          </div>
        </div>


      </div>
    </div>


    <Footer />
    <ChatFixed ref="chatFixed" />

    <el-dialog width="50%" :title="$t('user.send_gift')" :visible.sync="dialogTableVisible">
        <div class="details" v-if="currentGift">
          <div class="dleft">
            <img :src="currentGift.img" alt="">
          </div>
          <div class="dright">
            <div class="gtitle">{{ $t('store.gift_name') }}：{{lang == 'en' ? currentGift.name_en : currentGift.name}}</div>
            <p class="ginfo">{{ $t('store.gift_description') }}：{{lang == 'en' ? currentGift.desc_en : currentGift.desc}}</p>
            <p class="gprice">{{ $t('store.price') }}：{{ currentGift.price }} {{$t('pay.coin')}}</p>
          </div>
        </div>
        <div class="sendinput">
          <el-input v-model="username" autocomplete="off" :placeholder="$t('user.username')"></el-input>
        </div>
        <div class="sendtextarea">
          <el-input type="textarea" v-model="msg" autocomplete="off" :placeholder="$t('store.leave_a_message')"></el-input>
        </div>



      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">{{$t('user.cancel')}}</el-button>
        <el-button type="primary" @click="send">{{ $t('user.send') }}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Head from '@/components/Head.vue'
import ChatFixed from '@/components/ChatFixed.vue'
import {giftList, sendGift,myGift} from '@/api/index'
import {mapGetters} from "vuex";
export default {
  components: {Footer, Head, ChatFixed},
  data() {
    return {
      myGiftList: [],
      type: 0,
      currentGift: null,
      username: '',
      msg: '',
      dialogTableVisible: false,
      lang: 'tn',
      giftList: [],
      currentIndex: 1,
      initGift: false
    }
  },
  created() {
    if(!this.initGift){
      this.changeGift(1)
    }
    this.myGift()
  },
  computed:{
    ...mapGetters([
      'getLanguage'
    ]),
  },
  watch: {
    getLanguage: {
      handler(newV){
        this.lang = newV
      },
      immediate: true
    }
  },
  methods:{
    myGift(){
      myGift().then(res => {
        if(res.code == 0){
          this.myGiftList = res.list
        }
      })
    },
    send(){
      if(!this.username){
        return
      }
      sendGift({
        username: this.username,
        gift_id: this.currentGift.id
      }).then(res => {
        if(res.code == 0){
          this.$message({
            message: 'Operated successful',
            type: 'success'
          })
          this.username = ''
          this.currentGift = null
          this.dialogTableVisible = false
        }else{
          this.$message.error('Operated failed')
        }
      })
    },
    sendGift(gift){
      this.currentGift = gift
      this.dialogTableVisible = true
    },
    changeGift(id){
      this.currentIndex = id
      giftList({cate_id: id}).then(res => {
        if(res.code == 0){
          this.giftList = res.list
        }
      })
    },
  }
}
</script>
<style scoped>
@import "../../assets/css/common.css";
@import "../../assets/css/index.css";
.container{
  width: 1000px;
  padding: 100px 30px;
}
.container .title {
  font-size: 20px;
  color: #513b5a;
  margin-bottom: 28px;
  font-weight: 700;
  text-align: left;
}
.container .linkbox {
  text-align: left;
}
.container .linkbox .router-link-active {
  background-color: #b4534d;
}
.container .linkbox .linkitem{
  padding: 10px 10px;
  background-color: #fff;
  color: #513b5a;
  background-color: transparent;
  cursor:pointer;
}
.container .linkbox .router-link-active{
  background-color: #b4534d !important;
}
.container .linkbox .linkitem{
  padding: 10px 10px;
  background-color: #fff;
  color: #513b5a;
  background-color: transparent;
}
.container .storecontent{
  padding: 10px;
  padding-right: 0;
  background-color: #fff;
  margin-top: 30px;
  font-size: 14px;
  min-height: 400px;
}
.container .storecontent .gifttypebox {
  justify-content: flex-start;
}
.container .storecontent .gifttypebox .gifttypeitem {
  width: 155px;
  height: 32px;
  color: #9c6d97;
  margin: 5px 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.container .storecontent .gifttypebox .gifttypeactive{
  color: #ff4a53!important;
}
.container .storecontent .giftitembox {
  justify-content: flex-start;
  color: #9c6d97;
  padding: 30px;
}
.container .storecontent .giftitembox .giftitem {
  width: 144px;
  height: auto;
  margin: 15px;
  align-content: space-between;
}
.container .storecontent .giftitembox .giftitem .img{
  width: 120px;
  height: 120px;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}
.container .storecontent .giftitembox .giftitem .giftname, .container .storecontent .giftitembox .giftitem .giftprice {
  min-height: 30px;
  text-align: center;
}
.container .storecontent .giftitembox .giftitem .giftname, .container .storecontent .giftitembox .giftitem .giftprice {
  min-height: 30px;
  text-align: center;
}
.container .storecontent .giftitembox .giftitem .sendbtn{
  min-width: 75px;
  height: 30px;
  border: 1px solid #ff4a53;
  color: #ff4a53;
  border-radius: 30px;
  cursor: pointer;
}
.details{
  display: flex;
  flex-wrap: wrap;
}
.details .dleft{
  width: 40%;
  padding: 0 0 20px 20px;
}
.details .dright {
  width: 55%;
  margin-left: 5%;
  text-align: left;
}
.sendtextarea{
  margin-top: 20px;
}
.details .dleft img{
  width: 100%;
}
.el-button--default {
  border-color: #9f759b;
}
.el-button--primary, .el-popper.is-light .cardcontainer+.el-popper__arrow:before {
  background-color: #9f759b;
  border-color: #9f759b;
}
::v-deep .el-dialog__header{
  text-align: left;
}

element.style {
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
</style>
