export default{
    user: {
        username: 'username',
        password: 'password',
        login: 'SIGN IN',
        register: 'SIGN UP',
        register2: 'Register now~~',
        plogin: 'PLEASE SIGN IN',
        remember_me: 'Remember me',
        about_us: 'ABOUT US',
        terms_of_use: 'TERMS OF USE',
        privacy_policy: 'PRIVACY POLICY',
        help_center: 'HELP CENTER',
        contact_us: 'CONTACT US',
        join_our: 'JOIN OUR COMMUNITY',
        join_our2: 'Join our fast-growing community. Find new friends, date up, share photo and video. Enjoy!',
        register_profile: 'REGISTER YOUR PROFILE',
        basic: 'Basic',
        email: 'Email',
        line: 'Lai ID',
        sex: 'Gender',
        male: 'Male',
        female: 'Female',
        birthday: 'Birthday',
        i_agree_with_it: 'I agree with it',
        join: 'Join',
        should_contain_only_letters_and_digits: 'Should contain only letters and digits',
        should_be_valid: 'Should be valid',
        please_enter: 'Please enter',
        my_profile: 'My Profile',
        profile_edit: 'Profile Edit',
        modify_btn: 'Modify',
        my_preferences: 'My Preferences',
        mail_notifications: 'Mail Notifications',
        logout: 'Sign Out',
        main: 'MAIN',
        member: 'MEMBER',
        chat : 'CHAT',
        recharge : 'RECHARGE',
        upgrade : 'UPGRADE',
        store : 'STORE',
        members : 'MEMBERS',
        my_credits : 'MY CREDITS',
        users : 'USERS',
        newsfeed : 'NEWSFEED',
        balance : 'Credit balance',
        coin_balance : 'Coin balance',
        buy_more : 'Buy more',
        latest : 'Latest',
        online : 'Online',
        all : 'View all',
        joined_our_site: 'Joined our site!',
        modified_his_avatar: 'Modified his avatar.',
        age: 'years',
        active: 'active',
        follow: 'Follow',
        followed: 'Cancel Follow',
        send_gift: 'Send Virtual gifts',
        send: 'Send',
        hours_ago: 'hours ago',
        chat_btn: 'Chat',
        photo_btn: 'Photo',
        browse_user: 'BROWSE USER',
        whole: ' Whole',
        search: 'Search',
        whats_happening: "What's happening?",
        add_signature: "Add some info about yourself here",
        modify_avatar: "Modify your avatar",
        user_avatar: "Avatar",
        change_password: 'Change Password',
        edit_infomation: 'Edit Infomation',
        save: 'Save',
        cancel: 'Cancel',
        virtual_gifts: 'Virtual gifts',
        me_received: 'ME RECEIVED',
        like: 'Like',
        mine: 'Mine',
        delBtn: 'Delete',
        membership_privileges: 'Membership privileges',
        choose_from_album: 'Choose from album',
        my_head: 'My head',
        data_setting: 'Data setting',
        personal_signature: 'Personal signature',
        language: 'Language',
        state: 'State',
        more: 'more',
        comments: 'Comments',
        free: 'Free',
        expires: 'Expires',
        permission: `You dont't have permission`,
        search_by_name: 'Search by name',
        notifications : 'Notifications ',
    },
    pay: {
        recharge_for_self: 'Recharge for self',
        crown_card: 'Crown Card',
        diamond_card: 'Diamond Card',
        allow_profile_wall_posts: 'Allow profile wall posts',
        search_users: 'Search users',
        view_profiles: 'View profiles',
        auth_message: 'Auth message',
        allow_comments_on_newsfeed: 'Allow comments on newsfeed',
        dynamic_unrestricted: 'Dynamic unrestricted',
        allows_newsfeed_to_be_ent: 'Allows newsfeed to be sent',
        upload_photo: 'Upload photo',
        view_photo: 'View photo',
        comment_photo: 'Comment photo',
        add_video: 'Add video',
        view_video: 'View video',
        comment_video: 'Comment video',
        start_mail_dialog: 'Start mail dialog',
        continue_mail_dialog: 'Continue mail dialog',
        read_chat_messages: 'Read chat messages',
        send_chat_messages_for_free: 'Send chat messages for free',
        start_chat_dialog: 'Start chat dialog',
        read_mails: 'Read mails',
        continue_chat_dialog: 'Continue chat dialog',
        free_use_of_the_translation_function: 'Free use of the translation function',
        month1: 'month(s)',
        province: 'Province',
        coin: 'Coin',
        total_price: 'Total price',
        card_username: `Cardholder's name`,
        card_english_name: `Please fill in your English name`,
        card_fullphone: `Card fullPhone`,
        account: 'Account',
        remaining_quantity: 'Remaining quantity',
        select_recharge_type: 'Select the recharge type',
    },
    store: {
        love_space: 'Love space',
        lovers_space: 'LOVERS space',
        goddess_space: 'Goddess space',
        lovers: 'lovers',
        leave_a_message: 'Leave a message',
        gift_name: 'Gift name',
        gift_description: 'Gift description',
        price: 'Price',
        send: 'Send',
        gift_box: 'Gift box',
        send_gift_to: 'SEND GIFT TO',
        your_balance_is_not_enough: 'Your balance is not enough',

    },
    chat: {
        upgrade_msg: `You dont't have permission, Need to upgrade~`,
        send_failed: `Send failed`,
        view_history: `View history`,
        msg: `Message`,
        chats: 'Chats',
        customer: 'Customer',
        chat_list: 'Chat List',
    }

}
