<template>
  <div class="menu">
    <div class="flexbox loginmenucontainer">
      <a v-show="!userInfo" href="#/login" class="sign">{{$t('user.login')}}</a>


      <a v-show="userInfo" href="#/chat/pc" class="sign"><el-badge :value="getTotalMsgCount">{{ $t('user.notifications') }}&nbsp;&nbsp;&nbsp;</el-badge></a>

      <router-link v-show="!userInfo" class="sign" :to="{name: 'Info', params: {query: 'register'}}"  >{{$t('user.register')}}</router-link>
      <el-dropdown v-if="userInfo" class="sign" placement="bottom" @command="userOpreate">
        <span class="el-dropdown-link">
          {{userInfo.username}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="0">{{ $t('user.my_profile') }}</el-dropdown-item>
          <el-dropdown-item command="1">{{ $t('user.profile_edit') }}</el-dropdown-item>
<!--          <el-dropdown-item command="2">{{ $t('user.my_preferences') }}</el-dropdown-item>-->
<!--          <el-dropdown-item command="3">{{ $t('user.mail_notifications') }}</el-dropdown-item>-->
          <el-dropdown-item command="4">{{ $t('user.logout') }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="langitem">
        <el-dropdown  placement="bottom" @command="handleCommand">
          <span class="el-dropdown-link">
            <template v-if="currentIndex == 0">
              <i class="iconfont icon-jianti"></i> 简体中文
            </template>
            <template v-else-if="currentIndex == 1">
              <i class="iconfont icon-fanti"></i> 繁体中文
            </template>
            <template v-else>
              <i class="iconfont icon-yingwen"></i> English
            </template>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="0"><i class="iconfont icon-jianti"></i> 简体中文</el-dropdown-item>
            <el-dropdown-item command="1"><i class="iconfont icon-fanti"></i> 繁体中文</el-dropdown-item>
            <el-dropdown-item command="2"><i class="iconfont icon-yingwen"></i> English</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import {logout} from '../api/user'
import {mapGetters} from "vuex";
export default {
  name: 'Menu',
  data(){
    return {
      languagelist: ['cn','tn', 'en'],
      currentIndex: 0,
    }
  },
  computed: {
    ...mapGetters([
        'getUserinfo',
        'getTotalMsgCount',
    ]),
    userInfo(){
      return Object.keys(this.getUserinfo).length > 0 ? this.getUserinfo : false
    }
  },
  watch: {
  },
  created() {
    if(localStorage.getItem('lang') == 'cn'){
      this.currentIndex = 0
    }else if(localStorage.getItem('lang') == 'tn'){
      this.currentIndex = 1
    }else{
      this.currentIndex = 2
    }
  },
  props: {
  },
  methods: {
    userOpreate(index){
      const that = this
      switch (index){
        case '0':
          this.$router.push({name: 'IndexPC', query: {id: this.userInfo.id}})
          break
        case '1':
          this.$router.push({name: 'UserEditPc'})
          break
        case '2':
          break
        case '3':
          break
        case '4':
          console.log(index)
          // 退出
          logout().then(res => {
            if(res.code == 0){
              sessionStorage.clear()
              that.$store.commit('setUserInfo', {})
              this.$store.commit('loginState', false)
              that.$router.push({name: 'Login'})
            }else{
              that.$message.error('operate failed');
            }
          })
          break
      }
    },
    logoutOp(){

    },
    handleCommand(index) {
      localStorage.setItem('lang', this.languagelist[index])
      this.$i18n.locale = this.languagelist[index]
      this.currentIndex = index
      this.$store.commit('changeLanguage', this.languagelist[index])
    }
  },
  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../assets/css/iconfont.css';
@import "../assets/css/common.css";
.menu {
  width: auto;
  height: auto;
  position: fixed;
  right: 0;
  top: 0;
}
.loginmenucontainer {
  width: 100%;
  /*height: 80px;*/
  padding: 10px 15px;
  font-size: 16px;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}

.loginmenucontainer .sign {
  display: flex;
  height: 26px;
  line-height: 22px;
  font-size: 14px;
  margin-right: 20px;
  cursor: pointer;
  color: #000;
}

.loginmenucontainer .langitem, .loginmenucontainer .sign {
  padding: 3px 15px;
  border-radius: 3px;
  background-color: #8f75a2;
}
.el-dropdown {
  color: #000!important;
}

.el-dropdown-menu {
  background-color: #8f75a2!important;
  padding: 0;
  border: none;
}

::v-deep .popper__arrow::after {
   border-bottom-color: #8f75a2 !important;
  border: none;
 }

::v-deep .el-dropdown-menu {

  position: absolute !important;

  min-width: 222px;

  left: auto !important;

  right: 0;
}

.popper__arrow{

  left: 50%!important;

}

.el-dropdown-menu__item{
  color: #333 !important;
}

li.el-dropdown-menu__item:hover{
  background-color: #e8cbed !important;
}
</style>
