export default{
    user: {
        username: '用戶名',
        password: '密碼',
        login: '登錄',
        register: '注冊',
        register2: '立即註冊~~',
        plogin: '請登錄',
        about_us: '關於我們',
        terms_of_use: '服務條款',
        privacy_policy: '隱私政策',
        help_center: '幫助中心',
        contact_us: '聯係我們',
        join_our: '加入我們的社區',
        join_our2: '加入我們這個富有激情的社區。找到新的朋友，約會，分享照片和視頻。 嗨起來!',
        register_profile: '注冊您的個人資料',
        basic: '基本資料',
        email: '郵箱',
        line: '賴ID',
        sex: '性别',
        male: '男',
        female: '女',
        birthday: '生日',
        i_agree_with_it: '我同意',
        join: '加入',
        should_contain_only_letters_and_digits: '必須包含字母和數字',
        should_be_valid: '必須是有效的',
        please_enter: '請輸入',
        my_profile: '我的個人主頁',
        profile_edit: '資料編輯',
        my_preferences: '個性化設置',
        mail_notifications: '數據標簽',
        logout: '退出',
        main: '首頁',
        member: '會員',
        chat : '聊天',
        recharge : '充值',
        upgrade : '升級',
        store : '商店',
        members : '會員',
        my_credits : '我的賬戶',
        users : '社區用戶',
        newsfeed : '最新動態',
        balance : '餘額',
        coin_balance : '金幣餘額',
        buy_more : '充值',
        latest : '最新',
        online : '在綫',
        all : '查看全部',
        joined_our_site: '加入了我們的網站。',
        modified_his_avatar: '修改了他的頭像。',
        age: '歲',
        active: '活躍',
        follow: '關注',
        followed: '取消關注',
        send_gift: '發送禮物',
        send: '發送',
        hours_ago: '小時前',
        chat_btn: '聊天',
        photo_btn: '照片',
        browse_user: '瀏覽用戶',
        whole: ' 全部',
        search: '搜索',
        whats_happening: '聊一聊~',
        add_signature: "你的個性簽名",
        modify_btn: '修改',
        modify_avatar: "修改頭像",
        user_avatar: "用户頭像",
        change_password: '修改密碼',
        edit_infomation: '資料編輯',
        save: '保存',
        cancel: '取消',
        virtual_gifts: '禮物',
        me_received: '我收到的禮物',
        like: '喜歡',
        mine: '我的',
        delBtn: '刪除',
        membership_privileges: '會員特權',
        choose_from_album: '從相冊中選擇',
        my_head: '我的頭像',
        data_setting: '資料設定',
        personal_signature: '個性簽名',
        language: '语言',
        state: '國家',
        more: '查看更多',
        comments: '的評論',
        free: '非會員',
        expires: '到期',
        permission: '您沒有權限',
        search_by_name: '用戶名搜索',
        notifications : '通知 ',
    },
    pay: {
        recharge_for_self: '為自己升級',
        crown_card: '皇冠會員',
        diamond_card: '鑽石會員',
        allow_profile_wall_posts: '允许个人资料上墙帖',
        search_users: '搜索用戶',
        view_profiles: '查看個人資料',
        auth_message: '聊天發圖片免費',
        allow_comments_on_newsfeed: '允許在動態下面評論',
        dynamic_unrestricted: '無免費條數限制',
        allows_newsfeed_to_be_ent: '允许發動態',
        upload_photo: '上傳照片',
        view_photo: '查看圖片',
        comment_photo: '評論圖片',
        add_video: '添加視頻',
        view_video: '查看視頻',
        comment_video: '評論視頻',
        start_mail_dialog: '啓用郵件對話框',
        continue_mail_dialog: '繼續郵件對話框',
        read_chat_messages: '閲讀聊天消息',
        send_chat_messages_for_free: '免費發送聊天消息',
        start_chat_dialog: '啟用聊天輸入框',
        read_mails: '閲讀郵件',
        continue_chat_dialog: '繼續聊天對話框',
        free_use_of_the_translation_function: '免費使用翻譯',
        month1: '月',
        province: '優惠',
        coin: '金幣',
        total_price: '總價',
        card_username: '持卡人名字',
        card_english_name: `請填寫英文姓名`,
        card_fullphone: '電話',
        account: '賬戶',
        remaining_quantity: '剩餘數量',
        select_recharge_type: '選擇充值類型',
    },
    store: {
        love_space: '愛情空間',
        lovers_space: 'LOVERS空間',
        goddess_space: '女神空間',
        lovers: '情趣空間',
        leave_a_message: '贈送留言',
        gift_name: '禮物名稱',
        gift_description: '禮物說明',
        price: '售價',
        send: '贈送',
        gift_box: '禮物盒子',
        send_gift_to: '贈送禮物給',
        your_balance_is_not_enough: '餘額不足',
    },
    chat: {
        upgrade_msg: `您沒有權限, 需要升級~`,
        send_failed: `發送失敗`,
        view_history: `查看歷史消息`,
        msg: `消息`,
        chats: '聊天',
        customer: '客服',
        chat_list: '會話列表',
    }
}
