<template>
  <div>
    <div class="invest_area" >
      <div class="invest_area_top" >
        <div class="invest_area_top_icon" >
          <img @click="$router.back()" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAABAklEQVRIS8XVz2oCMRAG8G+Mq9BDD32Agogg2/3X7t1L1XfwPfpKfQgvWnsqlNo0gZbe6sl36Lo7soVVinhYw2DO4ceXSWZCEFok5OI8cBDcRcqjKTE/aL18rHO6o4n9OL7xSM1KjIvNwBjz7QyHYdhvNFtzAqt8k91ba20dtNx7kDhJkh5IPQFo/3I+/NRa10UP4CBIu6rJCwAXRZ6NrLVvp6D/4CiKOqS8BQGX4HyktX49Fd3Bvp9et9r8zExXXGRjY8yLC7qD49v0i4A+wGsGVo7oz8f7cvJ3eWKwWCnK1CKXV9VU5LlVuEiDVLhIS1e4yBDa11xgbDo2yZl+EJfUW+dUiBfEiYriAAAAAElFTkSuQmCC" alt="" >
          <img @click="$router.push({name: 'Mhome'})" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAACmklEQVRIS+3VTUgUYRgH8P8zOxpW1EFTCO1Qixd3vsm6hAUeCvIkEhHZxyWowEtEQUHhReokZNDJMiLCPBnUQSjpUsbMvDO7Xsw6pASaBkUl6O77xM66scqqqx26NMd5n+fHO/+Z5x3CGldtbW1F5Y7qWwRqz5YyuG/2y/TlycnJudVaabXFRMLZo5bxADM0EB5FtYwTREimF6g1lXI/rNS/IqzrdouioI+jTaI9CLxnWcQw7KMg9BFAUqI9DL3BYngxWDFNu5OBKwCFMjPfmkwmPxY2a5q2W4mVDwCsE9AlhHcdgCysWQLXO07V5gw/BlEzM9+fnZk+v1KWUfZV1XeJ6DSYh37F6PiY687k8T+wYRiNpKj9zFwDRkcQePfWerGL0ZwDoZuIplim24IgGMnej2BNs5oUVXlB4KkFlm2jQrwrBc3XNJjm3jJS+hlUI9PycDLpD0ewadojDKqci2Ff4eOsB8/GWJHBWwLPCuE15mDLSbOUt4PAv7oebHmtYTldBFwSvqvmYQbzDSG8m38JdxJwTfgu/TtY1+0DFEMHATtzg4fPnEF3GHqvi0RR2o41y2qOQXnOwAzAozmIGgioykAeSfr+UCFuWE5psGnZr5ip7uePb9b4+Pj3LBKPx7dt2brdJ+IJ4XsHNwQblvOJGC+FcE8VAqbpPGDCocB3d20MNp0JAoaEcM8sg3sZaA6EW/cfLi0Kw3LmwPw0EN7Z3KcKJBKJypi66T2BHwrhXViasd3DoJMsF+JhGH5dXCPDtHtB1Br4bkU0eYZp3yGiJc3RMDDPs0RTGHpvCmFdt/eTgmEiKl8+JMzcEwjvYv48VnTLOqYw1RcUptNpDKZSXljs/EgkbF1V0QJAza9L4rHQ959k/ya/AUtYkibkTA55AAAAAElFTkSuQmCC" alt="" >
        </div>
        <span >{{ $t('user.upgrade') }}</span>
      </div>
    </div>
    <div class="invest_content" >
      <van-tabs v-model="active">
        <van-tab :title="$t('pay.crown_card')">
          <div class="card_area" >
            <div class="card_area_content"  style="background: rgb(255, 137, 57);">{{ $t('pay.crown_card') }}</div>
            <div class="card_list" >
              <div
                  class="card_item"
                  v-for="item in rechargeList[0]"
                  :key="item.id"
                  :class="upgrade == item.id ? 'card_item_choose' : ''"
                  @click="selectUpgrade(item)"
              >
                <div class="card_item_left" >
                  <div class="month" >{{item.duration}}{{$t('pay.month1')}}</div>
                  <div class="month_details" >
                    <span >{{item.avg}} USD</span>
                    <span >/1{{$t('pay.month1')}}</span>
                    <span >{{$t('pay.province')}} {{item.province}}%</span>
                  </div>
                </div>
                <div class="card_item_right" >${{ item.price }}</div>
              </div>
            </div>
          </div>
          <div class="privilege" >
            <div class="privilege_title" >{{ $t('user.membership_privileges') }}</div>
            <div class="privilege_list" >
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >
                {{ $t('pay.allow_profile_wall_posts') }}
              </div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{ $t('pay.search_users') }}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{ $t('pay.view_profiles') }}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{ $t('pay.auth_message') }}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{ $t('pay.allow_comments_on_newsfeed') }}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{ $t('pay.dynamic_unrestricted') }}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{ $t('pay.allows_newsfeed_to_be_ent') }}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.upload_photo')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.view_photo')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.comment_photo')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.add_video')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.view_video')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.comment_video')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.start_mail_dialog')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.continue_mail_dialog')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.read_chat_messages')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.send_chat_messages_for_free')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.start_chat_dialog')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.read_mails')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.continue_chat_dialog')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.free_use_of_the_translation_function')}}</div>
            </div>
          </div>
        </van-tab>
        <van-tab :title="$t('pay.diamond_card')">
          <div class="card_area" >
            <div class="card_area_content"  style="background: rgb(153, 153, 153);">{{ $t('pay.diamond_card') }}</div>
            <div class="card_list" >
              <div
                  class="card_item"
                  v-for="item in rechargeList[1]"
                  :key="item.id"
                  :class="upgrade == item.id ? 'card_item_choose' : ''"
                  @click="selectUpgrade(item)"
              >
                <div class="card_item_left" >
                  <div class="month" >{{item.duration}}{{$t('pay.month1')}}</div>
                  <div class="month_details" >
                    <span >{{item.avg}} USD</span>
                    <span >/1{{$t('pay.month1')}}</span>
                    <span >{{$t('pay.province')}} {{item.province}}%</span>
                  </div>
                </div>
                <div class="card_item_right" >${{ item.price }}</div>
              </div>
            </div>
          </div>
          <div class="privilege" >
            <div class="privilege_title" >{{ $t('user.membership_privileges') }}</div>
            <div class="privilege_list" >
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.allow_profile_wall_posts')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.search_users')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.view_profiles')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.allow_comments_on_newsfeed')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.allows_newsfeed_to_be_ent')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.upload_photo')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.add_video')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.start_mail_dialog')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.continue_mail_dialog')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.read_chat_messages')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.send_chat_messages_for_free')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.start_chat_dialog')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.read_mails')}}</div>
              <div class="privilege_item" >
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAEE0lEQVRIS7VWTWhdRRT+Zm6Ut+gmNbW0Lgqh4CKgdudCLf4UBDeCK6l0Y63SSvsU46Ih+mwwRWPzGtIXX/KSSKVgIQrtQqy0YmhduKsgrlRoEQSpbV0ovffOzDlyzty5SarGhXjhZt6dO/f7znznO2di8D9fZj38mUUe5IDdYOzyAUPE2BgIAOFXz/iGCJ+ywUcjTfPLP+H8LcHiIm/KA94D8BwDlggQYOY46nOIv31AzoQTJsdbrZb5/XaivxB0F/h+AJ8xY4sAktwVgfz2HjBGgfUWIhmZ8cOtgKfbb5jvVpOsIZib4yHqwyUw+mWRAHM1VtHWkcs752MAMup7j5tg7GyPmW8TSU3Q6fCGvgYuM7A9vUySSIRylQK0KmoBlWdXzQVi+MBX7rjT7jjeMr/JNzVBd5EnwHht9fYkyhSh7EQjJqB0K7LlZVwTgoKrXMZitns0e6km6PV4c7C4AqAhk0l7HaukFi5GK0SqfUUk85KTsiQlknfOwwN28INJ85PuYGaBmxZoy4JEIOCyOO2gdhIBAppI4q4Yecm101RaxsiH7WxcCd7v8XkGnqjwox1X3BGdlKJzUXNNrAYQpcmLSqqUfDLLpzv20biDHl9jxkCypHzDwCcUsM0FPChrBLT0OEdCQHgySkFwnr923lz1hGcM0JccBeDG6U52lxJMz0rMUVcRmRgHXj1gZpjZvDOFi6XHQ0SYa71uXpR1wy2ezR3tA8xX02+bRwDDew6F/cToiHzWRKyPu5mkB5jqRgL9G329a/iguSBzY8d4vnR4PsvwSmvYHJe55ig3faB24bHQezfbK3O7D7rHQ7AXNG+xMPnsfCZcwOQJvs4GGwXcx5ZwmQIOecY279FjRsMH/Ow89mkFe57zgbdKbnPPL5DLrhaOpgJhR2ol1uL62flsIBJ0+CIDDycbOrFkVQOpYmNb4MpVrLWgNUDRpqK95kWKUlQI5stzJ+1jSjAxzaOBcEQkSvZMvUaeBSyCsN63ipVakd4kROnblapG8/ypbEoJjk7yIBt8HwJsWFVcyVUCIAhiRwFPhhBiiTzZVt3mdWluC3vP50vmRt0qxo7xyRCwR0DraKpty1xRUt1BBUTzFa2rOxR/FGWU1liMf3EqG1nTi8bHeVOe6SGyVZtYOgO0cEiTr0BV90yFJraUS8ZU7c7Zuy8tmWtrCOShNcH3OYflQOgXgrhdSShrpCpBZYAiNT7Rv+pPKdG2sP3LZ27rpvFzYPQID/0RcIYZ25M0Sqb2jLJIwkWO1I9SMKn7mnwdAiHZ3+INDaY3C4eXQ0AjHTbJWQIuV3pOB4+ebATY8l8I0m72HubNKOlZYvNUCPwAMQYEJDU22WEpia2cV1Xxj1tg711aMnpMrftfRSL6L+OfX/FYRujfo3EAAAAASUVORK5CYII=" alt="" >{{$t('pay.continue_chat_dialog')}}</div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
      <div class="site" ></div>
      <div class="money_fixed" >
        <div class="money_fixed_left" >
          <span >{{ $t('pay.total_price') }}</span>
          <span >$</span>
          <span >{{ currentUpgrade.price }}</span>
        </div>
        <div class="money_fixed_right" @click="submitBtn">{{ $t('user.upgrade') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {recharge} from "@/api";
import { Tab, Tabs } from 'vant';
import 'vant/lib/tabs/style'
import 'vant/lib/tab/style'
export default {
  name: "m_upgrade",
  components:{
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  data(){
    return {
      upgrade: 0,
      rechargeList: [[],[]],
      active: 0,
      currentUpgrade: {
        id: 0,
        price: 0
      }
    }
  },
  created() {
    this.getRecharge()
  },
  methods: {
    submitBtn(){
      let data = {
        type: 'Upgrade',
        id: this.currentUpgrade.id,
        amount: this.currentUpgrade.price
      }
      this.$store.commit('setPayData', data)
      this.$router.push({name: 'MPayPayment'})
    },
    getRecharge(){
      recharge().then(res => {
        if(res.code == 0){
          this.rechargeList = res.list
          if(this.upgrade == 0) {
            this.upgrade = res.list[0][0].id
            this.currentUpgrade = res.list[0][0]
          }
        }
      })
    },
    selectUpgrade(item){
      this.upgrade = item.id
      this.currentUpgrade = item
    }
  }
}
</script>

<style scoped>
@import "../../assets/css/common.css";
.invest_area{
  width: 100%;
  height: 20px;
  padding: 20px;
  color: #303133;
  position: relative;
}
.invest_area_top{
  text-align: center;
  font-size: 18px;
  height: 20px;
  line-height: 20px;
}
.invest_area_top_icon{
  position: absolute;
  height: 20px;
  left: 20px;
  top: 20px;
  display: flex;
  align-items: center;
}
.invest_area_top_icon img:first-of-type {
  width: 20px;
  height: 16px;
  margin-right: 15px;
}
.invest_area_top_icon img:nth-of-type(2) {
  width: 25px!important;
  height: 25px!important;
  top: 10px;
}
.invest_content {
  margin-top: 5px;
  background: #f5f7fa;
}
.card_area {
  padding: 20px;
  background: #fff;
}
.card_area_content {
  width: 262px!important;
  height: 140px;
  display: inline-block;
  /*margin-left: 45px;*/
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  padding-top: 23px;
  padding-left: 23px;
  font-weight: 800;
  text-align: left;
}
.card_list {
  width: 100%;
  align-items: center;
}
.card_item_choose {
  border: 1px solid #ff4a53;
  background-color: #fff3f4;
}
.card_item {
  width: 92%;
  margin: 0 auto 10px auto;
  border: 1px solid #edeff2;
  border-radius: 5px;
  position: relative;
  height: 60px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}
.card_item_left {
  flex-shrink: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.month {
  color: #303133;
  font-size: 20px;
  margin-left: 10px;
  font-weight: 600;
  text-align: left;
}
.month_details {
  display: flex;
  margin-top: 4px;
}
.month_details span:first-of-type {
  color: #303133;
  font-size: 12px;
  line-height: 20px;
}
.month_details span:nth-of-type(2) {
  color: #bfc2cc;
  font-size: 12px;
  line-height: 20px;
}
.month_details span:nth-of-type(3) {
  color: #ff4a53;
  font-size: 14px;
  margin-left: 10px;
}
.card_item_right {
  flex-shrink: 0;
  font-size: 20px;
  color: #ff4a53;
}
.privilege_title {
  font-size: 16px;
  color: #909399;
  padding: 10px 0 10px 25px;
  width: 100%;
  background: #f5f7fa;
  text-align: left;
}
.privilege_list {
  padding: 0 20px;
  background: #fff;
  padding-bottom: 10px;
}
.privilege_item {
  width: 100%;
  padding-top: 10px;
  font-size: 14px;
  color: #303133;
  background: #fff;
  display: flex;
  align-items: center;
}
.privilege_item img {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}
.site {
  width: 100%;
  height: 60px;
}
.money_fixed {
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 60px;
  display: flex;
  z-index: 1000;
}
.money_fixed_left {
  flex-shrink: 1;
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding-left: 20px;
  display: flex;
}
.money_fixed_left span:first-of-type {
  font-size: 14px;
  color: #303133;
}
.money_fixed_left span:nth-of-type(2), .money_fixed_left span:nth-of-type(3) {
  font-size: 18px;
  color: #ff4a53;
  margin-left: 4px;
}
.money_fixed_left span:nth-of-type(2), .money_fixed_left span:nth-of-type(3) {
  font-size: 18px;
  color: #ff4a53;
  margin-left: 4px;
}
.money_fixed_right {
  flex-shrink: 0;
  width: 130px;
  height: 61px;
  background: #ff4a53;
  font-size: 18px;
  font-weight: 100;
  border: 0;
  color: #fff;
  text-align: center;
  line-height: 60px;
}
</style>
